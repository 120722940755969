import React, {
  memo,
  type ReactNode,
  type ReactElement,
  useCallback,
  useEffect,
  useState
} from 'react'
import {
  Box,
  createStyles,
  Flex,
  Loader,
  Paper
} from '@mantine/core'
import { useReactFlow, useStore, type NodeProps } from '@xyflow/react'
import { getTextColor, getWhiteBackgroundColor } from 'src/utils/theme'
import { useHotkeys } from '@mantine/hooks'
import { IconGripVertical } from '@tabler/icons-react'
import NodeResizer from '../FlowControls/NodeResizer'
import { SELECTED_COLOR } from '../shared'
import Handles from '../Components/Handles'
import NodeToolbar from '../FlowControls/NodeToolbar'
import useDetachNodes from './GroupNode/useDetachNodes'
import { useViewControls } from './NodeContext'

const widthAndHeight = {
  w: '100%',
  h: '100% !important'
}

interface StyleProps {
  noBg?: boolean
  noBorder?: boolean
  isDashed?: boolean
  selected?: boolean
  borderWidth?: number
  borderColor?: string
}

const useStyles = createStyles((
  theme,
  {
    selected,
    borderWidth,
    borderColor,
    isDashed,
    noBorder,
    noBg
  }: StyleProps) => {
  const isLight = theme.colorScheme === 'light'
  const backgroundColor = noBg
    ? 'transparent'
    : getWhiteBackgroundColor(theme).backgroundColor

  return {
    root: {
      ...(!noBg && {
        boxShadow: theme.shadows.xl
      }),
      ...(!noBorder && {
        border: `${borderWidth ?? 1}px solid ${borderColor ?? theme.colors.gray[4]}`
      }),
      borderStyle: isDashed
        ? 'dashed'
        : 'none',
      backgroundColor,
      ...getTextColor(theme),
      position: 'relative',
      '&:before': {
        content: "''",
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        borderRadius: theme.radius.sm,
        outline: selected
          ? `solid ${isLight
            ? SELECTED_COLOR
            : '#fff'} 2px`
          : 'unset'
      }
    },
    bounds: {
      backgroundColor
    },
    maxWidthAndHeight: {
      width: '100%',
      height: '100% !important'
    },
    loaderContainer: {
      position: 'absolute',
      top: -8,
      right: 2
    },
    target: {
      position: 'absolute',
      top: 4,
      left: 4,
      width: 24,
      height: 24
    }
  }
})

interface Props extends NodeProps {
  noBg?: boolean
  noBorder?: boolean
  noPadding?: boolean
  borderWidth?: number
  borderColor?: string
  shouldOverrideToolbar?: boolean
  shouldConnect?: boolean
  allowResize?: boolean
  isLoading?: boolean
  isDashed?: boolean
  isCustomDrag?: boolean
  minWidth?: number
  minHeight?: number
  onHover?: (hovered: boolean) => void
  onDelete?: () => Promise<void>
  children: ReactElement | ReactNode | JSX.Element
}

function BaseNode ({
  id,
  children,
  isDashed,
  isLoading,
  isCustomDrag,
  allowResize,
  borderWidth = 1,
  borderColor,
  shouldOverrideToolbar,
  shouldConnect = true,
  noPadding,
  noBorder = false,
  noBg = false,
  minWidth = 250,
  minHeight = 150,
  selected,
  onHover,
  onDelete,
  data
}: Props) {
  const { viewOnly } = useViewControls()
  const { classes } = useStyles({
    selected,
    borderWidth,
    borderColor,
    isDashed,
    noBorder,
    noBg
  })
  const [hovered, setIsHovered] = useState(false)
  const hasParent = useStore((store) => !!store.nodeLookup.get(id)?.parentId)
  const { deleteElements } = useReactFlow()
  const detachNodes = useDetachNodes()

  const handleDelete = useCallback(
    async () => {
      if (onDelete) await onDelete()
      return await deleteElements({ nodes: [{ id }] })
    },
    [id, onDelete]
  )

  const onDetach = useCallback(() => detachNodes([id]), [id])

  useHotkeys([
    ['Delete', selected
      ? () => { void handleDelete() }
      : () => {}]
  ])

  const { locked } = data

  useEffect(() => {
    if (onHover) {
      onHover(hovered)
    }
  }, [hovered, onHover])

  return (
    <>
      {!shouldOverrideToolbar && (
        <NodeToolbar
          isVisible={locked as boolean}
          showLock={locked as boolean}
          onDelete={handleDelete}
          onDetach={hasParent
            ? onDetach
            : undefined}
        />
      )}
      {allowResize && (
        <NodeResizer
          lineStyle={{
            borderColor: 'white'
          }}
          isVisible={selected}
          minWidth={minWidth}
          minHeight={minHeight}
        />
      )}
      {locked && <div className="locked" />}
      <Paper
        p={noPadding
          ? 0
          : 'xs'}
        className={classes.root}
        pos="relative"
        // TODO: Migrate to hovered prop on NodeProps
        onMouseEnter={() => setIsHovered(!viewOnly)}
        onMouseLeave={() => setIsHovered(false)}
        draggable={false}
        {...widthAndHeight}
      >
        {isLoading && (
          <Box className={classes.loaderContainer}>
            <Loader size={8} color="primary" />
          </Box>
        )}
        {isCustomDrag && !viewOnly && (
          <Box className={classes.target}>
            <IconGripVertical
              className="custom-drag-handle"
              color="gray"
              size={16}
            />
          </Box>
        )}
        <Flex
          align="center"
          justify="center"
          className={classes.bounds}
          {...widthAndHeight}
        >
          {children}
        </Flex>
      </Paper>
      {shouldConnect && (
        <Handles hovered={hovered} nodeId={id} />
      )}
    </>
  )
}

export default memo(BaseNode)
