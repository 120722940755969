import { createStyles, Box, Table as MantineTable } from '@mantine/core'
import { getBackgroundColor } from 'src/utils/theme'
import EmptyPlaceholder from 'src/Reports/Viewer/EmptyPlaceholder'
import { type AgentTripItem, type ParsedTripItem } from 'src/Reports/Viewer/TripReportView'
import AverageMaxHead from './AverageMaxHead'
import Row from './Row'

const useStyles = createStyles((theme) => ({
  head: {
    position: 'sticky',
    ...getBackgroundColor(theme),
    zIndex: 3
  }
}))

interface Props {
  items: ParsedTripItem['trips']
  containerWidth: number
}

export default function Trips ({ items, containerWidth }: Props) {
  const { classes } = useStyles()

  return (
    <Box
      sx={{
        width: containerWidth,
        overflowY: 'auto',
        scrollbarWidth: 'thin'
      }}
    >
      <MantineTable fontSize="xs">
        <thead className={classes.head}>
          <tr>
            <th>Start Date/Time</th>
            <th>End Date/Time</th>
            <th>Driver</th>
            <th>Start Location</th>
            <th>End Location</th>
            <th>Driving Duration</th>
            <th>Idling Duration</th>
            <th>KMs Travelled</th>
            <AverageMaxHead label="Speed (Kmh)" />
            <th>Max Accel (X)</th>
            <th>Max Accel (Y)</th>
            <th>Max Accel (Z)</th>
            <th>View Trip</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item: AgentTripItem) => (
            <Row key={item.trip_id} data={item} />
          ))}
        </tbody>
      </MantineTable>
      {items.length === 0 && <EmptyPlaceholder />}
    </Box>
  )
}
