import React from 'react'
import { ActionIcon, Tooltip } from '@mantine/core'
import { IconZoomIn } from '@tabler/icons-react'
import Link from 'src/Layout/Link'

interface Props {
  identifier: string
  to: string
  icon?: JSX.Element
}

function NodeLinkButton ({ identifier, to, icon }: Props) {
  return (
    <Tooltip label={`Go to ${identifier}`}>
      <ActionIcon
        size="xs"
        variant="filled"
        component={Link}
        to={to}
        color="primary"
      >
        {icon ?? (
          <IconZoomIn size={12} color="white" />
        )}
      </ActionIcon>
    </Tooltip>
  )
}

export default NodeLinkButton
