import {
  type MantineColor,
  type MantineTheme,
  type TextProps,
  useMantineTheme
} from '@mantine/core'
import { type CSSProperties } from 'react'

export interface ThemeOverrides {
  bodyBg: string
  text: string
}

export const getBackgroundColor = ({
  colors,
  colorScheme
}: MantineTheme,
lightAmount?: number) => {
  const isLight = colorScheme === 'light'
  const amount = lightAmount ?? 0

  return {
    backgroundColor: isLight
      ? colors.gray[2 + amount]
      : colors.dark[7 - amount]
  }
}

export const getWhiteBackgroundColor = ({ colors, colorScheme }: MantineTheme) => {
  const isLight = colorScheme === 'light'
  return {
    backgroundColor: isLight
      ? colors.white[0]
      : colors.dark[7]
  }
}

export const getTextColor = ({ colors, colorScheme }: MantineTheme) => {
  const isLight = colorScheme === 'light'

  return {
    color: isLight
      ? colors.dark[6]
      : colors.gray[2]
  }
}

export const getActiveTextColor = ({ colors }: MantineTheme) => ({
  color: colors.white[0]
})

export const getPrimaryBgColor = ({ colors, colorScheme }: MantineTheme) => {
  const isLight = colorScheme === 'light'

  return {
    backgroundColor: isLight
      ? colors.primary[7]
      : colors.dark[7]
  }
}

export const getNonThemePrimaryBg = ({ colors }: MantineTheme) => ({
  backgroundColor: colors.primary[7]
})

export const getPrimaryTextColor = ({ colors, colorScheme }: MantineTheme) => {
  const isLight = colorScheme === 'light'

  return {
    color: isLight
      ? colors.gray[0]
      : colors.dark[0]
  }
}

export const getLinkTextColor = ({ colors, colorScheme }: MantineTheme) => {
  const isLight = colorScheme === 'light'

  return {
    color: isLight
      ? colors.primary[6]
      : colors.primary[2]
  }
}

export const getTextColorAsProp = (): Pick<TextProps, 'color'> => {
  const { colorScheme, colors } = useMantineTheme()
  const isLight = colorScheme === 'light'

  return {
    color: isLight
      ? colors.dark[6]
      : colors.gray[2]
  }
}

export const getColorAsProp = (): MantineColor => {
  const { colorScheme, colors } = useMantineTheme()
  const isLight = colorScheme === 'light'

  return isLight
    ? colors.dark[6]
    : colors.gray[2]
}

export const getPlaceholderColorAsProp = (): Pick<TextProps, 'color'> => {
  const { colorScheme, colors } = useMantineTheme()
  const isLight = colorScheme === 'light'

  return {
    color: isLight
      ? colors.gray[5]
      : colors.dark[2]
  }
}

/**
 * @description Gray before White
 * @param flag
 * @returns color: string
 */
export const getToggleableColorGBW = (flag: boolean) => {
  const { colorScheme, colors } = useMantineTheme()
  const isLight = colorScheme === 'light'

  return flag
    ? isLight
      ? colors.white[0]
      : colors.dark[7]
    : isLight
      ? colors.gray[0]
      : colors.dark[8]
}

/**
 * @description White before Gray
 * @param flag
 * @returns color: string
 */
export const getToggleableColorWBG = (flag: boolean) => {
  const { colorScheme, colors } = useMantineTheme()
  const isLight = colorScheme === 'light'

  return flag
    ? isLight
      ? colors.gray[0]
      : colors.dark[8]
    : isLight
      ? colors.white[0]
      : colors.dark[7]
}

export const getGradientBgColor = ({ colorScheme, colors }: MantineTheme) => {
  const isLight = colorScheme === 'light'

  return {
    background: isLight
      ? `linear-gradient(to bottom right, ${colors.brand[0]}, ${colors.brand[1]})`
      : `linear-gradient(to bottom right, ${colors.dark[0]}, ${colors.dark[4]})`
  }
}

export const useTheme = (): CSSProperties => {
  const theme = useMantineTheme()

  return getBackgroundColor(theme)
}
