import React from 'react'
import { Box, createStyles, Paper, Text } from '@mantine/core'

interface StyleProps {
  isAlarm: boolean
  isLoading?: boolean
}

const useStyles = createStyles(({ colors }, { isAlarm, isLoading }: StyleProps) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8
  },
  indicator: {
    backgroundColor: isLoading
      ? colors.gray[3]
      : isAlarm
        ? colors.red[6]
        : colors.green[6],
    animation: isAlarm
      ? 'pulse-alarm 2s infinite'
      : 'unset',
    width: 12,
    height: 12,
    borderRadius: '50%'
  }
}))

interface Props {
  isAlarm: boolean
  label?: string
  isLoading?: boolean
}

function StatusBadge ({ isAlarm, isLoading, label }: Props) {
  const { classes } = useStyles({ isAlarm, isLoading })

  return (
    <Paper
      className={classes.container}
      shadow="md"
      withBorder
      radius="lg"
      pr={8}
      pl={2}
      py={2}
    >
      <Box className={classes.indicator} />
      {label && (
        <Text
          size={8}
          weight={isAlarm
            ? 'bold'
            : 'normal'}
          color={isLoading
            ? 'dimmed'
            : undefined}
        >
          {label}
        </Text>
      )}
    </Paper>
  )
}

export default StatusBadge
