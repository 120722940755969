import { Link } from 'react-router-dom'
import { Button } from '@mantine/core'
import { IconRoute } from '@tabler/icons-react'
import { toUtcWithOffset } from 'src/utils/dates'

interface Props {
  agentId: string
  tripId: string
  startTime: string
}

export default function ViewTripButton ({ agentId, tripId, startTime }: Props) {
  const formattedStartTime = encodeURIComponent(toUtcWithOffset(startTime) ?? startTime)

  return agentId && tripId && startTime && (
    <Button
      component={Link}
      to={`/?agentId=${agentId}&tripId=${tripId}&startTime=${formattedStartTime}`}
      size="xs"
      leftIcon={<IconRoute size={12} stroke={2} />}
      variant="outline"
      color="primary"
    >
      View trip
    </Button>
  )
}
