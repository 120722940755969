/* eslint-disable @typescript-eslint/naming-convention */
import { useContext } from 'react'
import {
  createStyles,
  useMantineTheme,
  Accordion,
  Group,
  Text
} from '@mantine/core'
import { getWhiteBackgroundColor } from 'src/utils/theme'
import { IconPrinter, IconSitemap } from '@tabler/icons-react'
import { Context } from 'src/Layout/ReportTemplate/ReportContext'
import { toProperCase } from 'src/utils/strings'
import { downloadCSV } from 'src/utils/files'
import { transformTankLevelReportDataToCSV } from '..'
import { type SiteTankLevels } from '..'
import ExportButton from '../../ExportButton'
import List from './List'
import Chart from './Chart'

const useStyle = createStyles((theme) => ({
  root: {
    ...getWhiteBackgroundColor(theme)
  },
  panel: {
    ...getWhiteBackgroundColor(theme)
  }
}))

interface Props {
  data: SiteTankLevels[]
}

export default function Content ({ data }: Props) {
  const { report, timePeriod } = useContext(Context)
  const { colorScheme } = useMantineTheme()
  const { classes } = useStyle()

  return (
    <Accordion
      className={classes.root}
      styles={{
        chevron: {
          marginLeft: 2
        }
      }}
      defaultValue={[data[0].site_id.toString()]}
      variant="filled"
      radius="xs"
      multiple
    >
      {data.map((item) => {
        const {
          site_id,
          site_name,
          list,
          graph
        } = item
        const siteName = toProperCase(site_name)

        return (
          <Accordion.Item key={site_id} value={site_id.toString()}>
            <Accordion.Control
              icon={(
                <IconSitemap
                  size={18}
                  stroke={1.8}
                  color="#228BE6"
                />
              )}
            >
              <Group position="apart" align="center">
                <Text weight={500} size={14}>
                  {siteName}
                </Text>
                {report && (
                  <ExportButton
                    Icon={IconPrinter}
                    data={[item]}
                    onClickDownloadAsCSV={() => {
                      const transformedData = transformTankLevelReportDataToCSV(list)

                      downloadCSV(transformedData, `${siteName} - ${report?.name}`)
                    }}
                  />
                )}
              </Group>
            </Accordion.Control>
            <Accordion.Panel className={classes.panel}>
              <List items={list} />
              <Chart
                timePeriod={timePeriod}
                items={graph}
                unit="L"
                colorScheme={colorScheme}
                height={350}
                margin={{
                  top: 30,
                  right: 20,
                  bottom: 70,
                  left: 40
                }}
                unstacked
                disabled={graph.length === 0}
              />
            </Accordion.Panel>
          </Accordion.Item>
        )
      })}
    </Accordion>
  )
}
