import {
  createStyles,
  getStylesRef,
  Anchor,
  Group,
  Text
} from '@mantine/core'
import { Link } from 'react-router-dom'
import { mq } from 'src/utils/style'
import { useLocation } from 'react-router'
import {
  getActiveTextColor,
  getPrimaryTextColor,
  getTextColor,
  getWhiteBackgroundColor
} from 'src/utils/theme'
import { type MenuItem as MenuItemType } from 'src/menuData'
import TooltipWrapper from './TooltipWrapper'

export interface MenuItemProps {
  item: MenuItemType
  isExtraFromMobile?: boolean
  open?: boolean
  isDesktop?: boolean
  isSidebarOpen?: boolean
  setSidebarOpen?: (status: boolean) => void
  closeMenu: () => void
  onClick?: React.MouseEventHandler<unknown>
}

const useMenuItemStyles = createStyles((theme) => {
  const menuItemLabel: string = getStylesRef('menuItemLabel')
  const menuItemIcon: string = getStylesRef('menuItemIcon')
  return {
    menuItem: {
      display: 'flex',
      color: 'black',
      width: '100%',
      ...getWhiteBackgroundColor(theme),
      padding: '16px 18px',
      '&:hover': {
        backgroundColor: theme.colors.primary[7],
        ...getPrimaryTextColor(theme),
        textDecoration: 'none',
        opacity: '.9'
      },
      [`&:hover .${menuItemLabel}`]: {
        ...getActiveTextColor(theme)
      },
      [`&:hover .${menuItemIcon}`]: {
        ...getActiveTextColor(theme)
      }
    },
    menuItemActive: {
      backgroundColor: theme.colors.primary[7],
      color: 'white',
      textDecoration: 'none',
      opacity: 1
    },
    menuItemIcon: {
      width: '20px'
    },
    menuItemLabel: {
      ref: menuItemLabel,
      ...getTextColor(theme),
      fontSize: '0.9rem',
      textAlign: 'left',
      paddingLeft: '15px'
    },
    menuItemLabelActive: {
      ...getActiveTextColor(theme)
    },
    menuButton: {
      position: 'fixed',
      top: '33px',
      right: '30px',
      width: '20px',
      display: 'initial',
      [mq(theme.breakpoints.sm)]: {
        position: 'initial'
      }
    },
    icon: {
      ref: menuItemIcon,
      ...getTextColor(theme)
    },
    iconActive: {
      ...getActiveTextColor(theme)
    }
  }
})

export default function MenuItem ({
  item: {
    title,
    path,
    Icon,
    selector
  },
  isExtraFromMobile = false,
  isSidebarOpen,
  closeMenu,
  onClick
}: MenuItemProps) {
  const { classes, cx } = useMenuItemStyles()
  const trigger = (ev: React.MouseEvent) => {
    if (onClick) onClick(ev)
    closeMenu()
  }
  const location = useLocation()
  const isSamePath = isExtraFromMobile
    ? false
    : location.pathname === path

  return (
    <TooltipWrapper label={title} isSidebarOpen={isSidebarOpen}>
      <Anchor
        id={`btn-${selector}`}
        component={Link}
        to={path}
        className={cx(classes.menuItem, { [classes.menuItemActive]: isSamePath })}
        onClick={trigger}
      >
        <Group spacing="xs" position="left">
          <Icon
            id={selector}
            size={24}
            className={cx(classes.icon, {
              [classes.iconActive]: isSamePath
            })}
          />
          <Text
            className={cx(classes.menuItemLabel, {
              [classes.menuItemLabelActive]: isSamePath
            })}
          >
            {title}
          </Text>
        </Group>
      </Anchor>
    </TooltipWrapper>
  )
}
