import {
  useState,
  useCallback,
  useEffect,
  useRef,
  createContext,
  type ReactElement,
  type ReactNode,
  useContext
} from 'react'
import {
  type Node,
  useReactFlow,
  getConnectedEdges,
  type Edge,
  type XYPosition,
  useStore
} from '@xyflow/react'
import useShortcut from './useShortcut'

interface Props {
  noKeyboardShortcut?: boolean
}
interface ProviderProps {
  children?: JSX.Element | ReactElement | ReactNode
}

interface CopyPasteProps {
  bufferedNodes: Node[]
  bufferedEdges: Edge[]
  setBufferedNodes: (nodes: Node[]) => void
  setBufferedEdges: (nodes: Edge[]) => void
}

const CopyPasteContext = createContext<CopyPasteProps>({
  bufferedNodes: [],
  bufferedEdges: [],
  setBufferedNodes: () => {},
  setBufferedEdges: () => {}
})

export const CopyPasteProvider = ({ children }: ProviderProps) => {
  const [bufferedNodes, setBufferedNodes] = useState([] as Node[])
  const [bufferedEdges, setBufferedEdges] = useState([] as Edge[])

  return (
    <CopyPasteContext.Provider value={{
      bufferedNodes,
      bufferedEdges,
      setBufferedNodes,
      setBufferedEdges
    }}
    >
      {children}
    </CopyPasteContext.Provider>
  )
}

export function useCopyPaste ({ noKeyboardShortcut = false }: Props) {
  const mousePosRef = useRef<XYPosition>({ x: 0, y: 0 })
  const rfDomNode = useStore(({ domNode }) => domNode)

  const {
    getNodes,
    setNodes,
    getEdges,
    setEdges,
    screenToFlowPosition
  } = useReactFlow<Node, Edge>()

  // Set up the paste buffers to store the copied nodes and edges.
  const {
    bufferedNodes,
    bufferedEdges,
    setBufferedNodes,
    setBufferedEdges
  } = useContext(CopyPasteContext)

  // initialize the copy/paste hook
  // 1. remove native copy/paste/cut handlers
  // 2. add mouse move handler to keep track of the current mouse position
  useEffect(() => {
    const events = ['cut', 'copy', 'paste']

    if (rfDomNode) {
      const preventDefault = (e: Event) => e.preventDefault()

      const onMouseMove = (event: MouseEvent) => {
        mousePosRef.current = {
          x: event.clientX,
          y: event.clientY
        }
      }

      for (const event of events) {
        rfDomNode.addEventListener(event, preventDefault)
      }

      rfDomNode.addEventListener('mousemove', onMouseMove)

      return () => {
        for (const event of events) {
          rfDomNode.removeEventListener(event, preventDefault)
        }

        rfDomNode.removeEventListener('mousemove', onMouseMove)
      }
    }
  }, [rfDomNode])

  const copy = useCallback(() => {
    const selectedNodes = getNodes().filter((node) => node.selected)
    const selectedEdges = getConnectedEdges(selectedNodes, getEdges()).filter(
      ({ source, target }) => {
        const isExternalSource = selectedNodes.every(
          ({ id }) => id !== source
        )
        const isExternalTarget = selectedNodes.every(
          ({ id }) => id !== target
        )

        return !(isExternalSource || isExternalTarget)
      }
    )

    setBufferedNodes(selectedNodes)
    setBufferedEdges(selectedEdges)
  }, [getNodes, getEdges])

  const cut = useCallback(() => {
    const selectedNodes = getNodes().filter((node) => node.selected)
    const selectedEdges = getConnectedEdges(selectedNodes, getEdges()).filter(
      ({ source, target }) => {
        const isExternalSource = selectedNodes.every(
          ({ id }) => id !== source
        )
        const isExternalTarget = selectedNodes.every(
          ({ id }) => id !== target
        )

        return !(isExternalSource || isExternalTarget)
      }
    )

    setBufferedNodes(selectedNodes)
    setBufferedEdges(selectedEdges)

    // A cut action needs to remove the copied nodes and edges from the graph.
    setNodes((nodes) => nodes.filter((node) => !node.selected))
    setEdges((edges) => edges.filter((edge) => !selectedEdges.includes(edge)))
  }, [getNodes, setNodes, getEdges, setEdges])

  const paste = useCallback(
    (
      { x: pasteX, y: pasteY } = screenToFlowPosition({
        x: mousePosRef.current.x,
        y: mousePosRef.current.y
      })
    ) => {
      const minX = Math.min(...bufferedNodes.map((s) => s.position.x))
      const minY = Math.min(...bufferedNodes.map((s) => s.position.y))

      const now = Date.now()

      const newNodes: Node[] = bufferedNodes.map((node) => {
        const id = `${node.id}-${now}`
        const finalX = isNaN(pasteX)
          ? 0
          : pasteX
        const finalY = isNaN(pasteY)
          ? 0
          : pasteY

        const x = finalX + (node.position.x - minX)
        const y = finalY + (node.position.y - minY)

        return {
          ...node,
          id,
          position: {
            x,
            y
          }
        }
      })

      const newEdges: Edge[] = bufferedEdges.map((edge) => {
        const id = `${edge.id}-${now}`
        const source = `${edge.source}-${now}`
        const target = `${edge.target}-${now}`

        return {
          ...edge,
          id,
          source,
          target
        }
      })

      setNodes((nodes) => [
        ...nodes.map((node) => ({ ...node, selected: false })),
        ...newNodes
      ])
      setEdges((edges) => [
        ...edges.map((edge) => ({ ...edge, selected: false })),
        ...newEdges
      ])
      // reset
      setBufferedNodes([])
    },
    [
      bufferedNodes,
      bufferedEdges,
      screenToFlowPosition,
      setNodes,
      setEdges
    ]
  )

  if (!noKeyboardShortcut) {
    useShortcut(['Meta+x', 'Control+x'], cut)
    useShortcut(['Meta+c', 'Control+c'], copy)
    useShortcut(['Meta+v', 'Control+v'], paste)
  }

  return {
    cut,
    copy,
    paste,
    bufferedNodes,
    bufferedEdges
  }
}

export default useCopyPaste
