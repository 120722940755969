import {
  Button,
  Code,
  Collapse,
  ScrollArea,
  Stack,
  Text
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useNodesData, useReactFlow } from '@xyflow/react'
import { useNode } from '../Wizard/WizardContext'

const isDev = process.env.NODE_ENV === 'development'

function Inspector () {
  const { selectedNode } = useNode()
  if (!selectedNode) return null

  const { getNode } = useReactFlow()
  const nodeData = useNodesData(selectedNode.id)
  const [show, { toggle }] = useDisclosure(false)

  const updatedNode = getNode(selectedNode.id)

  return isDev && (
    <Stack spacing={4}>
      <Text size="xs">Data</Text>
      <ScrollArea h={230}>
        <Code block>
          {JSON.stringify(nodeData, null, 2)}
        </Code>
      </ScrollArea>
      <Button size="xs" variant="subtle" onClick={toggle}>View raw data</Button>
      <Collapse in={show}>
        <ScrollArea h={300}>
          <Code block>
            {JSON.stringify(updatedNode ?? 'N/A', null, 2)}
          </Code>
        </ScrollArea>
      </Collapse>
    </Stack>
  )
}

export default Inspector
