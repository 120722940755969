/* eslint-disable @typescript-eslint/naming-convention */
import { View } from '@react-pdf/renderer'
import THCell from 'src/Layout/ReportTemplate/Components/THCell'
import TDCell from 'src/Layout/ReportTemplate/Components/TDCell'
import { reportStyles } from 'src/Layout/ReportTemplate/styles'
import dayjs from 'dayjs'
import { uiDateFormatUniversal } from 'src/utils/dates'
import { type ParsedSpeedEvent } from '../../../SpeedingReportView'

interface RowProps {
  index: number
  data: ParsedSpeedEvent['speed_alerts'][0]
}

const Row = ({
  index,
  data: {
    speed_date_time,
    location,
    geo_zone_name,
    speed_limit,
    speed
  }
}: RowProps) => (
  <View style={reportStyles.group}>
    <TDCell value={`${index + 1}`} />
    <TDCell value={dayjs(speed_date_time).format(uiDateFormatUniversal)} />
    <TDCell value={location} />
    <TDCell value={geo_zone_name} />
    <TDCell value={speed_limit} />
    <TDCell value={speed} />
  </View>
)

interface Props {
  items: ParsedSpeedEvent['speed_alerts']
}

export default function ChildTable ({ items }: Props) {
  return (
    <View>
      <View style={[reportStyles.group]}>
        <THCell value="Event #" />
        <THCell value="Event Date/Time" />
        <THCell value="Location" />
        <THCell value="Geozone" />
        <THCell value="Speed Limit" />
        <THCell value="Speed Violation" />
      </View>
      {items.map((item, index) => (
        <Row key={item.id} index={index} data={item} />
      ))}
    </View>
  )
}
