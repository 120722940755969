import React, { memo, useCallback, useEffect, useState } from 'react'
import { IconPhotoScan } from '@tabler/icons-react'
import { useNodesData, useReactFlow } from '@xyflow/react'
import { Image, Space } from '@mantine/core'
import { SELECTED_COLOR } from '../shared'
import ImageDropzone from '../Components/ImageDropzone'
import { imageStyles } from '../sharedStyles'
import { type ImgData } from '../Nodes/ImageNode'
import useGenericImage from '../hooks/useGenericImage'
import { useNode } from './WizardContext'
import BaseWizard from './BaseWizard'
import CustomizationControls from './CustomizationControls'

function ImageWizard () {
  const { classes } = imageStyles()
  const { selectedNode } = useNode()
  const { updateNodeData } = useReactFlow()
  const [imageUrl, setImageUrl] = useState<string>()
  const { load, upload, remove } = useGenericImage()

  const nodeData = useNodesData(selectedNode?.id ?? '')

  const loadImage = useCallback(async () => {
    if (nodeData) {
      const { image } = nodeData.data as ImgData

      if (image) {
        const url = await load(image.previewUrl)
        setImageUrl(url)
      } else {
        setImageUrl(undefined)
      }
    }
  }, [nodeData])

  const onChangeImage = useCallback(async (file?: File) => {
    if (file && nodeData) {
      const { id, data } = nodeData
      const { image } = data as ImgData
      // check if there was an existing image, then we remove it
      if (image?.id) {
        void remove(image.id)
      }

      void upload([file], (response) => {
        updateNodeData(id, { image: response[0] })
      })
    }
  }, [nodeData])

  useEffect(() => {
    if (nodeData) {
      void loadImage()
    }
  }, [nodeData])

  return selectedNode?.type === 'imageNode' && nodeData && (
    <BaseWizard
      expectLongContent
      title="Image Wizard"
      Icon={<IconPhotoScan size={24} color={SELECTED_COLOR} />}
    >
      <CustomizationControls nodeId={selectedNode?.id} hasOpacity />
      <Space h="md" />
      <ImageDropzone
        setImage={onChangeImage}
        renderImage={imageUrl && (
          <Image
            src={imageUrl}
            fit="contain"
            classNames={{
              root: classes.maxWidthAndHeight,
              figure: classes.maxWidthAndHeight,
              imageWrapper: classes.maxWidthAndHeight,
              image: classes.maxWidthAndHeight
            }}
          />
        )}
      />
    </BaseWizard>
  )
}

export default memo(ImageWizard)
