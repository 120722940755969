import { useContext, useEffect } from 'react'
import {
  type PopoverProps,
  ActionIcon,
  Box,
  Button,
  Popover,
  Stack,
  Text
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { type Icon, IconFileTypeCsv } from '@tabler/icons-react'
import { Context } from 'src/Layout/ReportTemplate/ReportContext'
import { type ParsedTripItem } from './TripReportView'
import { type ParsedSpeedEvent } from './SpeedingReportView'
import { type ParsedDuressEvent } from './DuressReportView'
import { type ParsedIdleEvent } from './IdleReportView'
import { type ParsedGeozoneEvent } from './GeozoneReportView'
import { type SiteTankLevels } from './TankLevelReportView'
import ExportToPdf from './ExportToPdf'

type Parsed =
  ParsedTripItem[] |
  ParsedSpeedEvent[] |
  ParsedDuressEvent[] |
  ParsedIdleEvent[] |
  ParsedGeozoneEvent[] |
  SiteTankLevels[]

interface Props {
  Icon: Icon
  queryId?: number
  data?: Parsed
  onClickDownloadAsCSV: () => void
  withinPortal?: PopoverProps['withinPortal']
  disabled?: boolean
}

export default function ExportButton ({
  Icon,
  queryId,
  data,
  onClickDownloadAsCSV,
  withinPortal = true,
  disabled = false
}: Props) {
  const {
    report,
    timePeriod,
    range,
    reportRawData,
    isGenerating,
    isDownloading
  } = useContext(Context)
  const [opened, { close, toggle }] = useDisclosure()
  const isBusy = isGenerating || isDownloading
  const disabledDownload = disabled ||
  typeof data === 'undefined' ||
  data.length === 0 ||
  isBusy

  useEffect(() => {
    // This will automatically close this component once
    // new report generation has been triggered
    if (opened && isGenerating) {
      close()
    }
  }, [isGenerating])

  useEffect(() => {
    // This will automatically close this component once
    // downloading file has been completed
    if (opened && !isDownloading) {
      close()
    }
  }, [isDownloading])

  return (
    <Box onClick={event => event.stopPropagation()}>
      <Popover
        opened={opened}
        position="bottom-end"
        arrowOffset={12}
        withinPortal={withinPortal}
        onClose={() => {
          // This will allow Popover component to be closed if the report
          // is not generating data or downloading a file
          if (!isBusy) {
            close()
          }
        }}
        withArrow
      >
        <Popover.Target>
          <ActionIcon
            size={36}
            onClick={toggle}
            disabled={disabledDownload}
          >
            <Icon size={16} />
          </ActionIcon>
        </Popover.Target>
        <Popover.Dropdown>
          <Stack spacing="xs">
            <Text color="dimmed" size="xs">
              Export data
            </Text>
            <Button
              leftIcon={<IconFileTypeCsv size={16} />}
              variant="subtle"
              color="primary"
              onClick={onClickDownloadAsCSV}
              disabled={disabledDownload}
            >
              Download as CSV
            </Button>
            {report && (
              <ExportToPdf
                report={report}
                rawData={reportRawData}
                data={data}
                timePeriod={timePeriod}
                range={range}
                queryId={queryId}
                disabled={disabledDownload}
              />
            )}
          </Stack>
        </Popover.Dropdown>
      </Popover>
    </Box>
  )
}
