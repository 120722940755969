import React, { useCallback, useEffect, useState } from 'react'
import { IconBox } from '@tabler/icons-react'
import SelectAgent from 'src/Input/Select/SelectAgent'
import { useReactFlow } from '@xyflow/react'
import { SELECTED_COLOR } from '../shared'
import BaseWizard from './BaseWizard'
import { useNode } from './WizardContext'

function AgentWizard () {
  const { updateNode } = useReactFlow()
  const { selectedNode } = useNode()

  const [agentId, setAgentId] = useState<string | null>(null)

  useEffect(
    () => {
      if (selectedNode?.data?.agentId) {
        const selectedId = selectedNode?.data?.agentId as string
        setAgentId(selectedId)
      } else {
        // node not set yet
        setAgentId(null)
      }
    },
    [selectedNode?.data?.agentId]
  )

  const handleUpdate = useCallback(
    (id: string | null, name?: string) => {
      if (selectedNode && (id ?? name)) {
        updateNode(selectedNode.id, {
          data: {
            agentId: id,
            agentName: name
          }
        })
      }
    },
    [selectedNode]
  )

  return selectedNode && selectedNode.type === 'agentNode' && (
    <BaseWizard
      title="Agent Wizard"
      Icon={<IconBox size={24} color={SELECTED_COLOR} />}
    >
      <SelectAgent
        searchable
        value={agentId}
        label="Select an agent"
        onChange={handleUpdate}
      />
    </BaseWizard>
  )
}

export default AgentWizard
