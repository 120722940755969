import React, { useEffect, useState } from 'react'
import {
  Container,
  Table as MantineTable,
  Text,
  createStyles,
  Button
} from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { type AgentDetails } from '@venturi-io/api/src/config/agent'
import { usePaginatedApi } from 'src/utils/useApi'
import { type TripSummary, getAgentTrips } from '@venturi-io/api/src/collector/trip'
import { useUser } from 'src/UserContext'
import dayjs from 'dayjs'
import { toUtc, uiDateFormat } from 'src/utils/dates'
import NoData from 'src/Dashboard/Items/shared/NoData'
import Nothing from 'src/Nothing'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { reverseGeocode } from 'src/Maps/GeoZoneMap/mapbox'
import { round } from 'src/utils/math'
import { getBackgroundColor } from 'src/utils/theme'
import { secondsToDhms, useSharedStyles } from '../shared'
import { MODULE_CONTENT } from './constants'

const useStyles = createStyles((theme, { stickyTop }: { stickyTop: number }) => ({
  container: {
    background: 'white'
  },
  head: {
    position: 'sticky',
    top: stickyTop,
    ...getBackgroundColor(theme),
    zIndex: 3
  },
  row: {
    zIndex: 2
  }
}))

interface Props {
  agent?: AgentDetails
  agentId?: number
  showTitle?: boolean
  startTime?: string
  endTime?: string
  style?: React.CSSProperties
  stickyTop?: number
  hideRfid?: boolean
  didChangeHeight?: (height: number, key: string) => void
  hideAccelColumns?: boolean
}

export interface TableProps extends Pick<Props, 'didChangeHeight' | 'stickyTop' | 'hideRfid' | 'hideAccelColumns'> {
  agentId: number
  trips: TripSummary[]
}

interface LocationCellProps {
  longitude?: number
  latitude?: number
}

interface AverageMaxHeadProps {
  label: string
}

function AverageMaxHead ({ label }: AverageMaxHeadProps) {
  return (
    <th colSpan={2} style={{ width: 160 }}>
      <tr>
        <td colSpan={2}>{label}</td>
      </tr>
      <tr>
        <td
          style={{
            minWidth: 70
          }}
        >
          Average
        </td>
        <td
          style={{
            minWidth: 70
          }}
        >
          Max
        </td>
      </tr>
    </th>
  )
}

function LocationCell ({ longitude, latitude }: LocationCellProps) {
  const [address, setAddress] = useState('...')
  const loadAddress = async () => {
    if (longitude && latitude) {
      const result = await reverseGeocode([longitude, latitude])
      setAddress(result)
    }
  }

  useEffect(() => {
    if (longitude && latitude) {
      void loadAddress()
    }
  }, [longitude, latitude])

  return (
    <td>{address}</td>
  )
}

function Rows ({
  trips,
  agentId,
  hideRfid,
  hideAccelColumns
}: TableProps) {
  return trips.length > 0
    ? (
      <>
        {trips.map(({
          tripId,
          startTime,
          endTime,
          distance,
          startPoint,
          endPoint,
          rfidTag,
          user,
          speedMax,
          speedAvg,
          idlingDuration,
          drivingDuration,
          xAccelerationMax,
          yAccelerationMax,
          zAccelerationMax
        }) => (
          <tr key={tripId}>
            <td>{dayjs(startTime).format(uiDateFormat)}</td>
            <td>{dayjs(endTime).format(uiDateFormat)}</td>
            {!hideRfid && (<td>{rfidTag ?? '-'}</td>)}
            <td>
              {user
                ? `${user.firstName} ${user.lastName}`
                : '-'}
            </td>
            <LocationCell longitude={startPoint.longitude} latitude={startPoint.latitude} />
            <LocationCell longitude={endPoint.longitude} latitude={endPoint.latitude} />
            <td>{secondsToDhms(drivingDuration ?? 0)}</td>
            <td>{secondsToDhms(idlingDuration ?? 0)}</td>
            <td>{round(distance / 1000)}</td>
            <td>
              {speedAvg
                ? round(speedAvg)
                : '-'}
            </td>
            <td>
              {speedMax
                ? round(speedMax)
                : '-'}
            </td>
            {!hideAccelColumns && (
              <>
                <td>
                  {xAccelerationMax
                    ? round(xAccelerationMax)
                    : '-'}
                </td>
                <td>
                  {yAccelerationMax
                    ? round(yAccelerationMax)
                    : '-'}
                </td>
                <td>
                  {zAccelerationMax
                    ? round(zAccelerationMax)
                    : '-'}
                </td>
              </>
            )}
            <td>
              <Link to={`/?agentId=${agentId}&tripId=${tripId}&startTime=${encodeURIComponent(startTime)}`}>
                <Button
                  size="xs"
                  leftIcon={<FontAwesomeIcon icon={['far', 'route']} />}
                  variant="outline"
                  color="primary"
                >
                  View trip
                </Button>
              </Link>
            </td>
          </tr>
        ))}
      </>
      )
    : (
      <tr>
        <td colSpan={18}><NoData message="No trips available" /></td>
      </tr>
      )
}

const Table = ({
  trips,
  agentId,
  stickyTop,
  hideRfid = false,
  hideAccelColumns = false,
  didChangeHeight
}: TableProps) => {
  const { classes } = useStyles({ stickyTop: stickyTop ?? 0 })
  const { ref, height } = useElementSize()

  useEffect(() => {
    if (didChangeHeight) {
      didChangeHeight(height, 'trips')
    }
  }, [height])

  return (
    <MantineTable
      ref={ref}
      fontSize="xs"
    >
      <thead className={classes.head}>
        <tr>
          <th>Start Date/Time</th>
          <th>End Date/Time</th>
          {!hideRfid && (<th>RFID</th>)}
          <th>Driver</th>
          <th>Start Location</th>
          <th>End Location</th>
          <th>Driving Duration</th>
          <th>Idling Duration</th>
          <th>KMs Travelled</th>
          <AverageMaxHead label="Speed (Kmh)" />
          {!hideAccelColumns && (
            <>
              <th>Max Accel (X)</th>
              <th>Max Accel (Y)</th>
              <th>Max Accel (Z)</th>
            </>
          )}
          <th>View Trip</th>
        </tr>
      </thead>
      <tbody>
        <Rows
          trips={trips}
          agentId={agentId}
          hideRfid={hideRfid}
          hideAccelColumns={hideAccelColumns}
        />
      </tbody>
    </MantineTable>
  )
}

export default function Trips ({
  agent,
  agentId: directAgentId,
  showTitle = true,
  startTime,
  endTime,
  style,
  stickyTop = 0,
  hideRfid = false,
  hideAccelColumns = false,
  didChangeHeight
}: Props) {
  const id = agent
    ? agent.agentId
    : directAgentId ?? -1
  const { token } = useUser()
  const trips = usePaginatedApi(getAgentTrips)

  const { classes: sharedClasses } = useSharedStyles()
  const { ref } = useElementSize()

  const loadTrips = () => {
    void trips.fetch({
      agentId: id,
      page: trips.page ?? 1,
      size: 999,
      startTime: toUtc(startTime ?? dayjs().subtract(24, 'hour')) + 'Z',
      endTime: toUtc(endTime ?? dayjs()) + 'Z'
    }, token)
  }

  useEffect(() => {
    void loadTrips()
  }, [id])

  return (
    <Container ref={ref} className={sharedClasses.dock} fluid style={style}>
      {showTitle && (
        <Text
          transform="uppercase"
          size="sm"
          weight={500}
        >
          {MODULE_CONTENT.VEHICLE_TRIP}
        </Text>
      )}

      {trips.data.caseOf({
        Nothing: () => (
          <Nothing
            isLoading={trips.loading}
            nothing={trips.data.isNothing()}
          />
        ),
        Just: ({ items }) => (
          <Table
            didChangeHeight={didChangeHeight}
            agentId={id}
            trips={items}
            stickyTop={stickyTop}
            hideRfid={hideRfid}
            hideAccelColumns={hideAccelColumns}
          />
        )
      })}
    </Container>
  )
}
