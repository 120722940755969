import {
  createStyles,
  getStylesRef,
  Burger,
  Text,
  useMantineTheme,
  Box
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import Alarms from 'src/Layout/Alarms'
import { mq } from 'src/utils/style'
import {
  getPrimaryBgColor,
  getPrimaryTextColor,
  getTextColorAsProp,
  getWhiteBackgroundColor
} from 'src/utils/theme'

export interface CloseMenuProps {
  show: boolean
  showLabel?: boolean
  setShow: (input: boolean) => void
}

const closeItemStyles = createStyles(theme => {
  const menuItemLabel = getStylesRef('menuItemLabel')

  return {
    closeMenu: {
      ...getPrimaryBgColor(theme),
      cursor: 'pointer',
      display: 'flex',
      height: '48px',
      alignItems: 'center',
      padding: '5px 5px 5px 14px',
      width: '100%',
      '&:hover': {
        ...getPrimaryBgColor(theme)
      }
    },
    mobileCloseMenu: {
      position: 'absolute',
      ...getWhiteBackgroundColor(theme),
      width: 64,
      height: 64,
      top: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    closeMenuLabel: {
      ...getPrimaryTextColor(theme),
      [mq(theme.breakpoints.sm)]: {
        marginLeft: '40px'
      }
    },
    menuButton: {
      position: 'fixed',
      width: 34,
      zIndex: 199,
      transform: 'translate3d(0, 0, 0)',
      [mq(theme.breakpoints.sm)]: {
        position: 'initial',
        zIndex: 'initial'
      }
    },
    menuItemLabel: {
      ref: menuItemLabel,
      color: 'black',
      fontSize: '0.9rem',
      marginLeft: '20px',
      textAlign: 'left',
      [mq(theme.breakpoints.sm)]: {
        marginLeft: '36px'
      }
    },
    notificationContainer: {
      position: 'absolute',
      right: 64,
      top: 0,
      width: 64,
      height: 64,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }
})

export function CloseMenu ({ show, setShow, showLabel }: CloseMenuProps) {
  const { classes, cx } = closeItemStyles()
  const theme = useMantineTheme()
  const isDesktop = useMediaQuery(mq(theme.breakpoints.sm, false))

  return (
    <>
      {!isDesktop && (
        <Box className={classes.notificationContainer}>
          <Alarms showDrawer hideDebug />
        </Box>
      )}
      <div
        className={isDesktop
          ? classes.closeMenu
          : classes.mobileCloseMenu}
        onClick={() => setShow(!show)}
      >
        <Burger
          color={isDesktop
            ? 'white'
            : getTextColorAsProp().color}
          className={classes.menuButton}
          opened={show}
        />
        {showLabel && (
          <Text className={cx(classes.menuItemLabel, classes.closeMenuLabel)}>
            Close
          </Text>
        )}
      </div>
    </>
  )
}
