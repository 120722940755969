import { type ReactNode, useEffect, useState } from 'react'
import { type SensorInstance, getSensorInstances } from '@venturi-io/api/src/config/ui'
import { type MultiSelectProps, type SelectItem } from '@mantine/core'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import MultiSelect from 'src/Input/MultiSelect'
import { hasKeyword } from 'src/utils/strings'

type Value = string[]
type Labels = string[]

export interface Props extends Omit<MultiSelectProps, 'data'> {
  excludeSensorInstanceIds?: number[]
  label?: string
  placeholder?: string
  value?: Value
  withAlarmConfig?: boolean
  onChange: (value: Value, raws?: SensorInstance[]) => void
  setSelectedSensorNames?: (labels: Labels) => void
  required?: boolean
  searchable?: boolean
  disabled?: boolean
  error?: ReactNode
}

const sensorToSelect = ({ sensorInstanceId, name, agentName }: SensorInstance): SelectItem => ({
  group: agentName,
  label: `${name} (${agentName})`,
  value: sensorInstanceId.toString()
})

export default function MultiSelectAgentSensor ({
  excludeSensorInstanceIds,
  label,
  placeholder,
  withAlarmConfig = false,
  onChange,
  setSelectedSensorNames,
  required = false,
  searchable = false,
  disabled = false,
  error,
  ...props
}: Props) {
  const { token } = useUser()
  const allSensorInstances = useApi(getSensorInstances)
  const [initialLoad, setInitialLoad] = useState(true)
  const [value, setValue] = useState<Value>(props.value ?? [])

  const rawSelectedAgentsSensors: SensorInstance[] = allSensorInstances.data.mapOrDefault(
    sensorInstances => (sensorInstances.filter(
      ({ sensorInstanceId }) => !excludeSensorInstanceIds?.includes(sensorInstanceId))
    ), [])

  const selectAgentsSensors: SelectItem[] = rawSelectedAgentsSensors.map(sensorToSelect)

  const handleChange = (val: string[]) => {
    setValue(val)
    const selected = rawSelectedAgentsSensors
      .filter(
        ({ sensorInstanceId }) => val.includes(sensorInstanceId.toString())
      )
    onChange(val, selected)
  }

  useEffect(() => {
    if (setSelectedSensorNames) {
      setSelectedSensorNames(
        value.map(val => {
          const sensor = selectAgentsSensors.find(sensor => sensor.value === val)

          return sensor?.label ?? ''
        })
      )
    }
  }, [value, selectAgentsSensors])

  useEffect(() => {
    void allSensorInstances.fetch({ withAlarmConfig }, token)

    if (initialLoad) {
      setInitialLoad(false)
    } else {
      setValue([])
    }
  }, [])

  return (
    <MultiSelect
      label={label}
      placeholder={placeholder ?? 'Choose sensor(s)'}
      value={value}
      data={selectAgentsSensors}
      onChange={handleChange}
      required={required}
      searchable={searchable}
      disabled={disabled || allSensorInstances.loading}
      filter={(value, selected, { group, label }) => {
        const itemGroup = group ?? ''
        const itemLabel = label ?? ''
        const searchKeywords = value.split(' ')
        let matchedSearch = false

        searchKeywords.every(val => {
          if (hasKeyword(itemLabel, val) || hasKeyword(itemGroup, val)) {
            matchedSearch = true
            return false // break the loop if there is a matched search keyword
          }

          return true
        })

        return !selected && matchedSearch
      }}
      clearSearchOnChange={false}
      clearSearchOnBlur
      error={error}
      {...props}
    />
  )
}
