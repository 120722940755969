import { type ReactNode } from 'react'
import { createStyles } from '@mantine/styles'
import Breadcrumbs, { type BreadcrumbsItem } from './Breadcrumbs'

interface StyleProps {
  form?: boolean
  noVerticalPadding?: boolean
  noHorizontalPadding?: boolean
  occupyMaxHeight?: boolean
}
const useStyles = createStyles((
  theme,
  {
    form,
    noVerticalPadding,
    noHorizontalPadding,
    occupyMaxHeight
  }: StyleProps) => ({
  container: {
    width: '100%',
    height: occupyMaxHeight
      ? '100%'
      : 'auto',
    padding: `${noVerticalPadding
      ? '0'
      : '20px'}
      ${noHorizontalPadding
        ? '0'
        : '20px'}`
  },
  children: {
    display: form
      ? 'flex'
      : 'initial',
    width: '100%',
    height: occupyMaxHeight
      ? '100%'
      : 'auto'
  },
  form: {
    justifyContent: 'center'
  }
}))

export interface Props {
  form?: boolean
  breadcrumbs?: BreadcrumbsItem[]
  noVerticalPadding?: boolean
  noHorizontalPadding?: boolean
  occupyMaxHeight?: boolean
  children: ReactNode
}

export default function Page ({
  form,
  breadcrumbs,
  noVerticalPadding,
  noHorizontalPadding,
  occupyMaxHeight,
  children
}: Props) {
  const { classes, cx } = useStyles({ form, noVerticalPadding, noHorizontalPadding, occupyMaxHeight })
  const childrenClasses = cx({
    [classes.children]: true,
    [classes.form]: form
  })

  return (
    <div className={classes.container}>
      {breadcrumbs && (
        <Breadcrumbs items={breadcrumbs} />
      )}
      <div className={childrenClasses}>
        {children}
      </div>
    </div>
  )
}
