/* eslint-disable @typescript-eslint/naming-convention */
import {
  useMantineTheme,
  Card as MantineCard,
  Group,
  SimpleGrid,
  Stack,
  Text,
  ThemeIcon
} from '@mantine/core'
import { IconCalendarPin } from '@tabler/icons-react'
import { getLinkTextColor } from 'src/utils/theme'
import { type SpeedAlert } from 'src/Reports/Viewer/SpeedingReportView'
import dayjs from 'dayjs'
import { uiDateFormatUniversal } from 'src/utils/dates'
import Detail from '../Detail'

interface Props {
  index: number
  data: SpeedAlert
}

export default function Card ({
  index,
  data: {
    id,
    speed_date_time,
    location,
    geo_zone_name,
    speed_limit,
    speed
  }
}: Props) {
  const theme = useMantineTheme()
  const itemSpacing = 16

  return (
    <MantineCard
      key={id}
      padding={itemSpacing}
      radius={4}
      withBorder
    >
      <Stack spacing={itemSpacing}>
        <Group
          ml={-8}
          spacing={0}
          align="center"
          noWrap
        >
          <ThemeIcon
            sx={{
              background: 'transparent'
            }}
            variant="light"
            color="primary"
          >
            <IconCalendarPin size={12} />
          </ThemeIcon>
          <Text
            size={10}
            weight={500}
            {...getLinkTextColor(theme)}
          >
            {dayjs(speed_date_time).format(uiDateFormatUniversal)}
          </Text>
        </Group>
        <Stack spacing={itemSpacing}>
          <Detail label="Event #" value={index + 1} />
          <Detail label="Location" value={location} />
          <Detail label="Geozone" value={geo_zone_name} />
        </Stack>
        <SimpleGrid spacing={itemSpacing} cols={2}>
          <Detail label="Speed Limit" value={speed_limit} />
          <Detail label="Speed Violation" value={speed} />
        </SimpleGrid>
      </Stack>
    </MantineCard>
  )
}
