/* eslint-disable @typescript-eslint/naming-convention */
import React, {
  type Dispatch,
  type SetStateAction,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from 'react'
import { useDisclosure } from '@mantine/hooks'
import { Dialog } from '@mantine/core'
import MapEventsToImage from 'src/Maps/MapEventsToImage'
import ProgressLoader from 'src/Layout/ReportTemplate/ProgressLoader'
import { computeRelativePercentage } from 'src/AssetTemplate/shared'
import { type ParsedSpeedEvent } from '.'

export interface SpeedingMapsBuilderProps {
  open: () => void
}

interface Props {
  data: ParsedSpeedEvent[]
  isBuilding: boolean
  setIsBuilding: Dispatch<SetStateAction<boolean>>
  onSuccess?: (data: ParsedSpeedEvent[]) => void
}

function Builder (
  {
    data,
    isBuilding,
    setIsBuilding,
    onSuccess
  }: Props,
  ref: React.Ref<SpeedingMapsBuilderProps>
) {
  const [isOpen, { close, open }] = useDisclosure(false)
  const [currentItemIndex, setCurrentItemIndex] = useState(0)
  const [currentItem, setCurrentItem] = useState<ParsedSpeedEvent>(data[currentItemIndex])
  const [mappedItems, setMappedItems] = useState<ParsedSpeedEvent[]>([])

  const buildProgress = useMemo(() => (
    computeRelativePercentage(
      currentItemIndex,
      0,
      data.length
    )
  ), [currentItemIndex, data])

  useImperativeHandle(ref, () => ({
    open
  }), [open])

  const handleSuccessMapCapturing = useCallback((map_url: string) => {
    const updatedItem = {
      ...currentItem,
      map_url
    }

    setMappedItems([...mappedItems, updatedItem])
    setCurrentItemIndex(currentItemIndex + 1)
  }, [currentItem, mappedItems, currentItemIndex])

  const handleSuccessBuilding = useCallback((data: ParsedSpeedEvent[]) => {
    close()

    if (onSuccess) {
      onSuccess(data)
    }

    setIsBuilding(false)
  }, [onSuccess])

  useEffect(() => {
    if (currentItemIndex + 1 > data.length) {
      handleSuccessBuilding(mappedItems)
    } else {
      setCurrentItem(data[currentItemIndex])
    }
  }, [currentItemIndex])

  if (!currentItem) {
    close()
    setIsBuilding(false)

    return
  }

  // Uses Dialog component so the user can still navigate through current page
  // while waiting for the report to finish downloading
  return (
    <Dialog
      shadow="xl"
      radius={10}
      opened={isOpen}
      zIndex={400}
      withCloseButton={false}
      withBorder
    >
      {isBuilding && (
        <ProgressLoader
          value={buildProgress}
          label="Building report..."
        />
      )}
      <MapEventsToImage
        eventType="speeding"
        tripId={Number(currentItem.trip_id)}
        startTime={currentItem.trip_start_time}
        onSuccess={handleSuccessMapCapturing}
      />
    </Dialog>
  )
}

const SpeedingMapsBuilder = forwardRef(Builder)

export default SpeedingMapsBuilder
