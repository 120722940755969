import React from 'react'
import { Handle, useConnection, type HandleProps } from '@xyflow/react'
import { createStyles } from '@mantine/core'
import { SELECTED_COLOR } from '../../shared'
import { useViewControls } from '../../Nodes/NodeContext'

interface StyleProps {
  isHovered?: boolean
  isPotentialTarget?: boolean
  hasConnection?: boolean
}

const useStyles = createStyles((theme, { isHovered, hasConnection, isPotentialTarget }: StyleProps) => ({
  handle: {
    opacity: isHovered
      ? 1
      : isPotentialTarget
        ? 0.65
        : hasConnection
          ? 1
          : 0,
    position: 'relative',
    '&:hover': {
      opacity: 1,
      backgroundColor: `${theme.fn.rgba(theme.colors.gray[4], 0.65)} !important`
    },
    '&.react-flow__handle-top': {
      top: -3
    },
    '&.react-flow__handle-left': {
      left: -3
    },
    '&.react-flow__handle-bottom': {
      bottom: -3
    },
    '&.react-flow__handle-right': {
      right: -3
    },
    '&::after': {
      content: "''",
      width: 12,
      height: 12,
      backgroundColor: theme.colorScheme === 'light'
        ? '#fff'
        : SELECTED_COLOR,
      border: 'solid 1px gray',
      borderRadius: 6,
      position: 'absolute',
      top: 3,
      left: 3
    }
  }
}))

interface Props extends Omit<HandleProps, 'type'> {
  nodeId: string
  hovered: boolean
}

const handleStyle = {
  width: 18,
  height: 18,
  border: 'unset',
  backgroundColor: 'transparent'
}

function CustomHandle ({ nodeId, hovered, ...rest }: Props) {
  const { viewOnly } = useViewControls()
  const { inProgress, fromNode } = useConnection()
  const isTarget = inProgress && fromNode?.id !== nodeId
  const { classes } = useStyles({ isHovered: hovered, isPotentialTarget: isTarget })

  return (
    <>
      {!inProgress && (
        <Handle
          isConnectable={!viewOnly}
          className={classes.handle}
          type="source"
          style={handleStyle}
          {...rest}
        />
      )}
      {(!inProgress || isTarget) && (
        <Handle
          isConnectable={!viewOnly}
          className={classes.handle}
          type="target"
          isConnectableStart={false}
          style={handleStyle}
          {...rest}
        />
      )}
    </>
  )
}

export default CustomHandle
