/* eslint-disable @typescript-eslint/naming-convention */
import {
  useMantineTheme,
  Card as MantineCard,
  Center,
  Group,
  SimpleGrid,
  Stack,
  Text,
  ThemeIcon
} from '@mantine/core'
import { IconCalendarTime } from '@tabler/icons-react'
import { getLinkTextColor } from 'src/utils/theme'
import { type AgentTripItem } from 'src/Reports/Viewer/TripReportView'
import ViewTripButton from 'src/Reports/Viewer/ViewTripButton'
import dayjs from 'dayjs'
import { uiDateFormat } from 'src/utils/dates'
import Detail from '../Detail'

interface Props {
  data: AgentTripItem
}

export default function Card ({
  data: {
    agent_id,
    trip_id,
    start_time,
    end_time,
    driver,
    start_location,
    end_location,
    driving_duration,
    idling_duration,
    distance_travelled,
    speed_avg,
    speed_max,
    x_acceleration_max,
    y_acceleration_max,
    z_acceleration_max
  }
}: Props) {
  const theme = useMantineTheme()
  const itemSpacing = 16

  return (
    <MantineCard
      key={trip_id}
      padding={itemSpacing}
      radius={4}
      withBorder
    >
      <Stack spacing={itemSpacing}>
        <Group
          ml={-8}
          spacing={0}
          align="center"
          noWrap
        >
          <ThemeIcon
            sx={{
              background: 'transparent'
            }}
            variant="light"
            color="primary"
          >
            <IconCalendarTime size={12} />
          </ThemeIcon>
          <Text
            size={10}
            weight={500}
            {...getLinkTextColor(theme)}
          >
            {dayjs(start_time).format(uiDateFormat)}
            {' to '}
            {dayjs(end_time).format(uiDateFormat)}
          </Text>
        </Group>
        <Stack spacing={itemSpacing}>
          <Detail label="Start Location" value={start_location} />
          <Detail label="End Location" value={end_location} />
        </Stack>
        <SimpleGrid spacing={itemSpacing} cols={2}>
          <Detail label="Driver" value={driver} />
          <Detail label="KMs Travelled" value={distance_travelled} />
          <Detail label="Driving Duration" value={driving_duration} />
          <Detail label="Idling Duration" value={idling_duration} />
          <Detail label="Average Speed (Kmh)" value={speed_avg} />
          <Detail label="Max Speed (Kmh)" value={speed_max} />
          <Detail label="Max Accel (X)" value={x_acceleration_max} />
          <Detail label="Max Accel (Y)" value={y_acceleration_max} />
          <Detail label="Max Accel (Z)" value={z_acceleration_max} />
        </SimpleGrid>
        <Center mt={8} mb={4}>
          <ViewTripButton
            agentId={agent_id}
            tripId={trip_id}
            startTime={start_time}
          />
        </Center>
      </Stack>
    </MantineCard>
  )
}
