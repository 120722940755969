/* eslint-disable @typescript-eslint/naming-convention */
import { View } from '@react-pdf/renderer'
import THCell from 'src/Layout/ReportTemplate/Components/THCell'
import TDCell from 'src/Layout/ReportTemplate/Components/TDCell'
import { reportStyles } from 'src/Layout/ReportTemplate/styles'
import { type ParsedTripItem } from 'src/Reports/Viewer/TripReportView'
import ChildTable from './ChildTable'

interface Props {
  index: number
  data: ParsedTripItem
  withSummary?: boolean
}

export default function Table ({
  index,
  data: {
    agent_name,
    alarm_status,
    total_trips,
    total_duration,
    total_distance_in_kilometres,
    trips
  },
  withSummary = false
}: Props) {
  const breakStartNumber = withSummary
    ? 0
    : 1
  const withBreak = index >= breakStartNumber

  return (
    <View break={withBreak}>
      <View>
        <View style={reportStyles.group}>
          <THCell value="Asset" />
          <THCell value="Alarm Status" />
          <THCell value="# Trips" />
          <THCell value="Total Duration" />
          <THCell value="Total Distance KMs" />
        </View>
        <View style={reportStyles.group}>
          <TDCell value={agent_name} />
          <TDCell value={alarm_status} />
          <TDCell value={total_trips} />
          <TDCell value={total_duration} />
          <TDCell value={total_distance_in_kilometres} />
        </View>
      </View>
      <ChildTable items={trips} />
    </View>
  )
}
