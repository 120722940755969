import { useMantineTheme, Paper, Table as MantineTable } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { getWhiteBackgroundColor } from 'src/utils/theme'
import { type Props as ListProps } from '..'
import Row from './Row'

export default function Table ({ items }: ListProps) {
  const theme = useMantineTheme()
  const { ref, width } = useElementSize()

  return (
    <Paper
      ref={ref}
      p="sm"
      shadow="xs"
      sx={{
        width: '100%',
        scrollbarWidth: 'thin'
      }}
    >
      <MantineTable
        sx={{
          ...getWhiteBackgroundColor(theme),
          position: 'relative'
        }}
      >
        <thead
          style={{
            ...getWhiteBackgroundColor(theme),
            position: 'sticky',
            top: 0,
            zIndex: 100
          }}
        >
          <tr
            style={{
              borderBottom: 1
            }}
          >
            <th>
              Assets
            </th>
            <th
              style={{
                width: 200
              }}
            >
              Date
            </th>
            <th
              style={{
                width: 150
              }}
            >
              Violations
            </th>
            <th
              style={{
                width: 200
              }}
            >
              Driver
            </th>
            <th
              style={{
                width: 200
              }}
            />
          </tr>
        </thead>
        <tbody>
          {items.map((data) => (
            <Row
              key={data.trip_id}
              item={data}
              containerWidth={width}
            />
          ))}
        </tbody>
      </MantineTable>
    </Paper>
  )
}
