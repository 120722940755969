/* eslint-disable @typescript-eslint/naming-convention */
import { type Report } from '@venturi-io/api/src/config/report'
import { type Range } from '@venturi-io/api'
import ReportTemplate from 'src/Layout/ReportTemplate'
import { reportStyles } from 'src/Layout/ReportTemplate/styles'
import Space from 'src/Layout/ReportTemplate/Components/Space'
import { type SiteTankLevels } from '../../TankLevelReportView'
import Details from './Details'
import Item from './Item'

interface Props {
  report: Report
  data: SiteTankLevels[]
  range: Range | null
}

export default function TankLevelHistory ({ report, data, range }: Props) {
  return (
    <ReportTemplate
      title={report.name}
      page={{
        size: 'A4',
        orientation: 'landscape',
        style: reportStyles.page,
        wrap: true
      }}
    >
      <Details report={report} range={range} />
      <Space />
      {data.map((item, index) => (
        <Item key={item.site_id} index={index} data={item} />
      ))}
    </ReportTemplate>
  )
}
