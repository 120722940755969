import { useCallback } from 'react'
import Reactour, { type ReactourStep } from 'reactour'
import { useMantineTheme, ActionIcon, Button, Tooltip } from '@mantine/core'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { IconProgressX } from '@tabler/icons-react'

export interface Props {
  steps: ReactourStep[]
  isOpen: boolean
  setIsOpen: (status: boolean) => void
  setIsDone?: (status: boolean) => void
  onClickLastStepNextButton: () => void
  onClose?: () => void
}

export default function Tour ({
  steps,
  isOpen,
  setIsOpen,
  onClickLastStepNextButton,
  onClose
}: Props) {
  const theme = useMantineTheme()

  const onRequestClose = useCallback(() => {
    if (onClose) {
      onClose()
    }

    setIsOpen(false)
  }, [])

  const NextTourButton = (
    <Button onClick={onClickLastStepNextButton} color="primary">
      Next
    </Button>
  )

  return (
    <Reactour
      steps={steps}
      accentColor={theme.colors.primary[6]}
      rounded={10}
      isOpen={isOpen}
      onAfterOpen={disableBodyScroll}
      onBeforeClose={enableBodyScroll}
      onRequestClose={onRequestClose}
      lastStepNextButton={NextTourButton}
      closeWithMask={false}
      showCloseButton={false}
      disableInteraction
    >
      <Tooltip
        label="End Tutorial"
        position="left"
        transitionProps={{
          transition: 'fade',
          duration: 200
        }}
        withArrow
      >
        <ActionIcon
          style={{
            position: 'absolute',
            top: 8,
            right: 8
          }}
          size={36}
          onClick={onRequestClose}
        >
          <IconProgressX size={24} />
        </ActionIcon>
      </Tooltip>
    </Reactour>
  )
}
