import { type Report } from '@venturi-io/api/src/config/report'
import { type Range } from '@venturi-io/api'
import ReportTemplate from 'src/Layout/ReportTemplate'
import Space from 'src/Layout/ReportTemplate/Components/Space'
import { reportStyles } from 'src/Layout/ReportTemplate/styles'
import { type ParsedSpeedEvent } from '../../SpeedingReportView'
import Details from './Details'
import SummaryTable from './SummaryTable'
import Table from './Table'

interface Props {
  report: Report
  data: ParsedSpeedEvent[]
  range: Range | null
}

function SpeedingEvents ({ report, data, range }: Props) {
  const withSummary = data.length > 1

  return (
    <ReportTemplate
      title={report.name}
      page={{
        size: 'A4',
        orientation: 'landscape',
        style: reportStyles.page,
        wrap: true
      }}
    >
      <Details report={report} range={range} />
      <Space />
      {withSummary && <SummaryTable data={data} />}
      {data.map((item, index) => (
        <Table
          key={item.agent_id}
          index={index}
          data={item}
          withSummary={withSummary}
        />
      ))}
    </ReportTemplate>
  )
}

export default SpeedingEvents
