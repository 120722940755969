import React from 'react'
import { type NodeResizerProps, NodeResizer as Resizer, useKeyPress } from '@xyflow/react'
import { SELECTED_COLOR } from '../shared'

function NodeResizer (props: NodeResizerProps) {
  const shiftKeyPressed = useKeyPress('Shift')

  return (
    <Resizer
      keepAspectRatio={shiftKeyPressed}
      color={SELECTED_COLOR}
      handleStyle={{
        width: '12px',
        height: '12px',
        zIndex: 10
      }}
      {...props}
    />
  )
}

export default NodeResizer
