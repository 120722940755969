import React, { memo, useEffect, useMemo } from 'react'
import {
  type Node,
  type NodeProps,
  useHandleConnections,
  useNodesData,
  useReactFlow
} from '@xyflow/react'
import { Group, Text } from '@mantine/core'
import { IconCpu, IconPlugConnectedX } from '@tabler/icons-react'
import { findSensorById } from 'src/Assets/shared'
import valueMap from 'src/utils/valueMap'
import { checkIfAlarm } from 'src/utils/status'
import { type SensorInstance } from '@venturi-io/api/src/config/ui'
import {
  type AgentDetails,
  type AssetType,
  getAgentDetails
} from '@venturi-io/api/src/config/agent'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StatusBadge from '../Components/StatusBadge'
import NodeLinkButton from '../Components/NodeLinkButton'
import BaseNode from './BaseNode'
import { type AgentNodeType } from './AgentNode'

export interface Sensor extends Partial<SensorInstance>, Record<string, string | number | AgentDetails | undefined> {
  details?: AgentDetails
}

export type SensorNodeType = Node<Sensor, 'sensorNode'>

function SensorNode (props: NodeProps<SensorNodeType>) {
  const { token } = useUser()
  const { updateNode } = useReactFlow()
  const agent = useApi(getAgentDetails)

  const { data, id } = props
  const {
    sensorName,
    sensorId,
    agentId,
    details
  } = data

  const leftConnections = useHandleConnections({ nodeId: id, id: 'left', type: 'target' })
  const rightConnections = useHandleConnections({ nodeId: id, id: 'right', type: 'target' })
  const topConnections = useHandleConnections({ nodeId: id, id: 'top', type: 'target' })
  const bottomConnections = useHandleConnections({ nodeId: id, id: 'bottom', type: 'target' })
  const connections = [
    ...topConnections,
    ...leftConnections,
    ...bottomConnections,
    ...rightConnections
  ]
  const parentNode = useNodesData<AgentNodeType>(connections[0]?.source)

  const isIndependent = typeof agentId !== 'undefined'

  const sensorData = useMemo(() => {
    // From parent node (Agent node reliant code)
    if (parentNode?.data?.details && !isIndependent) {
      const { data: { details: parentDetails } } = parentNode
      return findSensorById(Number(sensorId), parentDetails.sensors)
    }

    // independent node (created by SensorPickerDialog)
    if (isIndependent && details) {
      return findSensorById(Number(sensorId), details.sensors)
    }

    return null
  }, [parentNode, sensorId, details, isIndependent])

  const sensorValue = useMemo(() => {
    if (sensorData) {
      const { currentValue, unit, valueMap: vm } = sensorData
      return currentValue
        ? `${valueMap(vm ?? {}, currentValue ?? '')}${unit ? ` ${unit}` : ''}`
        : '-'
    }

    return null
  }, [sensorData])

  const isAlarm = useMemo(
    () => sensorData?.alarmStatus
      ? checkIfAlarm(sensorData.alarmStatus)
      : false,
    [sensorData]
  )

  const hasMultipleConnections = connections.length > 1 && typeof agentId === 'undefined'

  useEffect(() => {
    if (agentId) {
      void agent.fetch({ agentId: Number(agentId) }, token)
    }
  }, [agentId])

  useEffect(() => {
    agent.data.ifJust((data) => {
      updateNode(props.id, node => ({
        data: {
          ...node.data,
          details: data
        }
      }))
    })
  }, [agent.data])

  const link = useMemo(
    () => {
      const correctDetails = isIndependent
        ? details
        : parentNode
          ? parentNode.data.details
          : undefined

      const type = correctDetails?.assetType as AssetType

      let url = '/assets'
      switch (type) {
        case 'VEHICLE':
          url += '/vehicle'
          break
        case 'GENSET':
          url += '/genset'
          break
        case 'ATS':
          url += '/ats'
          break
        case 'TANK':
          url += '/tank'
          break
        case 'DURESS':
          url += '/duress'
          break
        case 'UPS':
          url += '/ups'
          break
        case 'POWER METER':
        case '3P-POWER':
          url += '/power-meter'
          break
        case 'OTHERS':
        default:
          url += '/generic'
          break
      }
      return `${url}/${correctDetails?.agentId}`
    },
    [details, agentId]
  )

  return (
    <BaseNode isLoading={agent.loading} {...props}>
      <Group spacing="xs">
        {isIndependent
          ? (
            <>
              <IconCpu size={16} />
              <Text size="xs">{sensorName}</Text>
              <StatusBadge
                isAlarm={isAlarm}
                label={`${sensorValue}`}
              />
              <NodeLinkButton
                to={link}
                identifier="Sensor"
                icon={<FontAwesomeIcon icon={['far', 'memo-circle-info']} size="2xs" />}
              />
            </>
            )
          : (
            <>
              {connections.length
                ? <IconCpu size={16} />
                : <IconPlugConnectedX size={16} color="red" />}
              <Text
                color={sensorName
                  ? undefined
                  : 'dimmed'}
                size="xs"
              >
                {!sensorName
                  ? 'No sensor selected yet'
                  : !parentNode
                      ? 'No agent connected'
                      : sensorName}
              </Text>
              {!hasMultipleConnections && sensorData
                ? (
                  <StatusBadge
                    isAlarm={isAlarm}
                    label={`${sensorValue}`}
                  />
                  )
                : (
                  <Text size={8} color="dimmed">
                    {hasMultipleConnections
                      ? `${connections.length} connections`
                      : 'No sensor data'}
                  </Text>
                  )}
              <NodeLinkButton
                to={link}
                identifier="Sensor"
                icon={<FontAwesomeIcon icon={['far', 'memo-circle-info']} size="2xs" />}
              />
            </>
            )}
      </Group>
    </BaseNode>
  )
}

export default memo(SensorNode)
