import z from 'zod'
import { createStatefulApi } from '../configure'
import { type Req } from '../shared'

export interface FileIdRequest extends Req {
  fileId: number
}

export const deleteFile = createStatefulApi<FileIdRequest, z.ZodBoolean>(
  'config',
  '/files/:fileId',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)
