/* eslint-disable @typescript-eslint/naming-convention */
import { useMantineTheme, Box, Divider } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { mq } from 'src/utils/style'
import EmptyPlaceholder from 'src/Reports/Viewer/EmptyPlaceholder'
import { type ParsedTripItem } from '../..'
import Table from './Table'
import Cards from './Cards'

export interface ItemProps {
  item: ParsedTripItem
}

export interface Props {
  items: ParsedTripItem[]
  isLoading?: boolean
}

export default function List ({ items, isLoading = false }: Props) {
  const theme = useMantineTheme()
  const isDesktop = useMediaQuery(mq(theme.breakpoints.sm, false))

  return (
    <Box>
      {isDesktop
        ? <Table items={items} />
        : <Cards items={items} />}
      {!isLoading && items.length === 0 && <EmptyPlaceholder />}
      {isDesktop && <Divider mt={0} mb={4} variant="solid" />}
    </Box>
  )
}
