import React from 'react'
import { Divider, Paper, Stack } from '@mantine/core'
import {
  IconClipboard,
  IconCopy,
  IconCut,
  IconStackPop,
  IconStackPush,
  IconTrash
} from '@tabler/icons-react'
import useCopyPaste from 'src/Admin/SiteDetail/hooks/useCopyPaste'
import { useReactFlow, type Node } from '@xyflow/react'
import useNodeLayerControl from 'src/Admin/SiteDetail/hooks/useNodeLayerControl'
import ItemButton from './ItemButton'

export interface MenuPosition {
  top?: number
  left?: number
  right?: number
  bottom?: number
}

export interface ContextMenuProps {
  nodes: Node[]
  setNodes: (nodes: Node[]) => void
  position: MenuPosition | null
}

function ContextMenu ({
  position,
  nodes,
  setNodes
}: ContextMenuProps) {
  const {
    copy,
    cut,
    paste,
    bufferedNodes
  } = useCopyPaste({ noKeyboardShortcut: true })

  const {
    onSendToBack,
    onBringToFront
  } = useNodeLayerControl({ nodes, setNodes })

  const { deleteElements } = useReactFlow()

  const canPaste = bufferedNodes.length > 0
  const hasSelected = nodes.some(({ selected, type }) => selected && type !== 'backgroundNode')
  const canCopy = hasSelected

  const show = position !== null &&
    (
      hasSelected ||
      canPaste
    )

  const onDelete = () => {
    const selected = nodes
      .filter(({ selected }) => selected)
      .map(({ id }) => ({ id }))
    void deleteElements({
      nodes: selected
    })
  }

  return show && (
    <Paper
      p="xs"
      shadow="xl"
      radius="md"
      style={{
        position: 'absolute',
        zIndex: 10,
        ...position
      }}
      className="context-menu"
    >
      <Stack spacing={0} justify="stretch">
        <ItemButton
          Icon={IconCopy}
          label="Copy"
          disabled={!canCopy}
          onClick={copy}
        />
        <ItemButton
          Icon={IconCut}
          label="Cut"
          disabled={!canCopy}
          onClick={cut}
        />
        <ItemButton
          Icon={IconClipboard}
          label="Paste"
          disabled={!canPaste}
          onClick={paste}
        />
        <ItemButton
          color="red"
          Icon={IconTrash}
          label="Delete"
          disabled={!canCopy}
          onClick={onDelete}
        />
        {canCopy && <Divider />}
        <ItemButton
          Icon={IconStackPop}
          label="Bring to Front"
          disabled={!canCopy}
          onClick={onBringToFront}
        />
        <ItemButton
          Icon={IconStackPush}
          label="Send to Back"
          disabled={!canCopy}
          onClick={onSendToBack}
        />
      </Stack>
    </Paper>
  )
}

export default ContextMenu
