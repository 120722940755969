import React, { memo, useEffect, useMemo, useState } from 'react'
import { useReactFlow, type Node, type NodeProps } from '@xyflow/react'
import { Group, Text } from '@mantine/core'
import { useApi } from 'src/utils/useApi'
import {
  type AgentDetails,
  type AssetType,
  getAgentDetails
} from '@venturi-io/api/src/config/agent'
import { useUser } from 'src/UserContext'
import { checkIfAlarm } from 'src/utils/status'
import { IconBox } from '@tabler/icons-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StatusBadge from '../Components/StatusBadge'
import NodeLinkButton from '../Components/NodeLinkButton'
import BaseNode from './BaseNode'

interface Agent extends Record<string, string | AgentDetails | undefined> {
  agentId?: string
  agentName?: string
  details?: AgentDetails
}

export type AgentNodeType = Node<Agent, 'agentNode'>

function AgentNode (props: NodeProps<AgentNodeType>) {
  const {
    agentId,
    agentName
  } = props.data

  const { token } = useUser()
  const { updateNode } = useReactFlow()
  const agent = useApi(getAgentDetails)
  const [details, setDetails] = useState<AgentDetails>()

  useEffect(() => {
    if (agentId) {
      void agent.fetch({ agentId: Number(agentId) }, token)
    }
  }, [agentId])

  useEffect(() => {
    agent.data.ifJust((data) => {
      updateNode(props.id, node => ({
        data: {
          ...node.data,
          details: data
        }
      }))
      setDetails(data)
    })
  }, [agent.data])

  const isAlarm = useMemo(
    () => details
      ? checkIfAlarm(details.alarmStatus)
      : false,
    [details]
  )

  const link = useMemo(
    () => {
      const type = details?.assetType as AssetType
      let url = '/assets'
      switch (type) {
        case 'VEHICLE':
          url += '/vehicle'
          break
        case 'GENSET':
          url += '/genset'
          break
        case 'ATS':
          url += '/ats'
          break
        case 'TANK':
          url += '/tank'
          break
        case 'DURESS':
          url += '/duress'
          break
        case 'UPS':
          url += '/ups'
          break
        case 'POWER METER':
        case '3P-POWER':
          url += '/power-meter'
          break
        case 'OTHERS':
        default:
          url += '/generic'
          break
      }
      return `${url}/${agentId}`
    },
    [details, agentId]
  )

  return (
    <BaseNode
      isLoading={agent.loading}
      {...props}
    >
      <Group bg="inherit" spacing="xs" pos="relative">
        <IconBox size={16} />
        <Text
          color={agentName
            ? undefined
            : 'dimmed'}
          size="xs"
        >
          {agentName ?? 'No agent assigned yet'}
        </Text>
        {details && (
          <StatusBadge
            isLoading={agent.loading}
            isAlarm={isAlarm}
            label={isAlarm
              ? 'ALARM'
              : 'Normal'}
          />
        )}

        <NodeLinkButton
          to={link}
          identifier="Sensor"
          icon={<FontAwesomeIcon icon={['far', 'memo-circle-info']} size="2xs" />}
        />
      </Group>
    </BaseNode>
  )
}

export default memo(AgentNode)
