import { View } from '@react-pdf/renderer'
import { type Report } from '@venturi-io/api/src/config/report'
import { type Range } from '@venturi-io/api'
import { reportStyles } from 'src/Layout/ReportTemplate/styles'
import LabelValue from 'src/Layout/ReportTemplate/Components/LabelValue'
import { formatToReadableDate } from 'src/utils/dates'

interface Props {
  report: Report
  range: Range | null
}
export default function Details ({ report, range }: Props) {
  return (
    <View style={reportStyles.details}>
      <LabelValue label="Report type" value={report.reportType.name} />
      <LabelValue
        label="Period Range"
        value={range
          ? `${formatToReadableDate(range.from)} - ${formatToReadableDate(range.to)}`
          : '--'}
      />
    </View>
  )
}
