import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  createStyles,
  Button,
  Group,
  Stack,
  Text
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getAgentGroup, type AgentGroup } from '@venturi-io/api/src/config/agentGroup'
import { getAgentsForAgentGroup } from '@venturi-io/api/src/config/agent'
import { useApi, usePaginatedApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { mq } from 'src/utils/style'
import Paper from 'src/Layout/Paper'
import ActionList from 'src/Layout/ActionList'
import NotFound from 'src/Router/NotFound'
import Nothing from 'src/Nothing'
import Form from '../Form'
import { Header, Row, Card } from './AgentList'
import AgentAssignment from './AgentAssignment'

interface RouteParams extends Record<string, string | undefined> {
  agentGroupId: string
}

const useStyles = createStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [mq(theme.breakpoints.xl)]: {
      maxWidth: theme.breakpoints.xl
    }
  },
  paper: {
    flex: 1,
    [mq(theme.breakpoints.xl)]: {
      maxWidth: theme.breakpoints.xl
    }
  }
}))

export default function Edit () {
  const { token } = useUser()
  const agentGroup = useApi(getAgentGroup)
  const agents = usePaginatedApi(getAgentsForAgentGroup)
  const { classes } = useStyles()
  const { agentGroupId: id } = useParams<RouteParams>()
  const [formInitialValues, setFormInitialValues] = useState<AgentGroup>()
  const [showAddAgent, setShowAddAgent] = useState(false)

  if (typeof id === 'undefined' || isNaN(parseInt(id))) {
    return <NotFound />
  }

  const agentGroupId = parseInt(id)

  const loadAgentGroup = () => {
    void agentGroup.fetch({ agentGroupId }, token)
  }

  const loadAgents = async (page?: number, size?: number): Promise<void> => {
    void agents
      .fetch({
        agentGroupId,
        page: page ?? agents.page,
        size: size ?? agents.size
      }, token)
  }

  const actions = {
    loadAgents: async () => await loadAgents(agents.page)
  }

  useEffect(() => {
    loadAgentGroup()
  }, [])

  useEffect(() => {
    agentGroup.data.ifJust(setFormInitialValues)
  }, [agentGroup.data])

  useEffect(() => {
    loadAgentGroup()
    void loadAgents()
  }, [agents.page])

  const hasAgent = agents.data.mapOrDefault(({ items }) => (
    items.length > 0
  ), false)

  return (
    <>
      <div className={classes.container}>
        <Paper className={classes.container} relative>
          {agentGroup.data.caseOf({
            Nothing: () => (
              <Nothing
                isLoading={agentGroup.loading}
                nothing={agentGroup.data.isNothing()}
              />
            ),
            Just: () => <Form mode="EDIT" initialValues={formInitialValues} hasAgent={hasAgent} />
          })}
        </Paper>
        <Stack mt="lg" spacing={0}>
          <Group position="apart" align="start">
            <Text size="lg" weight="bold">Agents</Text>
            <Button
              disabled={showAddAgent}
              title="Add Agent to this group"
              color="primary"
              leftIcon={<FontAwesomeIcon icon={['fas', 'plus']} color="white" />}
              onClick={() => setShowAddAgent(true)}
            >
              Add Agent
            </Button>
          </Group>
          <ActionList
            isLoading={agents.loading}
            data={agents.data.mapOrDefault(({ items }) => items, [])}
            actions={actions}
            extra={{ agentGroupId }}
            getId={({ agentId }) => agentId}
            header={() => <Header />}
            row={Row}
            card={Card}
            totalPages={agents.data.mapOrDefault(({ totalPages }) => totalPages, 0)}
            page={agents.page}
            onPageChange={agents.setPage}
          />
        </Stack>
      </div>
      <AgentAssignment
        agentGroupId={agentGroupId}
        show={showAddAgent}
        onClose={() => setShowAddAgent(false)}
        onSave={loadAgents}
      />
    </>
  )
}
