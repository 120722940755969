/* eslint-disable @typescript-eslint/no-var-requires */
import {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback
} from 'react'
import mapboxgl, { type LngLatLike } from 'mapbox-gl'
import { type BBox } from '@turf/turf'
import { Box } from '@mantine/core'
import html2canvas from 'html2canvas'
import ReactMapGL, { Layer, Source, type LayerProps, type MapRef } from 'react-map-gl'
import { getTripDetails, type TripDetails } from '@venturi-io/api/src/collector/trip'
import { getIncidentsSummary, type IncidentsSummary } from '@venturi-io/api/src/config/alarmHistory'
import { useApi, usePaginatedApi } from 'src/utils/useApi'
import { useNotifications } from 'src/utils/notifications'
import { useUser } from 'src/UserContext'
import { MapType } from './StylePicker'
import {
  generateDuressPoints,
  generateIdlingPoints,
  generateLinePath,
  generateRawLinePath,
  generateSpeedingPoints,
  generateTripPoints,
  randomHexColor
} from './GeoZoneMap/mapbox'
import IdleMarker from './GeoZoneMap/Trips/Layer/IdleMarker'
import { generateSpeedingDot } from './GeoZoneMap/Trips/Layer/speedingDot'

type EventType =
  'idle' |
  'speeding' |
  'duress' |
  'all'

interface Props {
  eventType?: EventType
  tripId: number
  startTime?: string | null
  endTime?: string
  sensorInstanceId?: number
  onSuccess?: (mapUrl: string) => void
}

interface ZoomBounds {
  zoom?: number
  idleBounds?: BBox
}

const speeding: LayerProps = {
  id: 'speeding-arrow-points',
  type: 'symbol',
  layout: {
    'icon-image': 'arrow-indicator',
    'icon-size': 1,
    'icon-rotate': ['get', 'direction'],
    'icon-allow-overlap': true
  }
}

const lineBorder: LayerProps = {
  type: 'line',
  paint: {
    'line-color': 'gray',
    'line-opacity': 1,
    'line-gap-width': 9,
    'line-width': 1
  },
  layout: {
    'line-cap': 'round',
    'line-join': 'round'
  }
}

const speedingLayer: LayerProps = {
  id: 'speeding-points',
  type: 'symbol',
  layout: {
    'icon-image': 'speeding-dot',
    'icon-allow-overlap': true
  }
}

const duressPinLayer: LayerProps = {
  id: 'duressFence',
  type: 'circle',
  paint: {
    'circle-color': ['get', 'color'],
    'circle-opacity': 1,
    'circle-radius': 14,
    'circle-stroke-color': '#fff',
    'circle-stroke-opacity': 1,
    'circle-stroke-width': 2
  }
}

const duressTextLayer: LayerProps = {
  id: 'duressText',
  type: 'symbol',
  layout: {
    'text-field': ['get', 'position'],
    'text-font': ['Arial Unicode MS Bold'],
    'text-size': 12,
    'text-allow-overlap': true
  },
  paint: {
    'text-color': 'white'
  }
}

const mapSize = {
  width: 1280,
  height: 720
}

export default function MapEventsToImage ({
  eventType,
  tripId,
  startTime,
  endTime,
  sensorInstanceId,
  onSuccess
}: Props) {
  const { token } = useUser()
  const { showError } = useNotifications()
  const mapRef = useRef<MapRef>(null)
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null)
  const tripDetails = useApi(getTripDetails)
  const duressSummary = usePaginatedApi(getIncidentsSummary)
  const [trip, setTrip] = useState<TripDetails>()
  const [incidentSummary, setIncidentSummary] = useState<IncidentsSummary>() // for duress only
  const [{ zoom, idleBounds }, setZoomBounds] = useState<ZoomBounds>({})

  const lineLayer: LayerProps = {
    id: 'main-line',
    type: 'line',
    paint: {
      'line-gradient': [
        'interpolate',
        ['linear'],
        ['line-progress'],
        0,
        'lightGray',
        1,
        trip?.color ?? 'red'
      ],
      'line-color': ['get', 'color'],
      'line-opacity': 1,
      'line-width': 9
    },
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    }
  }

  // trip points and appearance config
  const rawTripSource = useMemo(() => (
    trip
      ? generateTripPoints(trip.rawPath, trip.tripId, trip.color)
      : null
  ), [trip])

  const lineMapboxPathSource = useMemo(() => (
    trip?.inProgress
      ? generateRawLinePath(trip.rawPath, trip.color)
      : trip
        ? generateLinePath(trip.mapboxPath, trip.color)
        : null
  ), [trip])

  const speedingSource = useMemo(() => (
    trip
      ? generateSpeedingPoints(trip.rawPath, trip.tripId, trip.color)
      : null
  ), [trip])

  const idlingSource = useMemo(() => (
    trip
      ? generateIdlingPoints(trip.rawPath, trip.tripId)
      : null
  ), [trip])

  const duressSource = useMemo(() => (
    incidentSummary
      ? generateDuressPoints(incidentSummary)
      : null
  ), [incidentSummary])

  const bounds = useMemo(() => {
    if (trip) {
      const coordinates: LngLatLike[] = trip.rawPath.map(({ longitude, latitude }) => ({
        lng: longitude,
        lat: latitude
      }))

      // Create a 'LngLatBounds' with both corners at the first coordinate
      const bounds = new mapboxgl.LngLatBounds(
        coordinates[0],
        coordinates[0]
      )

      // Extend the 'LngLatBounds' to include every coordinate in the bounds result
      for (const coord of coordinates) {
        bounds.extend(coord)
      }

      return bounds
    }

    return null
  }, [trip])

  const hasData = useMemo(() => (
    lineMapboxPathSource &&
    rawTripSource &&
    idlingSource &&
    speedingSource
  ), [
    lineMapboxPathSource,
    rawTripSource,
    idlingSource,
    speedingSource
  ])

  const captureImage = useCallback(async () => {
    if (containerRef) {
      const canvas = await html2canvas(containerRef)
      const mapUrl = canvas.toDataURL('image/png', 1.0)
      if (onSuccess) {
        onSuccess(mapUrl ?? 'N/A')
      }
    }
  }, [containerRef, onSuccess])

  const renderAndCaptureMapEvents = useCallback((delayInMs: number) => {
    if (
      mapRef.current &&
      trip &&
      bounds &&
      onSuccess
    ) {
      const map = mapRef.current

      map.fitBounds(bounds, {
        duration: 0,
        padding: {
          top: 80,
          bottom: 80,
          left: 160,
          right: 160
        }
      })

      setZoomBounds({
        idleBounds: map.getBounds()
          .toArray()
          .flat() as never as BBox,
        zoom: map.getZoom()
      })

      setTimeout(() => {
        void captureImage()
      }, delayInMs)
    }
  }, [mapRef.current, trip, bounds])

  const onLoad = useCallback(() => {
    if (mapRef.current) {
      const map = mapRef.current.getMap()

      // Load map's resources
      map.loadImage(
        require('./assets/idle-indicator.png'),
        (error, image) => {
          if (image && !map.hasImage('idle-indicator')) {
            map.addImage('idle-indicator', image)
          }
          if (error) {
            showError(new Error(`Failed to load idle indicator: ${error.message}`))
          }
        }
      )

      map.loadImage(
        require('./assets/arrow-indicator.png'),
        (error, image) => {
          if (image && !map.hasImage('arrow-indicator')) {
            map.addImage('arrow-indicator', image)
          }
          if (error) {
            showError(new Error(`Failed to load arrow indicator: ${error.message}`))
          }
        }
      )

      if (!map.hasImage('speeding-dot')) {
        const speedingDot = generateSpeedingDot(map)
        map.addImage(
          'speeding-dot',
          speedingDot,
          {
            pixelRatio: 2
          }
        )
      }
    }

    renderAndCaptureMapEvents(1500)
  }, [mapRef.current, trip, bounds])

  useEffect(() => {
    // Load trip's details
    if (startTime) {
      void tripDetails.fetch({
        tripId,
        startTime
      }, token)
    }

    // Load duress summary if the event type is 'duress'
    if (
      eventType === 'duress' &&
      startTime &&
      endTime &&
      sensorInstanceId
    ) {
      void duressSummary.fetch({
        sensorInstanceId: sensorInstanceId.toString(),
        startTime,
        endTime,
        page: 1,
        size: 999
      }, token)
    }
  }, [tripId, sensorInstanceId])

  useEffect(() => {
    tripDetails.data.ifJust((data) => {
      setTrip({
        ...data,
        color: randomHexColor()
      })
    })
  }, [tripDetails.data])

  useEffect(() => {
    duressSummary.data.ifJust((data) => {
      setIncidentSummary(data)
    })
  }, [duressSummary.data])

  useEffect(() => {
    renderAndCaptureMapEvents(600)
  }, [mapRef.current, trip, bounds])

  // Render nothing if the event type is 'duress' and there's no incident summary
  if (eventType === 'duress' && incidentSummary === undefined) {
    return null
  }

  // Render nothing if the event there's no available trip and event sources
  if (!trip || !hasData) {
    return null
  }

  return (
    // This container will hide each map from user's screen to prevent the UI
    // from cluttering, but it will still be rendered to the DOM so html2canvas
    // can capture it
    <Box
      sx={{
        width: 0,
        height: 0,
        overflow: 'hidden'
      }}
    >
      <Box
        ref={setContainerRef}
        sx={{
          pointerEvents: 'none',
          // Set fixed width and height to fit on the main container and to avoid
          // skewed/distorted image
          ...mapSize
        }}
      >
        <ReactMapGL
          ref={mapRef}
          mapStyle={MapType.satellite}
          style={{
            ...mapSize
          }}
          onLoad={onLoad}
          transformRequest={(url) => ({
            url,
            referrerPolicy: 'strict-origin-when-cross-origin'
          })}
          mapboxAccessToken={process.env.REACT_APP_APIKEY_MAPBOX}
          cursor="default"
          maxZoom={16}
          interactive={false}
          attributionControl={false}
          preserveDrawingBuffer
        >
          {/* Render trip layer */}
          {lineMapboxPathSource && (
            <Source {...lineMapboxPathSource}>
              <Layer {...lineBorder} />
              <Layer {...lineLayer} />
            </Source>
          )}

          {/* Render speeding event indicators */}
          {eventType === 'speeding' && speedingSource && (
            <Source {...speedingSource}>
              <Layer {...speedingLayer} />
              <Layer {...speeding} />
            </Source>
          )}

          {/* Render idling event indicators */}
          {eventType === 'idle' && idleBounds && zoom && (
            <IdleMarker
              showTimestamps
              mapRef={mapRef}
              rawPath={trip.rawPath}
              bounds={idleBounds}
              zoom={zoom}
            />
          )}

          {/* Render duress event indicators */}
          {eventType === 'duress' && duressSource && (
            <Source {...duressSource}>
              <Layer {...duressPinLayer} />
              <Layer {...duressTextLayer} />
            </Source>
          )}
        </ReactMapGL>
      </Box>
    </Box>
  )
}
