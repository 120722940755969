import { useEffect, useMemo, useState } from 'react'
import { getSensorData } from '@venturi-io/api/src/collector/sensor'
import { type Agent, type Sensor, getAgentDetails } from '@venturi-io/api/src/config/agent'
import {
  createStyles,
  useMantineTheme,
  Box,
  Group,
  Paper,
  Stack,
  Text
} from '@mantine/core'
import { type LineSvgProps } from '@nivo/line'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import Line from 'src/Charts/Line'
import { dateFormat, giveOffset } from 'src/utils/dates'
import dayjs, { type ManipulateType } from 'dayjs'
import SegmentedTimePeriodSelector, {
  type ChosenBucket,
  type ItemKeys
} from 'src/Input/SegmentedTimePeriodSelector'
import { type DataPoint } from 'src/Charts/shared'
import { useMediaQuery } from '@mantine/hooks'
import { mq } from 'src/utils/style'
import NotApplicableOverlay from '../NotApplicableOverlay'
import Loader from './Loader'

const useStyles = createStyles(() => ({
  chartContainer: {
    minWidth: 0,
    position: 'relative'
  },
  tooltip: {
    background: '#212121',
    color: '#FFFFFF'
  }
}))

type SensorWithExtra = Omit<Sensor, 'id'> & {
  id: string
  agentId: Agent['agentId']
  agentName: Agent['agentName']
  agentAlarmStatus: Agent['alarmStatus']
}

interface SensorData {
  id: string
  data: DataPoint[]
}

interface Props {
  title?: string
  agentId: Agent['agentId']
  sensorInstanceIds: Array<Sensor['sensorInstanceId']>
  unit?: string
  height: number
  margin?: LineSvgProps['margin']
  unstacked?: boolean
  roundedValues?: boolean
  disabled?: boolean
}

export default function SensorHistoryStackedLineChart ({
  title,
  agentId,
  sensorInstanceIds,
  unit,
  height,
  margin,
  unstacked = false,
  roundedValues = false,
  disabled = false
}: Props) {
  const { classes } = useStyles()
  const { colors, colorScheme, breakpoints } = useMantineTheme()
  const isLight = colorScheme === 'light'
  const { token } = useUser()
  const isDesktop = useMediaQuery(mq(breakpoints.sm, false))
  const agentDetails = useApi(getAgentDetails)
  const getSensorsHistory = useApi(getSensorData)
  const [bucket, setBucket] = useState<ChosenBucket<ItemKeys>>({
    item: '7 days',
    data: {
      name: '7d',
      timeBucket: '1 day',
      noOfRecords: 7
    }
  })
  const axisBottomFormat = bucket.item.includes('day')
    ? 'DD/MM'
    : 'h:mm A'

  const agent = useMemo(() => (
    agentDetails.data.mapOrDefault(data => data, null)
  ), [agentDetails.data])

  const sensorsWithExtra: SensorWithExtra[] = useMemo(() => {
    if (!agent) return []

    const {
      agentId,
      agentName,
      alarmStatus,
      sensors
    } = agent

    return sensors
      .filter(({ sensorInstanceId }) => sensorInstanceIds.includes(sensorInstanceId))
      .map(sensor => ({
        agentId,
        agentName,
        agentAlarmStatus: alarmStatus,
        ...sensor,
        id: sensor.name
      }))
  }, [agent, sensorInstanceIds])

  const sensorsHistory: SensorData[] = useMemo(() => (
    getSensorsHistory.data.mapOrDefault(({ result }) => (
      result
        .reduce((sensorsData_: SensorData[], { sensorInstanceId, data }) => {
          const sensor: SensorWithExtra | undefined =
            sensorsWithExtra.find(s => s.sensorInstanceId === sensorInstanceId)

          if (sensor) {
            sensorsData_ = [
              ...sensorsData_,
              {
                id: sensor.id,
                data: data
                  .map(({ x, y }) => (
                    {
                      x,
                      y: typeof y !== 'undefined'
                        ? roundedValues
                          ? Math.round(y)
                          : y
                        : 0
                    }
                  ))
                  .sort((a, b) => (a.x > b.x ? 1 : -1))
              }
            ]
          }

          return sensorsData_
        }, [])
        .filter(sensor => sensor.data.length > 0)
    ), [])
  ), [getSensorsHistory.data, sensorsWithExtra])

  useEffect(() => {
    const completeSensors = !sensorInstanceIds.includes(-1)

    // Trigger API call if sensors are complete
    if (completeSensors) {
      const time = bucket.item.split(' ')
      void getSensorsHistory.fetch({
        sensorInstanceIds,
        startTime: dayjs()
          .subtract(Number.parseInt(time[0]), time[1] as ManipulateType)
          .format(dateFormat),
        endTime: dayjs().format(dateFormat),
        timeBucket: bucket.data.timeBucket,
        timeZone: giveOffset() as '+10:00',
        noOfRecords: bucket.data.noOfRecords
      }, token)
    }
  }, [sensorInstanceIds, bucket])

  useEffect(() => {
    void agentDetails.fetch({ agentId }, token)
  }, [agentId])

  return (
    <Box>
      <Stack spacing={8}>
        <Group position="apart">
          <Text size={14} weight={600}>{title}</Text>
          <SegmentedTimePeriodSelector
            chosenBucket={bucket}
            updateBucket={item => setBucket(item)}
            isLoading={getSensorsHistory.loading || disabled}
          />
        </Group>
        <Box
          sx={{
            pointerEvents: getSensorsHistory.loading
              ? 'none'
              : 'auto'
          }}
          className={classes.chartContainer}
        >
          {disabled && <NotApplicableOverlay size="lg" />}
          <Loader isLoading={getSensorsHistory.loading} />
          <Line
            data={sensorsHistory}
            height={height}
            colors={{
              scheme: 'tableau10'
            }}
            margin={margin}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: 0,
              max: 'auto',
              stacked: !unstacked,
              reverse: false
            }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              format: (value) => dayjs(value).format(axisBottomFormat),
              tickSize: 0,
              tickPadding: 12,
              tickRotation: isDesktop
                ? 0
                : -50,
              legendOffset: 36,
              legendPosition: 'middle',
              truncateTickAt: 0
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 12,
              tickRotation: 0,
              legendOffset: -40,
              legendPosition: 'middle',
              truncateTickAt: 0
            }}
            enableGridX={false}
            pointSize={8}
            pointColor={{
              from: 'color',
              modifiers: []
            }}
            pointBorderWidth={1}
            pointBorderColor="#ffffff"
            pointLabel="data.yFormatted"
            pointLabelYOffset={-11}
            enableArea={true}
            areaOpacity={0.15}
            enableTouchCrosshair={true}
            crosshairType="x"
            useMesh={sensorsHistory.length > 1}
            legends={[]}
            motionConfig="gentle"
            curve="monotoneX"
            theme={{
              axis: {
                ticks: {
                  text: {
                    fontSize: 10,
                    fontWeight: 400,
                    fill: '#909296'
                  }
                }
              },
              grid: {
                line: {
                  stroke: isLight
                    ? colors.gray[2]
                    : colors.dark[4]
                }
              },
              crosshair: {
                line: {
                  stroke: isLight
                    ? colors.dark[4]
                    : colors.gray[2]
                }
              }
            }}
            tooltip={({ point }) => (
              <Paper
                className={classes.tooltip}
                px={12}
                py={8}
                radius="sm"
                shadow="sm"
              >
                <Text
                  size={12}
                  weight={500}
                  color={point.color}
                >
                  {point.serieId}
                </Text>
                <Text size={12} weight={500}>
                  {`${dayjs(point.data.x).format('MMM D h:mm A')}`}
                </Text>
                <Text size={16} weight={600}>
                  {`${point.data.y ?? 0} ${unit ?? ''}`}
                </Text>
              </Paper>
            )}
            enableSlices="x"
            sliceTooltip={({ slice: { points } }) => (
              <Paper
                className={classes.tooltip}
                px={12}
                py={8}
                radius="sm"
                shadow="sm"
              >
                <Stack spacing={8}>
                  {points.map(({ color, serieId, data }) => (
                    <Box key={serieId}>
                      <Text
                        size={12}
                        weight={500}
                        color={color}
                      >
                        {serieId}
                      </Text>
                      <Text size={12} weight={500}>
                        {`${dayjs(data.x).format('MMM D h:mm A')}`}
                      </Text>
                      <Text size={16} weight={600}>
                        {`${data.y ?? 0} ${unit ?? ''}`}
                      </Text>
                    </Box>
                  ))}
                </Stack>
              </Paper>
            )}
          />
        </Box>
      </Stack>
    </Box>
  )
}
