import { useState, useEffect, useMemo } from 'react'
import { Box, Paper, useMantineTheme } from '@mantine/core'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { useParams } from 'react-router'
import { getAgentDetails } from '@venturi-io/api/src/config/agent'
import { getCurrentBreakpoint, getMantineBreakpointsInPx, mq } from 'src/utils/style'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import Loader from 'src/Layout/Loader'
import { useMediaQuery } from '@mantine/hooks'
import { getHeight, gridColumns, findSensorByType } from '../shared'
import MissingSensorMessage from '../MissingSensorMessage'
import ModuleContainer from './ModuleContainer'
import { defaultLayouts, reuse } from './data'
import { MODULE_CONTENT } from './constants'

const ResponsiveGridLayout = WidthProvider(Responsive)

interface RouteParams extends Record<string, string | undefined> {
  assetId: string
}

const rowHeight = 350
const baseY = 1
const collapsedHeight = rowHeight
const defaultYs = {
  trips: collapsedHeight,
  transactions: collapsedHeight,
  tankHistory: rowHeight + 100,
  tasks: collapsedHeight,
  audit: collapsedHeight + 100
}

export default function VehicleDashboard () {
  const { breakpoints: { lg } } = useMantineTheme()
  const isDesktop = useMediaQuery(mq(lg, false))
  const agentDetails = useApi(getAgentDetails)
  const { token } = useUser()
  const [missingSensors, setMissingSensors] = useState<string[]>([])
  const [layout, setLayout] = useState(defaultLayouts)
  const [ys, setYs] = useState(defaultYs)

  const { assetId } = useParams<RouteParams>()
  if (!assetId) {
    throw new Error('No asset ID given, routing is broken')
  }

  const breakpoints = getMantineBreakpointsInPx()
  const initialBreakpoint = getCurrentBreakpoint()
  const [currentBreakpoint, setCurrentBreakpoint] = useState(initialBreakpoint)
  const onBreakpointChange = (breakpoint: string) => setCurrentBreakpoint(breakpoint)

  const agent = agentDetails.data.mapOrDefault(data => data, null)

  const fuelLevelLiter = useMemo(() => {
    if (agent) {
      const { sensors } = agent
      return findSensorByType('FUEL_LEVEL_L', sensors)
    }
    return null
  }, [agent])

  useEffect(() => {
    setCurrentBreakpoint(initialBreakpoint)
  }, [initialBreakpoint])

  useEffect(() => {
    const agentId = parseInt(assetId)

    void agentDetails
      .fetch({ agentId }, token)
      .finally(() => {
        agentDetails.startPolling({ agentId }, token, 60)
      })

    return () => {
      agentDetails.stopPolling()
      agentDetails.abort()
    }
  }, [])

  const handleHeightAdjustment = (height: number, key: string) => {
    const barHeight = 24
    setYs({
      ...ys,
      [key]: height + barHeight
    })
  }

  useEffect(() => {
    const allLayouts = [...defaultLayouts[currentBreakpoint]]
    const shared = {
      w: 12,
      x: 0,
      ...reuse
    }

    let dynamicY = isDesktop
      ? baseY
      : 3
    let tankHistoryLayout

    const tripsLayout = {
      i: MODULE_CONTENT.VEHICLE_TRIP,
      y: dynamicY,
      h: getHeight(ys.trips),
      ...shared
    }
    dynamicY += getHeight(ys.trips)

    if (fuelLevelLiter) {
      tankHistoryLayout = {
        i: MODULE_CONTENT.VEHICLE_TANK_HISTORY,
        y: dynamicY,
        h: getHeight(ys.tankHistory),
        ...shared
      }
      dynamicY += getHeight(ys.tankHistory)
    }

    const transactionLayout = {
      i: MODULE_CONTENT.VEHICLE_TRANSACTIONS,
      y: dynamicY,
      h: getHeight(ys.transactions),
      ...shared
    }
    dynamicY += getHeight(ys.transactions)

    const tasksLayout = {
      i: MODULE_CONTENT.VEHICLE_TASKS,
      y: dynamicY,
      h: getHeight(ys.tasks),
      ...shared
    }

    dynamicY += getHeight(ys.tasks)
    const auditLayout = {
      i: MODULE_CONTENT.VEHICLE_AUDIT_LOG,
      y: dynamicY,
      h: getHeight(ys.audit),
      ...shared
    }

    if (tankHistoryLayout) {
      allLayouts.splice(3, 5, tripsLayout, tankHistoryLayout, transactionLayout, tasksLayout, auditLayout)
    } else {
      allLayouts.splice(3, 5, tripsLayout, transactionLayout, tasksLayout, auditLayout)
    }

    setLayout({
      xl: allLayouts,
      lg: allLayouts,
      md: allLayouts,
      sm: allLayouts,
      xs: allLayouts,
      xxs: allLayouts
    })
  }, [ys, currentBreakpoint])

  return (
    <Box mt="lg">
      {agentDetails.loading && <Loader />}
      {!agentDetails.loading && missingSensors.length > 0 && (
        <MissingSensorMessage assetName={agent?.agentName} missingSensors={missingSensors} />
      )}
      {agent && (
        <ResponsiveGridLayout
          className="layout"
          layouts={layout}
          rowHeight={rowHeight}
          cols={gridColumns}
          breakpoints={breakpoints}
          onBreakpointChange={onBreakpointChange}
        >
          {currentBreakpoint && (
            layout[currentBreakpoint]?.map(item => (
              <Paper
                key={item.i}
                p="md"
                shadow="sm"
                style={{
                  overflow: 'none',
                  position: 'relative'
                }}
              >
                <ModuleContainer
                  agent={agent}
                  contentKey={item.i}
                  setMissingSensors={setMissingSensors}
                  didChangeHeight={handleHeightAdjustment}
                />
              </Paper>
            ))
          )}
        </ResponsiveGridLayout>
      )}
    </Box>
  )
}
