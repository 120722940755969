import React from 'react'
import {
  ActionIcon,
  type ActionIconProps,
  type DefaultMantineColor,
  Tooltip,
  useMantineTheme
} from '@mantine/core'
import { type Icon, type IconProps } from '@tabler/icons-react'

interface Props extends IconProps, Partial<Pick<ActionIconProps, 'variant'>> {
  tooltip: string
  onClick: () => void
  Icon: Icon
  buttonColor?: DefaultMantineColor
}

function NodeAction ({
  tooltip,
  onClick,
  Icon,
  variant,
  buttonColor,
  ...rest
}: Props) {
  const { colorScheme } = useMantineTheme()
  const isLight = colorScheme === 'light'

  return (
    <Tooltip withArrow label={tooltip}>
      <ActionIcon
        size="sm"
        variant={variant ?? 'outline'}
        color={typeof buttonColor !== 'undefined'
          ? buttonColor
          : isLight
            ? 'dark.2'
            : 'gray.4'}
        onClick={onClick}
      >
        <Icon size={16} {...rest} />
      </ActionIcon>
    </Tooltip>
  )
}

export default NodeAction
