import AgentNode, { type AgentNodeType } from './Nodes/AgentNode'
import BackgroundNode from './Nodes/BackgroundNode'
import ImageNode from './Nodes/ImageNode'
import LabelNode from './Nodes/LabelNode'
import SensorNode, { type SensorNodeType } from './Nodes/SensorNode'
import ShapeNode from './Nodes/ShapeNode'
import GroupNode from './Nodes/GroupNode'
import LineNode from './Nodes/LineNode'
import FloatingEdge from './Nodes/LineNode/FloatingEdge'
import SiteNode from './Nodes/SiteNode'

export const nodeTypes = {
  agentNode: AgentNode,
  sensorNode: SensorNode,
  siteNode: SiteNode,
  imageNode: ImageNode,
  labelNode: LabelNode,
  shapeNode: ShapeNode,
  groupNode: GroupNode,
  lineNode: LineNode,
  backgroundNode: BackgroundNode
}

export type NodeType = keyof typeof nodeTypes

export const edgeTypes = {
  floatingEdge: FloatingEdge
}

export type EdgeType = keyof typeof edgeTypes

export const SELECTED_COLOR = '#1F395C'

export const isAgentNode = (node?: SensorNodeType | AgentNodeType) => !node
  ? false
  : node.type === 'agentNode'

export interface InteractionProps {
  locked: boolean
}

export interface CustomizationProps {
  color?: string
  opacity: number
}
