import { type Node } from '@xyflow/react'
import { getMultilevelNodes, getSelectedNodes } from '../utils'

interface Props {
  nodes: Node[]
  setNodes: (payload: Node[]) => void
}

function useNodeLayerControl ({ nodes, setNodes }: Props) {
  // remove parent nodes by default
  const selectedNodes = nodes.filter(({ selected }) => selected)

  // There are 2 cases that are needed to be addressed
  // 1. Selected items w/o a group
  // 2. Selected items in a group
  // 3. Single items in a group
  // 4. Single items w/o a group
  // 5. Single group node
  // 6. Multiple group nodes

  const hasSelectedGroupNodes = selectedNodes.some(({ type }) => type === 'groupNode')
  const isOnlyLowLevelNodes = !hasSelectedGroupNodes &&
    selectedNodes.filter(({ parentId }) => typeof parentId === 'undefined').length === selectedNodes.length

  const onSendToBack = () => {
    let organizedNodes: Node[] = []
    // 1. Single/Multiple group node
    if (hasSelectedGroupNodes) {
      const { selected, unSelected } = getSelectedNodes(selectedNodes, nodes)

      organizedNodes = [
        ...selected.flat(),
        ...unSelected
      ]
    }

    // 2. Single/multiple low level nodes
    if (isOnlyLowLevelNodes) {
      const otherNodes = nodes.filter(({ selected }) => !selected)

      organizedNodes = [
        ...selectedNodes,
        ...otherNodes
      ]
    }

    // 3. Single/multiple child nodes within a group
    if (!isOnlyLowLevelNodes && !hasSelectedGroupNodes) {
      const {
        groupNodes,
        selectedWithoutParentNode,
        otherNodes
      } = getMultilevelNodes(selectedNodes, nodes, 'toBack')

      organizedNodes = [
        // group and single selected
        ...groupNodes.flat(),
        ...selectedWithoutParentNode,
        ...otherNodes
      ]
    }

    setNodes(organizedNodes)
  }

  const onBringToFront = () => {
    let organizedNodes: Node[] = []

    // 1. Single group node
    if (hasSelectedGroupNodes) {
      const { selected, unSelected } = getSelectedNodes(selectedNodes, nodes)

      organizedNodes = [
        ...unSelected,
        ...selected.flat()
      ]
    }

    // 2. Single/multiple low level nodes
    if (isOnlyLowLevelNodes) {
      const otherNodes = nodes.filter(({ selected }) => !selected)

      organizedNodes = [
        ...otherNodes,
        ...selectedNodes
      ]
    }

    // 3. Single/multiple child nodes within a group
    if (!isOnlyLowLevelNodes && !hasSelectedGroupNodes) {
      const {
        groupNodes,
        selectedWithoutParentNode,
        otherNodes
      } = getMultilevelNodes(selectedNodes, nodes, 'toFront')

      organizedNodes = [
        ...otherNodes,
        // group and single selected
        ...groupNodes.flat(),
        ...selectedWithoutParentNode
      ]
    }

    setNodes(organizedNodes)
  }

  return {
    onSendToBack,
    onBringToFront
  }
}

export default useNodeLayerControl
