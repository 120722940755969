import { createStyles, TextInput } from '@mantine/core'
import { useReactFlow } from '@xyflow/react'
import { getTextColor } from 'src/utils/theme'
import { useViewControls } from '../NodeContext'

const useStyles = createStyles(theme => ({
  nodeLabel: {
    width: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'none'
  },
  input: {
    width: '100%',
    textAlign: 'center',
    ...getTextColor(theme)
  }
}))

interface Props {
  id: string
  text: string
}

function Label ({ id, text }: Props) {
  const { viewOnly } = useViewControls()
  const { classes } = useStyles()
  const { updateNodeData } = useReactFlow()

  return (
    <TextInput
      readOnly={viewOnly}
      value={text}
      variant="unstyled"
      classNames={{
        wrapper: classes.nodeLabel,
        input: classes.input
      }}
      type="text"
      onChange={(e) => updateNodeData(id, { text: e.target.value })}
    />
  )
}

export default Label
