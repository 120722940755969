import {
  ActionIcon,
  Code,
  Group,
  Paper,
  ScrollArea,
  SegmentedControl,
  Stack,
  Transition
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconBrandPnpm, IconX } from '@tabler/icons-react'
import { type Edge, type Node } from '@xyflow/react'
import { useState } from 'react'

const isDev = process.env.NODE_ENV === 'development'
type ViewMode = 'edges' | 'nodes'

interface Props {
  nodes?: Node[]
  edges?: Edge[]
}

function NodeEdgeInspector ({
  nodes,
  edges
}: Props) {
  const [viewMode, setViewMode] = useState<ViewMode>('edges')
  const [show, { toggle }] = useDisclosure(false)

  const simplifiedNode = nodes?.map(({
    id,
    type,
    parentId,
    zIndex
  }) => ({
    id,
    type,
    parentId,
    zIndex
  }))

  return isDev && (
    <>
      <Transition
        mounted={show}
        transition="slide-right"
        duration={500}
        timingFunction="ease"
      >
        {styles => (
          <Paper shadow="xl"
            p="xs"
            pos="absolute"
            sx={{
              top: 12,
              left: 12
            }}
            style={styles}
          >
            <Stack spacing={4}>
              <Group position="apart">
                <SegmentedControl
                  value={viewMode}
                  onChange={(value) => setViewMode(value as ViewMode)}
                  data={[
                    { label: 'Nodes', value: 'nodes' },
                    { label: 'Edges', value: 'edges' }
                  ]}
                />
                <ActionIcon onClick={toggle}>
                  <IconX size={16} />
                </ActionIcon>
              </Group>
              <ScrollArea h={300}>
                <Code block>
                  {JSON.stringify(
                    viewMode === 'nodes'
                      ? simplifiedNode
                      : edges,
                    null,
                    2)}
                </Code>
              </ScrollArea>
            </Stack>
          </Paper>
        )}
      </Transition>
      <Transition
        mounted={!show}
        transition="slide-left"
        duration={500}
        timingFunction="ease"
      >
        {styles => (
          <Paper shadow="xl"
            p="xs"
            pos="absolute"
            sx={{
              top: 12,
              left: 12
            }}
            style={styles}
          >
            <ActionIcon onClick={toggle}>
              <IconBrandPnpm size={16} />
            </ActionIcon>
          </Paper>
        )}
      </Transition>
    </>
  )
}

export default NodeEdgeInspector
