import React, { useEffect, useState } from 'react'
import { IconPhotoScan } from '@tabler/icons-react'
import { Image, Stack, Transition } from '@mantine/core'
import { useNodesData, useReactFlow } from '@xyflow/react'
import { SELECTED_COLOR } from '../shared'
import ImageDropzone from '../Components/ImageDropzone'
import { imageStyles } from '../sharedStyles'
import useGenericImage from '../hooks/useGenericImage'
import { type BGImageNodeType } from '../Nodes/BackgroundNode'
import { type ImgData } from '../Nodes/ImageNode'
import BaseWizard from './BaseWizard'
import { useNode } from './WizardContext'
import CustomizationControls from './CustomizationControls'
import InteractionControls from './InteractionControls'

function BackgroundWizard () {
  const { classes } = imageStyles()
  const { getNode, addNodes, updateNodeData } = useReactFlow()
  const { bgWizardShow, bgWizardClose } = useNode()

  const bgNode = getNode('fixed-background') as BGImageNodeType
  const isCreate = typeof bgNode === 'undefined'
  const { upload, load, remove } = useGenericImage()
  const [imageUrl, setImageUrl] = useState<string>()
  const nodeData = useNodesData('fixed-background')

  const onChangeImage = (file?: File) => {
    if (file) {
      // we separate the condition to skip it when no existing node is available
      if (nodeData) {
        const { image } = nodeData.data as ImgData

        if (image?.id) {
          void remove(image.id)
        }
      }

      void upload([file], (response) => {
        if (isCreate) {
          addNodes({
            id: 'fixed-background',
            type: 'backgroundNode',
            data: {
              image: response[0],
              opacity: 1,
              locked: false
            },
            position: { x: 0, y: 0 },
            zIndex: 1,
            draggable: false,
            deletable: false
          })
        } else {
          updateNodeData('fixed-background', { image: response[0] })
        }
      })
    }
  }

  const loadImage = async () => {
    if (nodeData) {
      const { image } = nodeData.data as ImgData

      if (image) {
        const url = await load(image.previewUrl)
        setImageUrl(url)
      } else {
        setImageUrl(undefined)
      }
    }
  }

  useEffect(() => {
    if (nodeData) {
      void loadImage()
    }
  }, [nodeData])

  return (
    <Transition
      mounted={bgWizardShow ?? false}
      duration={400}
      transition="pop"
      timingFunction="ease"
    >
      {styles => (
        <BaseWizard
          expectLongContent
          title="Background"
          Icon={<IconPhotoScan size={24} color={SELECTED_COLOR} />}
          style={styles}
          close={bgWizardClose}
        >
          <Stack spacing="xs">
            <InteractionControls nodeId="fixed-background" hasLock />
            <CustomizationControls nodeId="fixed-background" hasOpacity />
            <ImageDropzone
              setImage={onChangeImage}
              renderImage={imageUrl && (
                <Image
                  src={imageUrl}
                  fit="contain"
                  classNames={{
                    root: classes.maxWidthAndHeight,
                    figure: classes.maxWidthAndHeight,
                    imageWrapper: classes.maxWidthAndHeight,
                    image: classes.maxWidthAndHeight
                  }}
                />
              )}
            />
          </Stack>
        </BaseWizard>
      )}
    </Transition>
  )
}

export default BackgroundWizard
