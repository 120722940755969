import { useCallback, useRef, useState } from 'react'
import { type Report } from '@venturi-io/api/src/config/report'
import { type Range } from '@venturi-io/api'
import DownloadAsPdfButton from 'src/Layout/ReportTemplate/DownloadAsPdfButton'
import { type TimePeriod } from 'src/Buttons/TimePeriodButton'
import { type ParsedTripItem } from '../TripReportView'
import { type ParsedSpeedEvent } from '../SpeedingReportView'
import { type ParsedDuressEvent } from '../DuressReportView'
import { type ParsedIdleEvent } from '../IdleReportView'
import { type ParsedGeozoneEvent } from '../GeozoneReportView'
import { type SiteTankLevels } from '../TankLevelReportView'
import TankLevelChartsBuilder, {
  type ChartsBuilderProps
} from '../TankLevelReportView/ChartsBuilder'
import SpeedingMapsBuilder, {
  type SpeedingMapsBuilderProps
} from '../SpeedingReportView/SpeedingMapsBuilder'
import SpeedingEvents from './SpeedingEvents'
import TripHistory from './TripHistory'
import ConditionSummary from './ConditionSummary'
import DuressEvents from './DuressEvents'
import IdleTimeEvents from './IdleTimeEvents'
import GeozoneEvents from './GeozoneEvents'
import TankLevelHistory from './TankLevelHistory'

type Parsed =
  ParsedTripItem[] |
  ParsedSpeedEvent[] |
  ParsedDuressEvent[] |
  ParsedIdleEvent[] |
  ParsedGeozoneEvent[] |
  SiteTankLevels[]

export interface Props {
  report: Report
  rawData: Record<string, Array<Record<string, string | null>>>
  data?: Parsed
  timePeriod: TimePeriod['value'] | null
  range: Range | null
  queryId?: number
  disabled?: boolean
}

export default function ExportToPdf (props: Props) {
  const {
    report,
    rawData,
    data,
    timePeriod,
    range,
    queryId,
    disabled = false
  } = props
  const [rows, setRows] = useState(data ?? [])
  // TO DO: Revisit queryId integration once all report types have been grouped
  const records = typeof queryId !== 'undefined'
    ? rawData[`${queryId}`]
    : []
  const tankLevelChartsBuilderRef = useRef<ChartsBuilderProps>(null)
  const speedingMapsBuilderRef = useRef<SpeedingMapsBuilderProps>(null)
  const [isBuilding, setIsBuilding] = useState(false)

  // This function will trigger report's build process first
  // before triggering the download function
  const onBuild = useCallback(() => {
    const { reportType: { name } } = report

    switch (name) {
      case 'Tank Level History Report':
        setIsBuilding(true)
        tankLevelChartsBuilderRef.current?.open()
        break

      case 'Speed Event Report':
        setIsBuilding(true)
        speedingMapsBuilderRef.current?.open()
        break

      default:
        setIsBuilding(false)
    }
  }, [report, tankLevelChartsBuilderRef, speedingMapsBuilderRef])

  const renderPDFChild = useCallback(() => {
    const { reportType: { name } } = report
    const commonProps = { range, report }

    switch (name) {
      case 'Trip History':
        return (
          <TripHistory
            {...commonProps}
            data={rows as ParsedTripItem[]}
          />
        )
      case 'Speed Event Report':
        return (
          <SpeedingEvents
            {...commonProps}
            data={rows as ParsedSpeedEvent[]}
          />
        )
      case 'Duress Event Report':
        return (
          <DuressEvents
            {...commonProps}
            data={rows as ParsedDuressEvent[]}
          />
        )
      case 'Idle Time Report':
        return (
          <IdleTimeEvents
            {...commonProps}
            data={rows as ParsedIdleEvent[]}
          />
        )
      case 'Geozone Event Report':
        return (
          <GeozoneEvents
            {...commonProps}
            data={rows as ParsedGeozoneEvent[]}
          />
        )
      case 'Tank Level History Report':
        return (
          <TankLevelHistory
            {...commonProps}
            data={rows as SiteTankLevels[]}
          />
        )
      default:
        return (
          <ConditionSummary
            report={report}
            data={records}
          />
        )
    }
  }, [report, range, rows])

  return (
    <>
      <DownloadAsPdfButton
        fileName={report.reportType.name}
        isBuilding={isBuilding}
        onBuild={onBuild}
        renderDocument={renderPDFChild}
        disabled={disabled}
      />
      {report.reportType.name === 'Tank Level History Report' && (
        <TankLevelChartsBuilder
          ref={tankLevelChartsBuilderRef}
          timePeriod={timePeriod}
          data={rows as SiteTankLevels[]}
          isBuilding={isBuilding}
          setIsBuilding={setIsBuilding}
          onSuccess={(data) => {
            setRows(data)
          }}
        />
      )}
      {report.reportType.name === 'Speed Event Report' && (
        <SpeedingMapsBuilder
          ref={speedingMapsBuilderRef}
          data={rows as ParsedSpeedEvent[]}
          isBuilding={isBuilding}
          setIsBuilding={setIsBuilding}
          onSuccess={(data) => {
            setRows(data)
          }}
        />
      )}
    </>
  )
}
