import React from 'react'
import { useNodesData, useReactFlow } from '@xyflow/react'
import { Group, Slider, Stack, Text } from '@mantine/core'
import { IconSparkles } from '@tabler/icons-react'
import { SELECTED_COLOR } from '../../shared'

interface Props {
  nodeId: string
  hasOpacity?: boolean
}

function CustomizationControls ({ nodeId, hasOpacity }: Props) {
  const { updateNode } = useReactFlow()
  const nodeData = useNodesData(nodeId)
  if (!nodeData) return null

  const { opacity } = nodeData.data

  return (
    <Stack spacing="xs">
      <Group>
        <IconSparkles size={16} color={SELECTED_COLOR} />
        <Text weight="bold">Appearance</Text>
      </Group>
      {hasOpacity && (
        <Slider
          color="primary"
          value={opacity as number}
          onChange={(value) =>
            updateNode(
              nodeId,
              (node) => ({
                data: {
                  ...node.data,
                  opacity: value
                }
              }))}
          min={0}
          max={1}
          step={0.1}
        />
      )}
    </Stack>
  )
}

export default CustomizationControls
