import { type Layouts } from 'react-grid-layout'
import { MODULE_CONTENT } from './constants'

export const reuse = {
  static: true,
  isDraggable: false
}

export const initialLayout = [
  { i: MODULE_CONTENT.VEHICLE_DETAILS, x: 0, y: 0, w: 4, h: 1, ...reuse },
  { i: MODULE_CONTENT.VEHICLE_STATUS, x: 4, y: 0, w: 4, h: 1, ...reuse },
  { i: MODULE_CONTENT.VEHICLE_LOCATION, x: 8, y: 0, w: 4, h: 1, ...reuse },
  { i: MODULE_CONTENT.VEHICLE_TRIP, x: 0, y: 1, w: 12, h: 0.9, ...reuse },
  { i: MODULE_CONTENT.VEHICLE_TANK_HISTORY, x: 0, y: 1.9, w: 12, h: 1.3, ...reuse },
  { i: MODULE_CONTENT.VEHICLE_TRANSACTIONS, x: 0, y: 3.2, w: 12, h: 0.9, ...reuse },
  { i: MODULE_CONTENT.VEHICLE_TASKS, x: 0, y: 4.1, w: 12, h: 0.9, ...reuse },
  { i: MODULE_CONTENT.VEHICLE_AUDIT_LOG, x: 0, y: 5.0, w: 12, h: 0.9, ...reuse }
]

export const mdLayout = [
  { i: MODULE_CONTENT.VEHICLE_DETAILS, x: 0, y: 0, w: 12, h: 1, ...reuse },
  { i: MODULE_CONTENT.VEHICLE_STATUS, x: 0, y: 1, w: 12, h: 1, ...reuse },
  { i: MODULE_CONTENT.VEHICLE_LOCATION, x: 0, y: 2, w: 12, h: 1, ...reuse },
  { i: MODULE_CONTENT.VEHICLE_TRIP, x: 0, y: 3, w: 12, h: 0.9, ...reuse },
  { i: MODULE_CONTENT.VEHICLE_TANK_HISTORY, x: 0, y: 4.9, w: 12, h: 2, ...reuse },
  { i: MODULE_CONTENT.VEHICLE_TRANSACTIONS, x: 0, y: 6.9, w: 12, h: 0.9, ...reuse },
  { i: MODULE_CONTENT.VEHICLE_TASKS, x: 0, y: 7.8, w: 12, h: 0.9, ...reuse },
  { i: MODULE_CONTENT.VEHICLE_AUDIT_LOG, x: 0, y: 6.0, w: 12, h: 0.9, ...reuse }
]

export const defaultLayouts: Layouts = {
  xl: initialLayout,
  lg: initialLayout,
  md: initialLayout,
  sm: mdLayout,
  xs: mdLayout,
  xxs: mdLayout
}
