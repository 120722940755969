import React, { useCallback, useEffect, useState } from 'react'
import { IconBox } from '@tabler/icons-react'
import { useReactFlow } from '@xyflow/react'
import SelectSite from 'src/Input/Select/SelectSite'
import { SELECTED_COLOR } from '../shared'
import BaseWizard from './BaseWizard'
import { useNode } from './WizardContext'

function SiteWizard () {
  const { updateNode } = useReactFlow()
  const { selectedNode } = useNode()

  const [siteId, setSiteId] = useState<string | null>(null)

  useEffect(
    () => {
      if (selectedNode?.data?.siteId) {
        const selectedId = selectedNode?.data?.siteId as string
        setSiteId(selectedId)
      } else {
        // node not set yet
        setSiteId(null)
      }
    },
    [selectedNode?.data?.siteId]
  )

  const handleUpdate = useCallback(
    (id: string | null, name?: string) => {
      if (selectedNode && (id ?? name)) {
        updateNode(selectedNode.id, {
          data: {
            siteId: id,
            siteName: name
          }
        })
      }
    },
    [selectedNode]
  )

  return selectedNode && selectedNode.type === 'siteNode' && (
    <BaseWizard
      title="Site Wizard"
      Icon={<IconBox size={24} color={SELECTED_COLOR} />}
    >
      <SelectSite
        searchable
        value={siteId}
        label="Select a site"
        onChange={handleUpdate}
      />
    </BaseWizard>
  )
}

export default SiteWizard
