import { IconCpu } from '@tabler/icons-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useReactFlow } from '@xyflow/react'
import SelectSensor from 'src/Input/Select/SelectSensor'
import { SELECTED_COLOR } from '../shared'
import { useNode } from './WizardContext'
import BaseWizard from './BaseWizard'

function SensorWizard () {
  const { updateNode } = useReactFlow()
  const { selectedNode } = useNode()

  const [sensorId, setSensorId] = useState<string | null>(null)

  useEffect(
    () => {
      if (selectedNode?.data?.sensorId) {
        const id = selectedNode?.data?.sensorId
        const selectedId = typeof id === 'number'
          ? id.toString()
          : id as string
        setSensorId(selectedId)
      } else {
        // node not set yet
        setSensorId(null)
      }
    },
    [selectedNode?.data?.sensorId]
  )

  const handleUpdate = useCallback(
    (id: string | null, name?: string) => {
      if (selectedNode && (id ?? name)) {
        updateNode(selectedNode.id, {
          data: {
            sensorId: id,
            sensorName: name
          }
        })
      }
    },
    [selectedNode]
  )

  return selectedNode && selectedNode.type === 'sensorNode' && (
    <BaseWizard
      title="Sensor Wizard"
      Icon={<IconCpu size={24} color={SELECTED_COLOR} />}
    >
      <SelectSensor
        searchable
        value={sensorId}
        label="Select a sensor"
        onChange={handleUpdate}
      />
    </BaseWizard>
  )
}

export default SensorWizard
