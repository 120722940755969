import React from 'react'
import { Position } from '@xyflow/react'
import Handle from './CustomHandle'

interface Props {
  hovered: boolean
  nodeId: string
}

function Handles ({ hovered, nodeId }: Props) {
  return (
    <>
      <Handle
        id="bottom"
        nodeId={nodeId}
        hovered={hovered}
        position={Position.Bottom}
      />
      <Handle
        id="right"
        nodeId={nodeId}
        hovered={hovered}
        position={Position.Right}
      />
      <Handle
        id="top"
        nodeId={nodeId}
        hovered={hovered}
        position={Position.Top}
      />
      <Handle
        id="left"
        nodeId={nodeId}
        hovered={hovered}
        position={Position.Left}
      />
    </>
  )
}

export default Handles
