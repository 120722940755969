/* eslint-disable @typescript-eslint/naming-convention */
import { type SpeedAlert } from 'src/Reports/Viewer/SpeedingReportView'
import dayjs from 'dayjs'
import { uiDateFormatUniversal } from 'src/utils/dates'

interface Props {
  index: number
  data: SpeedAlert
}

export default function Row ({
  index,
  data: {
    id,
    speed_date_time,
    location,
    geo_zone_name,
    speed_limit,
    speed
  }
}: Props) {
  return (
    <tr key={id}>
      <td>{index + 1}</td>
      <td>{dayjs(speed_date_time).format(uiDateFormatUniversal)}</td>
      <td>{location}</td>
      <td>{geo_zone_name}</td>
      <td>{speed_limit}</td>
      <td>{speed}</td>
    </tr>
  )
}
