import React, { type ReactElement, type ReactNode, useState } from 'react'
import { createStyles, Stack, Text } from '@mantine/core'
import { Dropzone, MIME_TYPES } from '@mantine/dropzone'
import { IconPhotoScan } from '@tabler/icons-react'
import { getPlaceholderColorAsProp } from 'src/utils/theme'

const widthAndHeight = {
  w: '100%',
  h: '100% !important'
}

const useStyles = createStyles(() => ({
  maxWidthAndHeight: {
    pointerEvents: 'none',
    width: '100%',
    height: '100% !important'
  }
}))

interface Props {
  setImage: (file?: File) => void
  renderImage?: JSX.Element | ReactNode | ReactElement
}

function ImageDropzone ({ setImage, renderImage }: Props) {
  const { classes } = useStyles()
  const [error, setError] = useState<string>()

  return (
    <Dropzone
      classNames={{
        inner: classes.maxWidthAndHeight
      }}
      multiple={false}
      accept={[MIME_TYPES.png, MIME_TYPES.jpeg]}
      onReject={() => setError('file is not jpg/png')}
      onDrop={([file]) => {
        setImage(file)
      }}
      {...widthAndHeight}
    >
      <Stack
        spacing="xs"
        justify="center"
        align="center"
        {...widthAndHeight}
      >
        {renderImage ?? (
          <IconPhotoScan size={24} {...getPlaceholderColorAsProp()} />
        )}
        <Text color="dimmed" size="xs">
          Drag or click to select an image
        </Text>
        {error && (
          <Text color="red" size="xs">
            {`Error: ${error}`}
          </Text>
        )}
      </Stack>
    </Dropzone>
  )
}

export default ImageDropzone
