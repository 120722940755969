import React from 'react'
import { Button, createStyles, type MantineColor, Text } from '@mantine/core'
import { type Icon } from '@tabler/icons-react'
import { getTextColorAsProp } from 'src/utils/theme'
import { SELECTED_COLOR } from '../shared'

const useStyles = createStyles(({ colorScheme, colors }) => ({
  button: {
    display: 'flex',
    '&:hover': {
      backgroundColor: colorScheme === 'light'
        ? colors.gray[2]
        : colors.dark[3]
    }
  }
}))

interface Props {
  Icon: Icon
  label: string
  disabled: boolean
  color?: MantineColor
  onClick: () => void
}

function ItemButton ({
  Icon,
  label,
  disabled,
  color = SELECTED_COLOR,
  onClick
}: Props) {
  const { classes } = useStyles()

  return !disabled && (
    <Button
      className={classes.button}
      p={4}
      variant="subtle"
      leftIcon={<Icon size={16} color={color} />}
      onClick={onClick}
    >
      <Text
        {...getTextColorAsProp()}
        color={color}
        weight={color === 'red'
          ? 'bold'
          : 'lighter'}
        size="sm"
      >
        {label}
      </Text>
    </Button>
  )
}

export default ItemButton
