/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useEffect, useState } from 'react'
import { type Report, type ReportData } from '@venturi-io/api/src/config/report'
import { reverseGeocode } from 'src/Maps/GeoZoneMap/mapbox'
import { secondsToDhms } from 'src/Assets/shared'
import { round } from 'src/utils/math'
import { type Range } from '@venturi-io/api'
import dayjs from 'dayjs'
import { uiDateFormat } from 'src/utils/dates'
import { convertStrToFloat } from 'src/AssetTemplate/shared'
import { type CSVData } from 'src/utils/files'
import { getQueryIdByName } from '..'
import Content from './Content'

export enum TripHistoryQueries {
  summary = 'trip_history_summary',
  allTrips = 'trip_history_all_trips'
}

export const transformTripReportDataToCSV = (items: ParsedTripItem[]): CSVData => {
  const withSummary = items.length > 1

  const emptyTripSummary = {
    Assets: '',
    'Alarm Status': '',
    '# Trips': '',
    'Total Duration': '',
    'Total Distance KMs': ''
  }

  const emptyTripDetails = {
    'Start Date/Time': '',
    'End Date/Time': '',
    Driver: '',
    'Start Location': '',
    'End Location': '',
    'Driving Duration': '',
    'Idling Duration': '',
    'KMs Travelled': '',
    'Avg Speed (Kmh)': '',
    'Max Speed (Kmh)': '',
    'Max Accel (X)': '',
    'Max Accel (Y)': '',
    'Max Accel (Z)': ''
  }

  const summary = withSummary
    ? [
        ...items.map(({
          agent_name,
          alarm_status,
          total_trips,
          total_duration,
          total_distance_in_kilometres
        }) => ({
          Assets: agent_name,
          'Alarm Status': alarm_status,
          '# Trips': total_trips,
          'Total Duration': total_duration,
          'Total Distance KMs': total_distance_in_kilometres,
          ...emptyTripDetails
        })),
        // these empty objects serve as empty rows before all trips data
        {},
        {},
        {}
      ]
    : []

  const allTrips = items.reduce((data: CSVData, {
    agent_name,
    alarm_status,
    total_trips,
    total_duration,
    total_distance_in_kilometres,
    trips
  }) => {
    const tripSummary = {
      Assets: agent_name,
      'Alarm Status': alarm_status,
      '# Trips': total_trips,
      'Total Duration': total_duration,
      'Total Distance KMs': total_distance_in_kilometres
    }

    const transformedData: CSVData = trips.map(({
      start_time,
      end_time,
      driver,
      start_location,
      end_location,
      driving_duration,
      idling_duration,
      distance_travelled,
      speed_avg,
      speed_max,
      x_acceleration_max,
      y_acceleration_max,
      z_acceleration_max
    }, index) => {
      const formattedTrip = {
        'Start Date/Time': dayjs(start_time).format(uiDateFormat),
        'End Date/Time': dayjs(end_time).format(uiDateFormat),
        Driver: driver,
        'Start Location': start_location,
        'End Location': end_location,
        'Driving Duration': driving_duration,
        'Idling Duration': idling_duration,
        'KMs Travelled': distance_travelled,
        'Avg Speed (Kmh)': speed_avg,
        'Max Speed (Kmh)': speed_max,
        'Max Accel (X)': x_acceleration_max,
        'Max Accel (Y)': y_acceleration_max,
        'Max Accel (Z)': z_acceleration_max
      }

      return index === 0
        ? {
            ...tripSummary,
            ...formattedTrip
          }
        : {
            ...emptyTripSummary,
            ...formattedTrip
          }
    })

    return [
      ...data,
      ...transformedData,
      {} // this serves as an empty row after each asset
    ]
  }, [])

  return [
    ...summary,
    ...allTrips
  ]
}

export interface Props {
  report: Report
  data: ReportData
  range: Range | null
  maxWidth: number
  setTripReportData: (data: ParsedTripItem[]) => void
  selectedAgentIds: string[]
  selectedAgentGroupIds: string[]
}

export interface AgentTripItem {
  trip_id: string
  agent_id: string
  asset: string
  rfid_tag: string
  driver: string
  start_time: string
  end_time: string
  start_point_latitude: string
  start_point_longitude: string
  end_point_latitude: string
  end_point_longitude: string
  start_location: string
  end_location: string
  duration_in_seconds: string
  driving_duration_in_seconds: string
  driving_duration: string
  idling_duration_in_seconds: string
  idling_duration: string
  distance_in_metres: string
  distance_travelled: string
  speed_avg: string
  speed_min: string
  speed_max: string
  x_acceleration_min: string
  x_acceleration_avg: string
  x_acceleration_max: string
  y_acceleration_min: string
  y_acceleration_avg: string
  y_acceleration_max: string
  z_acceleration_min: string
  z_acceleration_avg: string
  z_acceleration_max: string
  org_user_id: string
  org_user_first_name: string
  org_user_last_name: string
}

export interface TripItem {
  agent_id: string
  agent_name: string
  alarm_status: string
  agent_groups_ids: string
  total_distance_in_metres: string
  total_distance_in_kilometres: string
  total_duration_in_seconds: string
  total_duration: string
  total_trips: string
  trips: AgentTripItem[]
}

export interface ParsedTripItem extends Omit<TripItem, 'agent_groups_ids'> {}

export default function TripReportView ({
  report,
  data,
  range,
  setTripReportData,
  selectedAgentIds,
  selectedAgentGroupIds
}: Props) {
  const { reportQueries } = report.reportType
  const agentGroupIds = selectedAgentGroupIds.join(',') ?? ''
  const tripHistorySummaryQueryId = getQueryIdByName(reportQueries, TripHistoryQueries.summary)
  const tripHistoryAllQueryId = getQueryIdByName(reportQueries, TripHistoryQueries.allTrips)
  const listItems = data[tripHistorySummaryQueryId] as unknown as TripItem[] ?? []
  const allTrips = data[tripHistoryAllQueryId] as unknown as AgentTripItem[] ?? []
  const [parsedTripData, setParsedTripData] = useState<ParsedTripItem[]>([])
  const [isLoading, setIsLoading] = useState(false)

  // Transform and fetch necessary trip data for specific agent
  const getAgentTrips = useCallback(async (
    agent_id: AgentTripItem['agent_id'],
    agent_name: TripItem['agent_name']
  ) => {
    const agentTrips: AgentTripItem[] = allTrips
      .filter((trip) => trip.agent_id === agent_id)
      // Sort by start_time in DESC order (latest to oldest)
      .sort((a, b) => (
        a.start_time < b.start_time
          ? 1
          : -1
      ))

    return await Promise.all(agentTrips.map(async ({
      start_time,
      end_time,
      start_point_longitude,
      start_point_latitude,
      end_point_longitude,
      end_point_latitude,
      org_user_id,
      org_user_first_name,
      org_user_last_name,
      driving_duration_in_seconds,
      idling_duration_in_seconds,
      distance_in_metres,
      speed_avg,
      speed_max,
      x_acceleration_max,
      y_acceleration_max,
      z_acceleration_max,
      ...rest
    }) => {
      const start_location = await reverseGeocode([
        convertStrToFloat(start_point_longitude),
        convertStrToFloat(start_point_latitude)
      ])
      const end_location = await reverseGeocode([
        convertStrToFloat(end_point_longitude),
        convertStrToFloat(end_point_latitude)
      ])

      return {
        ...rest,
        asset: agent_name,
        start_time,
        end_time,
        start_location,
        end_location,
        start_point_longitude,
        start_point_latitude,
        end_point_longitude,
        end_point_latitude,
        org_user_id,
        org_user_first_name,
        org_user_last_name,
        driver: org_user_id
          ? `${org_user_first_name} ${org_user_last_name}`
          : '-',
        driving_duration_in_seconds,
        driving_duration: secondsToDhms(convertStrToFloat(driving_duration_in_seconds)),
        idling_duration_in_seconds,
        idling_duration: secondsToDhms(convertStrToFloat(idling_duration_in_seconds)),
        distance_in_metres,
        distance_travelled: `${round(convertStrToFloat(distance_in_metres) / 1000)}`,
        speed_avg: speed_avg
          ? `${round(convertStrToFloat(speed_avg))}`
          : '-',
        speed_max: speed_max
          ? `${round(convertStrToFloat(speed_max))}`
          : '-',
        x_acceleration_max: x_acceleration_max
          ? `${round(convertStrToFloat(x_acceleration_max))}`
          : '-',
        y_acceleration_max: y_acceleration_max
          ? `${round(convertStrToFloat(y_acceleration_max))}`
          : '-',
        z_acceleration_max: z_acceleration_max
          ? `${round(convertStrToFloat(z_acceleration_max))}`
          : '-'
      }
    }))
  }, [allTrips])

  const load = useCallback(async () => {
    setIsLoading(true)

    // Filter items by selected Agents first to reduce the number of
    // items to be transformed
    const filteredDataByAgents = selectedAgentIds.length > 0
      ? listItems.filter(({ agent_id }) => selectedAgentIds.includes(agent_id))
      : listItems

    // Parse the values of necessary fields including agent_groups_ids
    const parsedItems = await Promise.all(filteredDataByAgents.map(async ({
      agent_id,
      agent_name,
      agent_groups_ids,
      total_distance_in_metres,
      total_duration_in_seconds,
      ...rest
    }) => ({
      ...rest,
      agent_id,
      agent_name,
      total_distance_in_metres,
      total_distance_in_kilometres: `${round(convertStrToFloat(total_distance_in_metres) / 1000)}`,
      total_duration_in_seconds,
      total_duration: `${secondsToDhms(convertStrToFloat(total_duration_in_seconds))}`,
      agent_groups_ids: agent_groups_ids
        ? agent_groups_ids.split(',')
        : [],
      trips: await getAgentTrips(agent_id, agent_name)
    })))

    // Filter items by selected Agent Groups based on parsed
    // agent_groups_ids to reduce the number of items
    const filteredDataByGroups = agentGroupIds.length > 0
      ? parsedItems.filter(({ agent_groups_ids }) => (
        agent_groups_ids.some(id => agentGroupIds.includes(id))
      ))
      : parsedItems

    // Sort by agent_name in ASC order
    const sortedItems = filteredDataByGroups.sort((a, b) => (
      a.agent_name > b.agent_name
        ? 1
        : -1
    ))

    setParsedTripData(sortedItems)
    setTripReportData(sortedItems)
    setIsLoading(false)
  }, [listItems, selectedAgentIds, agentGroupIds])

  useEffect(() => {
    try {
      void load()
    } catch (err: any) {
      throw Error('Failed to generate report')
    }
  }, [
    listItems,
    selectedAgentIds,
    agentGroupIds,
    range
  ])

  return <Content data={parsedTripData} isLoading={isLoading} />
}
