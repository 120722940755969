import { useNavigate } from 'react-router-dom'
import { Group, Text } from '@mantine/core'
import { type Site } from '@venturi-io/api/src/userManager/site'
import TextTooltip from 'src/Layout/TextTooltip'
import { type Row as ListRow, useStyles } from 'src/Layout/ActionList'
import { parseEmpty } from 'src/utils/parseEmpty'
import ActionButton from 'src/ActionButton'

export default function Row ({ siteId, name }: Site & ListRow) {
  const navigate = useNavigate()
  const { classes, cx } = useStyles()

  return (
    <div className={`${classes.row} ${classes.onHover}`}>
      <div className={cx(classes.col, classes.idCol)}>
        <Text size="sm">
          {siteId}
        </Text>
      </div>
      <div className={classes.col}>
        <TextTooltip
          value={parseEmpty(name)}
          maxLength={30}
          size="sm"
        />
      </div>
      <div className={cx(classes.col, classes.actionsCol)}>
        <Group spacing="xs">
          <ActionButton
            label="Edit"
            icon="pencil"
            iconType="fas"
            onClick={() => navigate(`/settings/sites/edit/${siteId}`)}
            actionColor="green"
            actionVariant="filled"
            iconColor="white"
            iconSize="sm"
          />
          <ActionButton
            label="Modify Flow"
            icon="circle-nodes"
            iconType="fas"
            onClick={() => navigate(`/settings/sites/flow/${siteId}`)}
            actionColor="blue"
            actionVariant="filled"
            iconColor="white"
            iconSize="sm"
          />
        </Group>
      </div>
    </div>
  )
}
