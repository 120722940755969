import React from 'react'
import { ActionIcon, Tooltip, type TooltipProps } from '@mantine/core'
import { type Icon } from '@tabler/icons-react'
import { buttonStyles } from '../../sharedStyles'

interface Props {
  Icon: Icon
  label: string
  tooltipProps?: Omit<TooltipProps, 'label'>
  onClick: () => void
  checked: boolean
}

function Action ({
  Icon,
  onClick,
  label,
  tooltipProps,
  checked
}: Props) {
  const { classes } = buttonStyles({ isSelected: checked, isDesktop: true })

  return (
    <Tooltip label={label} {...tooltipProps}>
      <ActionIcon
        className={classes.iconWithSelectedState}
        variant="subtle"
        onClick={onClick}
      >
        <Icon />
      </ActionIcon>
    </Tooltip>
  )
}

export default Action
