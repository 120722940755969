import { type NodeProps, type Node, Position } from '@xyflow/react'
import React, { memo, useState } from 'react'
import { Box, createStyles } from '@mantine/core'
import { useViewControls } from '../NodeContext'
import Handles from './Handles'

interface StyleProps {
  viewOnly?: boolean
  selected?: boolean
}

const useStyles = createStyles((_, { viewOnly, selected }: StyleProps) => ({
  container: {
    width: viewOnly
      ? 0
      : 12,
    height: viewOnly
      ? 0
      : 12,
    borderRadius: 6,
    border: `
      ${selected
          ? '2px'
          : '1px'
      } ${selected
          ? '#1F395C'
          : 'gray'}
        solid
    `,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

export type LineNodeType = Node<{}, 'lineNode'>

function LineNode (props: NodeProps<LineNodeType>) {
  const { viewOnly } = useViewControls()
  const [hovered, setIsHovered] = useState(false)
  const { id, selected } = props
  const { classes } = useStyles({ viewOnly, selected })

  return (
    <Box
      className={classes.container}
        // TODO: Migrate to hovered prop on NodeProps
      onMouseEnter={() => setIsHovered(!viewOnly)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Handles hovered={hovered} position={Position.Top} nodeId={id} />
    </Box>
  )
}

export default memo(LineNode)
