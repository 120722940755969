/* eslint-disable @typescript-eslint/naming-convention */
import { Text, View } from '@react-pdf/renderer'
import dayjs from 'dayjs'
import { uiDateFormatUniversal } from 'src/utils/dates'
import THCell from 'src/Layout/ReportTemplate/Components/THCell'
import TDCell from 'src/Layout/ReportTemplate/Components/TDCell'
import Space from 'src/Layout/ReportTemplate/Components/Space'
import { reportStyles } from 'src/Layout/ReportTemplate/styles'
import { type ParsedSpeedEvent } from '../../SpeedingReportView'

interface Props {
  data: ParsedSpeedEvent[]
}

export default function SummaryTable ({ data }: Props) {
  return (
    <View>
      <Text style={reportStyles.subtitleValue}>
        SUMMARY
      </Text>
      <Space />
      <View style={reportStyles.group}>
        <THCell value="Assets" />
        <THCell value="Date" />
        <THCell value="Violations" />
        <THCell value="Driver" />
      </View>
      {data.map(({
        agent_id,
        agent_name,
        trip_start_time,
        total_speed_alerts,
        driver
      }) => (
        <View key={agent_id} style={reportStyles.group}>
          <TDCell value={agent_name} />
          <TDCell value={dayjs(trip_start_time).format(uiDateFormatUniversal)} />
          <TDCell value={total_speed_alerts} />
          <TDCell value={driver} />
        </View>
      ))}
    </View>
  )
}
