import { Text, View } from '@react-pdf/renderer'
import { reportStyles } from '../styles'

interface Props {
  label: string
  value?: string
}

export default function LabelValue ({ label, value }: Props) {
  return (
    <View style={reportStyles.stack}>
      <Text style={reportStyles.subtitle}>
        {label}
      </Text>
      <Text style={reportStyles.subtitleValue}>
        {value ?? '--'}
      </Text>
    </View>
  )
}
