import React, { type ReactElement } from 'react'
import {
  ActionIcon,
  Group,
  Paper,
  ScrollArea,
  Stack,
  Title
} from '@mantine/core'
import { IconX } from '@tabler/icons-react'
import { SELECTED_COLOR } from '../shared'
import Inspector from '../Inspector'

interface Props {
  title: string
  expectLongContent?: boolean
  Icon: ReactElement
  children: React.ReactNode
  style?: React.CSSProperties
  close?: () => void
}

function BaseWizard ({
  Icon,
  title,
  close,
  style,
  children,
  expectLongContent
}: Props) {
  return (
    <Paper
      py="xs"
      pl="xs"
      pr={0}
      shadow="xl"
      pos="absolute"
      top={12}
      right={12}
      w={320}
      style={style}
    >
      <Stack spacing="md">
        <Group pr="xs" position="apart">
          <Group spacing="xs">
            {Icon}
            <Title order={4}>{title}</Title>
          </Group>
          {close && (
            <ActionIcon variant="subtle" onClick={close}>
              <IconX size={16} color={SELECTED_COLOR} />
            </ActionIcon>
          )}
        </Group>
        {expectLongContent
          ? (
            <ScrollArea
              offsetScrollbars
              scrollbarSize={8}
              h={300}
            >
              {children}
            </ScrollArea>
            )
          : children}
        <Inspector />
      </Stack>
    </Paper>
  )
}

export default BaseWizard
