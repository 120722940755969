import { useDisclosure } from '@mantine/hooks'
import { type OnSelectionChangeParams, useOnSelectionChange, type Node } from '@xyflow/react'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

interface Props {
  children: JSX.Element
}

interface ContextProps {
  selectedNode?: Node
  bgWizardShow?: boolean
  bgWizardToggle?: () => void
  bgWizardClose?: () => void
}

const WizardContext = createContext<ContextProps>({})

export const WizardProvider = ({ children }: Props) => {
  const [selectedNodes, setSelectedNodes] = useState<Node[]>([])

  const [
    bgWizardShow,
    {
      toggle: bgWizardToggle,
      open: bgWizardOpen,
      close: bgWizardClose
    }
  ] = useDisclosure(false)

  // the passed handler has to be memoized, otherwise the hook will not work correctly
  const onChange = useCallback(({ nodes }: OnSelectionChangeParams) => {
    bgWizardClose()
    setSelectedNodes(nodes)
    if (nodes[0]?.id === 'fixed-background') {
      // check if bg is selected
      bgWizardOpen()
    }
  }, [])

  useOnSelectionChange({
    onChange
  })

  const selectedNode = useMemo(
    () => selectedNodes.length
      ? selectedNodes[0]
      : undefined,
    [selectedNodes]
  )

  useEffect(() => {
    if (bgWizardShow) {
      // clear selected nodes
      setSelectedNodes([])
    }
  }, [bgWizardShow])

  return (
    <WizardContext.Provider value={{
      selectedNode,
      bgWizardShow,
      bgWizardToggle,
      bgWizardClose
    }}
    >
      {children}
    </WizardContext.Provider>
  )
}

export default WizardContext

export const useNode = () => {
  return useContext(WizardContext)
}
