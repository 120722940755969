import { useState } from 'react'
import { useNotifications } from 'src/utils/notifications'
import { getHost } from 'src/utils/host'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import { deleteFile } from '@venturi-io/api/src/config/files'
import { apiErrorData, ApiResError } from '@venturi-io/api'

// Create uploaded image interface
export interface GenericImage {
  id: number
  name: string
  url: string
  previewUrl: string
}

const apiPath = `https://${getHost()}`

function useGenericImage () {
  const { token } = useUser()
  const { showSuccess, showError } = useNotifications()
  const removeFile = useApi(deleteFile)
  const [isUploading, setIsUploading] = useState(false)

  const load = async (previewUrl?: string) => {
    if (!previewUrl) return ''

    const request = {
      method: 'GET',
      headers: {
        'X-Auth-Token': token
      }
    }

    const result = await fetch(`${apiPath}${previewUrl}?t=${new Date().toISOString()}`, request)
    const blob = await result.blob()

    return URL.createObjectURL(blob)
  }

  const upload = async (blobs: File[], onSuccess: (response: GenericImage[]) => void) => {
    setIsUploading(true)
    // used raw fetch termporarily to trigger upload org logo API
    // since it's expecting a form-data payload which is not yet supported on our createAPI
    const form = new FormData()
    blobs.forEach((file) => form.append('files', file))

    const request = {
      method: 'POST',
      headers: {
        'X-Auth-Token': token
      },
      body: form
    }

    const result = await fetch(`${apiPath}/api/config/files?type=IMAGE`, request)
    setIsUploading(false)

    if (result.ok) {
      const data = await result.json() as GenericImage[]
      onSuccess(data)
      showSuccess('Uploaded new image')
    } else {
      // parse response message
      const raw = await result.text()
      const body = JSON.parse(raw)
      const errorData = apiErrorData.safeParse(body)
      showError(new ApiResError(errorData.success
        ? errorData.data.messages[errorData.data.messages.length - 1] ?? 'Something went wrong'
        : 'Something went wrong'))
    }
  }

  const remove = async (id: number) => {
    return await removeFile.fetch({ fileId: id }, token)
  }

  return {
    isUploading,
    load,
    upload,
    remove
  }
}

export default useGenericImage
