import { type NodeProps, useStore } from '@xyflow/react'

import { memo } from 'react'
import { useMantineTheme } from '@mantine/core'
import BaseNode from '../BaseNode'
import { type ShapeNode as ShapeNodeType } from './Shape/types'
import Shape from './Shape'
import NodeLabel from './Label'

// this will return the current dimensions of the node (measured internally by react flow)
function useNodeDimensions (id: string) {
  const node = useStore((state) => state.nodeLookup.get(id))

  return {
    width: node?.measured?.width ?? 0,
    height: node?.measured?.height ?? 0
  }
}

function ShapeNode (props: NodeProps<ShapeNodeType>) {
  const { colors } = useMantineTheme()
  const { id, data } = props
  const { color, type, text } = data

  const { width, height } = useNodeDimensions(id)

  return (
    <BaseNode
      minWidth={20}
      minHeight={20}
      noBg
      noBorder
      noPadding
      allowResize
      {...props}
    >
      <>
        <Shape
          type={type}
          width={width}
          height={height}
          fill={color}
          strokeWidth={1}
          stroke={colors.gray[4]}
          fillOpacity={1}
        />
        <NodeLabel id={id} text={text} />
      </>
    </BaseNode>
  )
}

export default memo(ShapeNode)
