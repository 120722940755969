import React from 'react'
import MapboxDraw, {
  type DrawCreateEvent,
  type DrawModeChangeEvent,
  type DrawUpdateEvent
} from '@mapbox/mapbox-gl-draw'
import { useControl } from 'react-map-gl'
import { FEATURE_FILL_COLOR } from '../style'
import DrawRectangle from './DrawRectangle'
import DrawSquare from './DrawSquare'
import DrawCircle from './DrawCircle'
import type { ControlPosition } from 'react-map-gl'

type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  position?: ControlPosition

  onCreate?: (evt: DrawCreateEvent) => void
  onUpdate?: (evt: DrawUpdateEvent) => void
  onDelete?: (evt: { features: object[] }) => void
  onModeChange?: (evt: DrawModeChangeEvent) => void
}

const DrawControl = React.forwardRef(({
  position,
  onCreate,
  onUpdate,
  onDelete,
  onModeChange,
  ...rest
}: DrawControlProps, ref) => {
  const drawRef = useControl<MapboxDraw>(
    () => new MapboxDraw({
      ...rest,
      modes: {
        draw_rectangle: DrawRectangle,
        draw_square: DrawSquare,
        draw_circle: DrawCircle,
        ...MapboxDraw.modes
      },
      styles: [
        // ACTIVE (being drawn)
        // line stroke
        {
          id: 'gl-draw-line',
          type: 'line',
          filter: [
            'all',
            ['==', '$type', 'LineString'],
            ['!=', 'mode', 'static']
          ],
          layout: {
            'line-cap': 'round',
            'line-join': 'round'
          },
          paint: {
            'line-color': FEATURE_FILL_COLOR,
            'line-dasharray': [0.2, 2],
            'line-width': 3
          }
        },
        // polygon fill
        {
          id: 'gl-draw-polygon-fill',
          type: 'fill',
          filter: [
            'all',
            ['==', '$type', 'Polygon'],
            ['!=', 'mode', 'static']
          ],
          paint: {
            'fill-color': FEATURE_FILL_COLOR,
            'fill-outline-color': FEATURE_FILL_COLOR,
            'fill-opacity': 0.3
          }
        },
        // polygon mid points
        {
          id: 'gl-draw-polygon-midpoint',
          type: 'circle',
          filter: [
            'all',
            ['==', '$type', 'Point'],
            ['==', 'meta', 'midpoint']
          ],
          paint: {
            'circle-radius': 4,
            'circle-color': '#fff'
          }
        },
        // polygon outline stroke
        // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
        {
          id: 'gl-draw-polygon-stroke-active',
          type: 'line',
          filter: [
            'all',
            ['==', '$type', 'Polygon'],
            ['!=', 'mode', 'static']
          ],
          layout: {
            'line-cap': 'round',
            'line-join': 'round'
          },
          paint: {
            'line-color': FEATURE_FILL_COLOR,
            'line-width': 2
          }
        },
        // vertex point halos
        {
          id: 'gl-draw-polygon-and-line-vertex-halo-active',
          type: 'circle',
          filter: [
            'all',
            ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['!=', 'mode', 'static']
          ],
          paint: {
            'circle-radius': 6,
            'circle-color': '#fff'
          }
        },
        // vertex points
        {
          id: 'gl-draw-polygon-and-line-vertex-active',
          type: 'circle',
          filter: [
            'all',
            ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['!=', 'mode', 'static']
          ],
          paint: {
            'circle-radius': 5,
            'circle-color': FEATURE_FILL_COLOR
          }
        }
      ]
    }),
    ({ map }) => {
      map.on('draw.create', e => onCreate?.(e))
      map.on('draw.update', e => onUpdate?.(e))
      map.on('draw.delete', e => onDelete?.(e))
      map.on('draw.modechange', e => onModeChange?.(e))
    },
    ({ map }) => {
      map.off('draw.create', onCreate)
      map.off('draw.update', onUpdate)
      map.off('draw.delete', onDelete)
      map.off('draw.modechange', e => onModeChange?.(e))
    },
    {
      position
    }
  )

  React.useImperativeHandle(ref, () => drawRef, [drawRef]) // This way I exposed drawRef outside the component

  return null
})

export default DrawControl
