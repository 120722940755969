import { useCallback } from 'react'
import {
  addEdge,
  useReactFlow,
  reconnectEdge,
  type OnConnectEnd,
  type OnReconnect,
  type OnEdgesDelete,
  type Edge,
  type HandleType
} from '@xyflow/react'

interface Props {
  takeSnapshot: () => void
}

export function useIncompleteEdge ({ takeSnapshot }: Props) {
  const { setNodes, setEdges, screenToFlowPosition } = useReactFlow()

  const onConnectEnd: OnConnectEnd = useCallback(
    (event, connectionState) => {
      const { fromNode, fromHandle, toNode, toHandle, isValid } = connectionState

      if (
        isValid ??
        fromHandle?.type === 'target'
      ) {
        if (fromNode && toNode && fromHandle && toHandle) {
          // do a simpleStep or a straight??
          const { position: sourceHandle } = fromHandle
          const { position: targetHandle } = toHandle
          const { id: source, type: fromType } = fromNode
          const { id: target, type: toType } = toNode

          const isStraight = fromType === 'lineNode' || toType === 'lineNode'

          return setEdges((eds) => {
            takeSnapshot()
            let connection = {
              source,
              target,
              type: 'floatingEdge',
              // TODO: Should only increase to 5, if the node in question is coming from a child node from a group node
              zIndex: 5,
              style: {
                strokeWidth: 2
              }
            } as Edge

            if (!isStraight) {
              connection = {
                ...connection,
                type: 'smoothstep',
                sourceHandle,
                targetHandle
              }
            }

            return addEdge(connection, eds)
          })
        }

        return
      }

      if (!fromNode) return
      const fromNodeId = fromNode.id
      const id = `ghost-${Date.now()}`
      const { clientX, clientY } =
        'changedTouches' in event ? event.changedTouches[0] : event
      const newNode = {
        id,
        type: 'lineNode',
        position: screenToFlowPosition({
          x: clientX,
          y: clientY
        }),
        data: {}
      }

      const newEdge = {
        id: `${fromNodeId}->${id}`,
        source: fromNodeId,
        type: 'floatingEdge',
        target: id,
        reconnectable: 'target' as HandleType,
        zIndex: 5,
        style: {
          strokeWidth: 2
        }
      } as Edge

      setNodes((nodes) => nodes.concat(newNode))
      setEdges((edges) => {
        takeSnapshot()
        return addEdge(newEdge, edges)
      })
    },
    [setNodes, setEdges, screenToFlowPosition]
  )

  const onReconnect: OnReconnect = useCallback(
    (oldEdge, newConnection) =>
      setEdges((edges) => reconnectEdge(oldEdge, newConnection, edges)),
    [setEdges]
  )

  const onReconnectEnd = useCallback(
    (_: MouseEvent | TouchEvent, oldEdge: Edge, handleType: HandleType) => {
      if (handleType === 'source') {
        setNodes((nodes) => {
          return nodes.filter((node) => {
            const isGhost = node.type === 'ghost'
            const isTarget = node.id === oldEdge.target

            return !(isGhost && isTarget)
          })
        })

        setEdges((edges) => edges.filter((edge) => edge.id !== oldEdge.id))
      }
    },
    [setNodes, setEdges]
  )

  const onEdgesDelete: OnEdgesDelete = useCallback(
    (deletedEdges) => {
      takeSnapshot()
      setNodes((nodes) => {
        return deletedEdges.reduce(
          (acc, edge) =>
            acc.filter((n) => {
              const isGhost = n.type === 'ghost'
              const isSourceOrTarget =
                n.id === edge.source || n.id === edge.target

              return !(isGhost && isSourceOrTarget)
            }),
          nodes
        )
      })
    },
    [setNodes, takeSnapshot]
  )

  return {
    onConnectEnd,
    onReconnect,
    onReconnectEnd,
    onEdgesDelete
  }
}
