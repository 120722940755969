import React, { type MutableRefObject, type RefObject, useEffect, useState } from 'react'
import { type MapRef, Popup as MapPopup } from 'react-map-gl'
import { createStyles, Group, Stack, Text } from '@mantine/core'
import { getBackgroundColor, getWhiteBackgroundColor } from 'src/utils/theme'
import { type Position } from '@turf/turf'
import { IconPolygon } from '@tabler/icons-react'
import { randomId, useClickOutside } from '@mantine/hooks'
import { getGeozonesFromPoint, type Feature } from './shared'
import type MapboxDraw from '@mapbox/mapbox-gl-draw'
import type mapboxgl from 'mapbox-gl'

const useStyles = createStyles((theme) => ({
  popup: {
    zIndex: 2,
    '& .mapboxgl-popup-content': {
      padding: theme.spacing.sm,
      ...getWhiteBackgroundColor(theme)
    },
    '& .mapboxgl-popup-tip': {
      borderRightColor: `${theme.colorScheme === 'light'
        ? theme.colors.white[0]
        : theme.colors.dark[7]} !important`
    }
  },
  item: {
    cursor: 'pointer',
    padding: theme.spacing.xs,
    boxShadow: theme.shadows.xs,
    borderRadius: theme.radius.xs,
    ...getBackgroundColor(theme, -2),
    transition: 'all ease .3s',
    '&:hover': {
      ...getBackgroundColor(theme, -1)
    }
  }
}))

type MouseEvent = mapboxgl.MapMouseEvent & {
  features?: mapboxgl.MapboxGeoJSONFeature[] | undefined
} & mapboxgl.EventData

interface Props {
  mapRef: RefObject<MapRef>
  drawRef: MutableRefObject<MapboxDraw | undefined>
  onSelect: ({ features }: { features: object[] }) => void
}

function GeozonePicker ({
  mapRef,
  drawRef,
  onSelect
}: Props) {
  const { classes } = useStyles()
  const [showPopup, setShowPopup] = useState(false)
  const [center, setCenter] = useState<mapboxgl.LngLat>()
  const [data, setData] = useState<Feature[]>()
  const [wasDrawing, setWasDrawing] = useState(false)
  const ref = useClickOutside(() => setShowPopup(false))

  const map = mapRef.current?.getMap() ?? null
  const draw = drawRef.current ?? null

  const handleMapClick = ({ lngLat }: MouseEvent) => {
    const { lng, lat } = lngLat
    const collection = draw?.getAll()
    const isDrawing = draw?.getMode().includes('draw')

    // ignore first iteration to avoid selecting the overlapping marker on create
    if (isDrawing) {
      return setWasDrawing(true)
    }

    // detects if the last action was a draw mode and there is an output
    if (wasDrawing && draw?.getSelected()) {
      const { features } = draw?.getSelected()
      // select last drawn
      onSelect({ features })

      return setWasDrawing(false)
    }

    // default behavior when clicked on geozone(s)
    if (
      map &&
      collection &&
      !isDrawing
    ) {
      const features = getGeozonesFromPoint({
        point: [lng, lat] as Position,
        features: collection.features as never as Feature[]
      })

      // check if single
      if (features.length > 1) {
        setCenter(lngLat)
        setShowPopup(() => {
          setData(features)

          return true
        })
      } else {
        onSelect({ features })
      }
    }
  }

  const handlePopupClick = (feature: Feature) => () => {
    onSelect({ features: [feature] })
    setShowPopup(false)
  }

  useEffect(
    () => {
      if (map) {
        map.on('click', handleMapClick)
      }

      return () => {
        if (map) {
          map.off('click', handleMapClick)
        }
      }
    },
    [
      map,
      wasDrawing,
      draw,
      onSelect
    ]
  )

  return showPopup
    ? (
      <MapPopup
        closeOnClick
        closeOnMove
        closeButton={false}
        latitude={center?.lat ?? 0}
        longitude={center?.lng ?? 0}
        anchor="left"
        className={classes.popup}
        onClose={() => setShowPopup(false)}
        style={{
          zIndex: 95,
          maxWidth: '400px'
        }}
      >
        <Stack ref={ref} spacing="xs">
          <Text color="dimmed">Select a geozone</Text>
          {data?.map((feature) => {
            // geoZoneId and name could be undefined at the moment of creation
            const {
              properties: {
                data
              }
            } = feature

            return (
              <Group
                key={data?.geoZoneId ?? `temp-id-${randomId()}`}
                className={classes.item}
                spacing="xs"
                onClick={handlePopupClick(feature)}
              >
                <IconPolygon
                  size={16}
                  stroke={1.5}
                  color="#2998F0"
                />
                {data?.name ?? 'Draft (unsaved)'}
              </Group>
            )
          })}
        </Stack>
      </MapPopup>
      )
    : null
}

export default GeozonePicker
