/* eslint-disable @typescript-eslint/naming-convention */
import { View } from '@react-pdf/renderer'
import THCell from 'src/Layout/ReportTemplate/Components/THCell'
import TDCell from 'src/Layout/ReportTemplate/Components/TDCell'
import { reportStyles } from 'src/Layout/ReportTemplate/styles'
import { type ParsedTripItem } from 'src/Reports/Viewer/TripReportView'
import dayjs from 'dayjs'
import { uiDateFormat } from 'src/utils/dates'

interface RowProps {
  data: ParsedTripItem['trips'][0]
}

const Row = ({
  data: {
    start_time,
    end_time,
    driver,
    start_location,
    end_location,
    driving_duration,
    idling_duration,
    distance_travelled,
    speed_avg,
    speed_max,
    x_acceleration_max,
    y_acceleration_max,
    z_acceleration_max
  }
}: RowProps) => (
  <View style={reportStyles.group}>
    <TDCell value={dayjs(start_time).format(uiDateFormat)} />
    <TDCell value={dayjs(end_time).format(uiDateFormat)} />
    <TDCell value={driver} />
    <TDCell value={start_location} />
    <TDCell value={end_location} />
    <TDCell value={driving_duration} />
    <TDCell value={idling_duration} />
    <TDCell value={distance_travelled} />
    <TDCell value={speed_avg} />
    <TDCell value={speed_max} />
    <TDCell value={x_acceleration_max} />
    <TDCell value={y_acceleration_max} />
    <TDCell value={z_acceleration_max} />
  </View>
)

interface Props {
  items: ParsedTripItem['trips']
}

export default function ChildTable ({ items }: Props) {
  return (
    <View>
      <View style={[reportStyles.group]}>
        <THCell value="Start Date/Time" />
        <THCell value="End Date/Time" />
        <THCell value="Driver" />
        <THCell value="Start Location" />
        <THCell value="End Location" />
        <THCell value="Driving Duration" />
        <THCell value="Idling Duration" />
        <THCell value="KMs Travelled" />
        <THCell value="Avg Speed (Kmh)" />
        <THCell value="Max Speed (Kmh)" />
        <THCell value="Max Accel (X)" />
        <THCell value="Max Accel (Y)" />
        <THCell value="Max Accel (Z)" />
      </View>
      {items.map((item) => (
        <Row key={item.trip_id} data={item} />
      ))}
    </View>
  )
}
