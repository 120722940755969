/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useState } from 'react'
import { Box, type SelectItem } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { executeReport, type QueryParams, type Report } from '@venturi-io/api/src/config/report'
import dayjs from 'dayjs'
import { dateFormat, toUtc } from 'src/utils/dates'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import { type Range } from '@venturi-io/api'
import MultiSelect from 'src/Input/MultiSelect'
import MultiSelectAgentGroup from 'src/Input/MultiSelect/MultiSelectAgentGroup'
import { type TimePeriod } from 'src/Buttons/TimePeriodButton'
import uniqBy from 'lodash/uniqBy'
import ReportContext from 'src/Layout/ReportTemplate/ReportContext'
import LogoContext from 'src/Layout/ReportTemplate/LogoContext'
import SkeletonLoader from './SkeletonLoader'
import ReportView from './ReportView'
import TripReportView, {
  type TripItem,
  type ParsedTripItem,
  TripHistoryQueries
} from './TripReportView'
import SpeedingReportView, { type ParsedSpeedEvent, SpeedEventQueries } from './SpeedingReportView'
import HeaderBar from './HeaderBar'
import DuressReportView, { type ParsedDuressEvent, DuressEventQueries } from './DuressReportView'
import IdleReportView, { type ParsedIdleEvent, IdleEventQueries } from './IdleReportView'
import GeozoneReportView, {
  type ParsedGeozoneEvent,
  GeozoneEventQueries
} from './GeozoneReportView'
import ReportParametersAlert from './ReportParametersAlert'
import TankLevelReportView, {
  type SiteTankLevels,
  TankLevelHistoryQueries
} from './TankLevelReportView'

type RawData = Record<string, Array<Record<string, string | null>>>

export interface ReportRawData {
  data: RawData
  fromTime?: string
  toTime?: string
}

export type ReportFileType = 'CSV' | 'PDF'

export const getQueryIdByName = (queries: Report['reportType']['reportQueries'], queryName: string) => (
  queries.find(({ name }) => name === queryName)?.id ?? -1
)

const totalHorizontalPadding = 24

interface Props {
  embeddedReportData?: ReportRawData
  embeddedReport?: Report
}

export default function Viewer ({ embeddedReportData, embeddedReport }: Props) {
  const { ref, width: maxWidth } = useElementSize()
  const generatedReport = useApi(executeReport)
  const { token } = useUser()
  const [report, setReport] = useState<Report>()
  const reportData = embeddedReportData ?? generatedReport.data.mapOrDefault(data => data, { data: {} })
  const reportQueries = report?.reportType.reportQueries ?? []
  const [tripReportData, setTripReportData] = useState<ParsedTripItem[]>([])
  const [duressReportData, setDuressReportData] = useState<ParsedDuressEvent[]>([])
  const [speedReportData, setSpeedReportData] = useState<ParsedSpeedEvent[]>([])
  const [idleReportData, setIdleReportData] = useState<ParsedIdleEvent[]>([])
  const [geozoneReportData, setGeozoneReportData] = useState<ParsedGeozoneEvent[]>([])
  const [tankLevelReportData, setTankLevelReportData] = useState<SiteTankLevels[]>([])
  const [summaryQueryId, setSummaryQueryId] = useState(-1)
  const [selectedAgentIds, setSelectedAgentIds] = useState<string[]>([])
  const [selectedAgentGroupIds, setSelectedAgentGroupIds] = useState<string[]>([])
  const [timePeriodValue, setTimePeriodValue] = useState<TimePeriod['value'] | null>('24h')
  const [dateRange, setDateRange] = useState<Range | null>(
    embeddedReportData?.fromTime && embeddedReportData?.toTime
      ? {
          from: embeddedReportData.fromTime,
          to: embeddedReportData.toTime
        }
      : {
          from: dayjs()
            .subtract(24, 'hour')
            .format(`${dateFormat}Z`),
          to: dayjs()
            .format(`${dateFormat}Z`)
        }
  )

  const getReportParams = (range: Range | null): QueryParams => {
    if (!report) {
      return {}
    }

    const { reportType: { name } } = report
    const rangeParams = {
      fromTime: toUtc(range?.from ?? dayjs().subtract(24, 'hour')) + 'Z',
      toTime: toUtc(range?.to ?? dayjs()) + 'Z'
    }

    switch (name) {
      case 'Trip History': {
        const tripHistorySummaryQueryId = getQueryIdByName(
          reportQueries,
          TripHistoryQueries.summary
        )
        const tripHistoryAllQueryId = getQueryIdByName(
          reportQueries,
          TripHistoryQueries.allTrips
        )

        setSummaryQueryId(tripHistorySummaryQueryId)

        return {
          [tripHistorySummaryQueryId]: rangeParams,
          [tripHistoryAllQueryId]: rangeParams
        }
      }
      case 'Tank Level History Report': {
        const tankLevelHistoryListQueryId = getQueryIdByName(
          reportQueries,
          TankLevelHistoryQueries.list
        )
        const tankLevelHistoryGraphQueryId = getQueryIdByName(
          reportQueries,
          TankLevelHistoryQueries.graph
        )

        return {
          [tankLevelHistoryListQueryId]: rangeParams,
          [tankLevelHistoryGraphQueryId]: {
            ...rangeParams,
            timeBucket: '1 day'
          }
        }
      }
      case 'Speed Event Report': {
        const speedEventSummaryQueryId = getQueryIdByName(
          reportQueries,
          SpeedEventQueries.summary
        )
        const speedEventAllQueryId = getQueryIdByName(
          reportQueries,
          SpeedEventQueries.allEvents
        )

        setSummaryQueryId(speedEventSummaryQueryId)

        return {
          [speedEventSummaryQueryId]: rangeParams,
          [speedEventAllQueryId]: rangeParams
        }
      }
      case 'Duress Event Report': {
        const duressEventSummaryQueryId = getQueryIdByName(
          reportQueries,
          DuressEventQueries.summary
        )

        setSummaryQueryId(duressEventSummaryQueryId)

        return {
          [duressEventSummaryQueryId]: rangeParams
        }
      }
      case 'Geozone Event Report': {
        const geozoneEventSummaryQueryId = getQueryIdByName(
          reportQueries,
          GeozoneEventQueries.summary
        )

        setSummaryQueryId(geozoneEventSummaryQueryId)

        return {
          [geozoneEventSummaryQueryId]: rangeParams
        }
      }
      case 'Idle Time Report': {
        const idleEventSummaryQueryId = getQueryIdByName(
          reportQueries,
          IdleEventQueries.summary
        )

        setSummaryQueryId(idleEventSummaryQueryId)

        return {
          [idleEventSummaryQueryId]: rangeParams
        }
      }
      default:
        return {}
    }
  }

  const handleExecute = (range: Range | null, timePeriod: TimePeriod['value'] | null) => {
    if (report) {
      const params = getReportParams(range)
      setTimePeriodValue(timePeriod)
      setDateRange(range)
      void generatedReport.fetch({
        reportId: report.id,
        queryParams: params
      }, token)
    }
  }

  const renderReportViewFromType = ({ data: rawData }: ReportRawData) => {
    if (!report) return null

    const { reportType: { name } } = report
    const commonProps = {
      report,
      data: rawData,
      queryId: summaryQueryId,
      timePeriod: timePeriodValue,
      range: dateRange,
      maxWidth: maxWidth - totalHorizontalPadding,
      selectedAgentIds,
      selectedAgentGroupIds
    }

    switch (name) {
      case 'Trip History':
        return (
          <TripReportView
            {...commonProps}
            setTripReportData={setTripReportData}
          />
        )
      case 'Duress Event Report':
        return (
          <DuressReportView
            {...commonProps}
            setDuressData={setDuressReportData}
          />
        )
      case 'Idle Time Report':
        return (
          <IdleReportView
            {...commonProps}
            setIdleData={setIdleReportData}
          />
        )
      case 'Speed Event Report':
        return (
          <SpeedingReportView
            {...commonProps}
            setSpeedEventReportData={setSpeedReportData}
          />
        )
      case 'Geozone Event Report':
        return (
          <GeozoneReportView
            {...commonProps}
            setGeozoneReportData={setGeozoneReportData}
          />
        )
      case 'Tank Level History Report':
        return (
          <TankLevelReportView
            {...commonProps}
            setTankLevelReportData={setTankLevelReportData}
          />
        )
      default:
        return <ReportView data={rawData} />
    }
  }

  useEffect(() => {
    // reset selected agent Ids
    setSelectedAgentIds([])
    setSelectedAgentGroupIds([])
  }, [report?.reportType.id])

  useEffect(() => {
    setReport(embeddedReport)
  }, [embeddedReport])

  useEffect(() => {
    if (typeof report !== 'undefined' && typeof embeddedReport !== 'undefined') {
      // This will ensure that the query ids for embedded report will be configured properly
      getReportParams(null)
    }
  }, [report])

  // TODO: Revisit once all report templates are in place
  const rowData = reportData.data[`${summaryQueryId}`] as unknown as TripItem[] ?? []

  const agents: SelectItem[] = rowData.map(({ agent_name, agent_id }: TripItem) => ({
    label: agent_name,
    value: agent_id
  }))

  return (
    <ReportContext>
      <LogoContext>
        <HeaderBar
          report={report}
          timePeriod={timePeriodValue}
          dateRange={dateRange}
          reportData={reportData.data}
          tripReportData={tripReportData}
          duressReportData={duressReportData}
          speedReportData={speedReportData}
          idleReportData={idleReportData}
          geozoneReportData={geozoneReportData}
          tankLevelReportData={tankLevelReportData}
          fromEmbed={embeddedReport !== undefined}
          busy={generatedReport.loading}
          queryId={summaryQueryId}
          setReport={(newReport) => {
            // reset once the report was changed
            if (!embeddedReport) { // only allow clears on viewer and not on generated
              void generatedReport.clearAll()
              setReport(newReport)
            }
          }}
          onGenerate={handleExecute}
          extensions={(
            report?.reportType.name === 'Trip History' ||
            report?.reportType.name === 'Duress Event Report' ||
            report?.reportType.name === 'Idle Time Report' ||
            report?.reportType.name === 'Geozone Event Report' ||
            report?.reportType.name === 'Speed Event Report' ||
            report?.reportType.name === 'Tank Level History Report'
          ) && (
            <>
              <MultiSelect
                height={40}
                searchable
                value={selectedAgentIds}
                data={uniqBy(agents, 'value')}
                placeholder="Select assets"
                onChange={e => setSelectedAgentIds(e)}
              />
              <MultiSelectAgentGroup
                value={selectedAgentGroupIds}
                placeholder="Select asset groups"
                onChange={e => setSelectedAgentGroupIds(e)}
                searchable
              />
            </>
          )}
        />
        {typeof report === 'undefined'
          ? <ReportParametersAlert />
          : (
            <Box ref={ref} mt="sm">
              {generatedReport.loading && <SkeletonLoader />}
              {!generatedReport.loading && generatedReport.data.mapOrDefault((rawData) => (
                renderReportViewFromType(rawData)
              ), null)}
            </Box>
            )}
        {/* Load from generated report */}
        {embeddedReportData && (
          <Box ref={ref} mt="sm">
            {renderReportViewFromType(embeddedReportData)}
          </Box>
        )}
      </LogoContext>
    </ReportContext>
  )
}
