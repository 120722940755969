import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import {
  Box,
  Button,
  Overlay,
  Stack,
  Switch,
  Tabs,
  Text,
  useMantineTheme
} from '@mantine/core'
import { getAgentsForSite } from '@venturi-io/api/src/config/agent'
import { getSitesWithAlarms } from '@venturi-io/api/src/config/site'
import { useUser } from 'src/UserContext'
import { useMeta } from 'src/MetaContext'
import { useApi, usePaginatedApi } from 'src/utils/useApi'
import Pagination from 'src/Layout/Pagination'
import Incidents from 'src/Incidents'
import AutoGrid from 'src/Layout/AutoGrid'
import AgentCell from 'src/Home/Agents/AgentCell'
import Loader from 'src/Layout/Loader'
import Paper from 'src/Layout/Paper'
import { IconBinaryTree2, IconComponents, IconFileUnknown, IconPlus } from '@tabler/icons-react'
import SiteDetail from 'src/Admin/SiteDetail'
import Link from 'src/Layout/Link'
import { getTextColorAsProp, getWhiteBackgroundColor } from 'src/utils/theme'
import NeedsRole from 'src/NeedsRole'
import { adminRoles } from 'src/utils/role'

interface RouteParams extends Record<string, string | undefined> {
  siteId: string
}

export default function Details () {
  const theme = useMantineTheme()
  const { siteId: id } = useParams<RouteParams>()
  const sites = useApi(getSitesWithAlarms)
  const agents = usePaginatedApi(getAgentsForSite)
  const [activeTab, setActiveTab] = useState<string | null>(null)
  const [hasLayout, setHasLayout] = useState<boolean>(false)

  const [small, setSmall] = useState(true)
  const [isFirst, setIsFirst] = useState(true)

  const { token, orgId } = useUser()
  const { meta, setMeta } = useMeta()

  if (!id) {
    return null
  }

  const siteId = parseInt(id)

  const loadAgents = (page?: number, size?: number) => {
    const req = {
      siteId,
      page: page ?? agents.page,
      size: size ?? agents.size
    }

    void agents
      .fetch(req, token)
      .finally(() => {
        agents.stopPolling()
        agents.startPolling(req, token, 60)
        setIsFirst(false)
      })

    return () => {
      agents.stopPolling()
      agents.abort()
    }
  }

  useEffect(() => {
    void sites.fetch({
      orgId,
      page: 1,
      size: 999
    }, token)
    loadAgents()
  }, [agents.page, id])

  useEffect(() => {
    if (!isFirst) return

    agents.data.ifJust(({ items }) => {
      if (items.length > 6) setSmall(true)
    })
  }, [agents.data])

  const Nothing = () => agents.loading
    ? <Loader first />
    : <Loader first><Text>No agents retreived for this organisation</Text></Loader>

  useEffect(() => {
    sites
      .data
      .ifJust(({ items }) => {
        const site = items.find(site => site.siteId === siteId)

        if (site) {
          setMeta({
            ...meta,
            pageTitle: site.name
          })
        }
      })
  }, [sites.data])

  return (
    <>
      {agents.data.caseOf({
        Nothing,
        Just: data =>
          (
            <Tabs
              value={activeTab}
              mt="lg"
              onTabChange={setActiveTab}
              color="primary"
            >
              <Tabs.List>
                <Tabs.Tab value="siteView" icon={<IconBinaryTree2 size="0.8rem" />}>Site View</Tabs.Tab>
                <Tabs.Tab value="allAgents" icon={<IconComponents size="0.8rem" />}>All Agents</Tabs.Tab>
              </Tabs.List>
              <Tabs.Panel value="siteView">
                <Box h="80vh" w="100%" pos="relative">
                  <SiteDetail
                    viewOnly
                    onFetchLayout={(hasDetails) => {
                      setHasLayout(hasDetails)
                      setActiveTab(hasDetails
                        ? 'siteView'
                        : 'allAgents')
                    }}
                  />
                  {!hasLayout && (
                    <Overlay
                      color={getWhiteBackgroundColor(theme).backgroundColor}
                      opacity={0.55}
                      center
                    >
                      <Stack align="center" spacing="xs">
                        <IconFileUnknown size={32} {...getTextColorAsProp()} opacity={0.75} />
                        <Text {...getTextColorAsProp()}>No site layout available</Text>
                        <NeedsRole role={adminRoles}>
                          <Button
                            color="primary"
                            variant="outline"
                            leftIcon={<IconPlus size={16} />}
                            component={Link}
                            to={`/settings/sites/flow/${siteId}`}
                          >
                            Create site layout
                          </Button>
                        </NeedsRole>
                      </Stack>
                    </Overlay>
                  )}
                </Box>
              </Tabs.Panel>
              <Tabs.Panel value="allAgents">
                <Stack my="sm">
                  <Switch
                    label="Compact View"
                    color="primary"
                    size="sm"
                    checked={small}
                    onChange={ev => setSmall(ev.currentTarget.checked)}
                  />
                  <AutoGrid>
                    {data
                      .items
                      .sort((a, b) => a.agentName.localeCompare(b.agentName))
                      .map(agent => (
                        <AgentCell
                          key={agent.agentId}
                          agent={agent}
                          isSmall={small}
                        />
                      ))}
                  </AutoGrid>
                  <Pagination
                    mt="sm"
                    value={agents.page}
                    total={data.totalPages}
                    onChange={agents.setPage}
                  />
                </Stack>
              </Tabs.Panel>
            </Tabs>
          )
      })}

      <Paper relative>
        {agents.loading
          ? <Nothing />
          : <Incidents siteId={siteId} />}
      </Paper>
    </>
  )
}
