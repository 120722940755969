/* eslint-disable @typescript-eslint/naming-convention */
import { Stack } from '@mantine/core'
import { type Props as ListProps } from '..'
import Card from './Card'

export default function Cards ({ items }: ListProps) {
  return (
    <Stack my={12} spacing={12}>
      {items.map((data) => (
        <Card key={data.agent_id} item={data} />
      ))}
    </Stack>
  )
}
