import { type ProgressProps, Box, Progress, Text } from '@mantine/core'

interface Props {
  value: ProgressProps['value']
  label?: string
}

export default function ProgressLoader ({
  value,
  label = 'Loading...'
}: Props) {
  return (
    <Box>
      <Text
        size={14}
        weight={400}
        align="center"
      >
        {label}
      </Text>
      <Progress
        mt={8}
        color="primary"
        size="lg"
        value={value}
        animate
      />
    </Box>
  )
}
