import { createRoot } from 'react-dom/client'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import duration from 'dayjs/plugin/duration'

import App from './App'
import { MetaProvider } from './MetaContext'
import ErrorBoundary from './ErrorBoundary'
import reportWebVitals from './reportWebVitals'
import 'mapbox-gl/dist/mapbox-gl.css'
import './index.css'
import { ZendeskProvider } from './ZendeskContext'

import 'react-virtualized/styles.css'

library.add(fas, fal, far)

dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration)

// Dirty hack to clear localStorage keys
if (window.location.search.substring(1) === 'clear') {
  window.localStorage.clear()
  window.location.assign(location.protocol + '//' + location.host + location.pathname)
}

const rootElement = document.getElementById('root')

// Added to support useBlocker in Site Flow Editor
const router = createBrowserRouter(createRoutesFromElements(<Route path="*" element={<App />} />))

if (rootElement) {
  const root = createRoot(rootElement)
  root.render(
    <ErrorBoundary>
      <MetaProvider>
        <ZendeskProvider apiKey="d38240d6-9921-43dd-9760-246866cbc88c">
          <RouterProvider router={router} />
        </ZendeskProvider>
      </MetaProvider>
    </ErrorBoundary>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
