import { memo, useCallback } from 'react'
import {
  type Node,
  type NodeProps,
  useReactFlow,
  useStore,
  useStoreApi
} from '@xyflow/react'
import { useMantineTheme } from '@mantine/core'
import { getRelativeNodesBounds } from '../../utils'
import BaseNode from '../BaseNode'
import NodeToolbar from '../../FlowControls/NodeToolbar'
import useDetachNodes from './useDetachNodes'
interface Group extends Record<string, string | boolean | undefined> {
  hovered?: boolean
}

export type GroupNodeType = Node<Group, 'groupNode'>

function GroupNode (props: NodeProps<GroupNodeType>) {
  const { id } = props
  const store = useStoreApi()
  const { colors, colorScheme } = useMantineTheme()
  const isLight = colorScheme === 'light'
  const { deleteElements } = useReactFlow()

  const detachNodes = useDetachNodes()
  const { minWidth, minHeight, hasChildNodes } = useStore((store) => {
    const childNodes = Array.from(store.nodeLookup.values()).filter(
      ({ parentId }) => parentId === id
    )
    const {
      x,
      y,
      width,
      height
    } = getRelativeNodesBounds(childNodes)

    return {
      minWidth: x + width,
      minHeight: y + height,
      hasChildNodes: childNodes.length > 0
    }
  }, isEqual)

  const onDelete = useCallback(
    async () => await deleteElements({ nodes: [{ id }] }),
    [id]
  )

  const onDetach = useCallback(
    () => {
      const childNodeIds = Array.from(store.getState().nodeLookup.values())
        .filter(({ parentId }) => parentId === id)
        .map(({ id }) => id)

      detachNodes(childNodeIds, id)
    },
    [id, store]
  )

  return (
    <BaseNode
      isDashed
      isCustomDrag
      noPadding
      allowResize
      shouldOverrideToolbar
      minHeight={minHeight}
      minWidth={minWidth}
      borderWidth={2}
      borderColor={isLight
        ? colors.dark[2]
        : colors.gray[4]}
      {...props}
    >
      <NodeToolbar
        onDelete={onDelete}
        onUngroup={hasChildNodes
          ? onDetach
          : undefined}
        className="nodrag"
      />
    </BaseNode>
  )
}

interface IsEqualCompareObj {
  minWidth: number
  minHeight: number
  hasChildNodes: boolean
}

function isEqual (prev: IsEqualCompareObj, next: IsEqualCompareObj): boolean {
  return (
    prev.minWidth === next.minWidth &&
    prev.minHeight === next.minHeight &&
    prev.hasChildNodes === next.hasChildNodes
  )
}

export default memo(GroupNode)
