/* eslint-disable @typescript-eslint/naming-convention */
import { type AgentTripItem } from 'src/Reports/Viewer/TripReportView'
import ViewTripButton from 'src/Reports/Viewer/ViewTripButton'
import dayjs from 'dayjs'
import { uiDateFormat } from 'src/utils/dates'

interface Props {
  data: AgentTripItem
}

export default function Row ({
  data: {
    agent_id,
    trip_id,
    start_time,
    end_time,
    driver,
    start_location,
    end_location,
    driving_duration,
    idling_duration,
    distance_travelled,
    speed_avg,
    speed_max,
    x_acceleration_max,
    y_acceleration_max,
    z_acceleration_max
  }
}: Props) {
  return (
    <tr key={trip_id}>
      <td>{dayjs(start_time).format(uiDateFormat)}</td>
      <td>{dayjs(end_time).format(uiDateFormat)}</td>
      <td>{driver}</td>
      <td>{start_location}</td>
      <td>{end_location}</td>
      <td>{driving_duration}</td>
      <td>{idling_duration}</td>
      <td>{distance_travelled}</td>
      <td>{speed_avg}</td>
      <td>{speed_max}</td>
      <td>{x_acceleration_max}</td>
      <td>{y_acceleration_max}</td>
      <td>{z_acceleration_max}</td>
      <td>
        <ViewTripButton
          agentId={agent_id}
          tripId={trip_id}
          startTime={start_time}
        />
      </td>
    </tr>
  )
}
