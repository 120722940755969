import React, { useState } from 'react'
import {
  createStyles,
  Group,
  Popover,
  Stack,
  Text,
  UnstyledButton,
  useMantineTheme
} from '@mantine/core'
import { NodeToolbar } from '@xyflow/react'
import { getTextColorAsProp } from 'src/utils/theme'
import { SELECTED_COLOR } from '../shared'

interface StyleProps {
  selected: boolean
}

const useStyles = createStyles((theme, { selected }: StyleProps) => ({
  button: {
    width: 28,
    height: 28,
    position: 'relative',
    borderRadius: theme.radius.sm,
    border: `3px solid ${theme.colors.gray[1]}`,
    '&::after': {
      content: "''",
      position: 'absolute',
      top: -2,
      left: -2,
      right: -2,
      bottom: -2,
      borderRadius: theme.radius.sm,
      border: `${selected
        ? '3px'
        : '0px'} solid ${SELECTED_COLOR}`
    }
  }
}))

interface Props {
  selected?: boolean
  activeColor?: string
  onChange: (color: string) => void
}

interface ButtonProps extends Omit<Props, 'selected'> {
  color: string
}

function ColorButton ({ onChange, color, activeColor }: ButtonProps) {
  const selected = color === activeColor
  const { classes } = useStyles({ selected })

  return (
    <UnstyledButton
      className={classes.button}
      onClick={() => onChange(color)}
      sx={{
        backgroundColor: color
      }}
    />
  )
}

function ColorToolbar (props: Props) {
  const { activeColor, selected } = props
  const { colors, radius } = useMantineTheme()
  const defaultColor = getTextColorAsProp().color
  const [show, setShow] = useState(false)

  return (
    <NodeToolbar isVisible={selected} align="start">
      <Popover
        closeOnClickOutside
        width={200}
        position="top-start"
        withArrow
        shadow="md"
      >
        <Popover.Target>
          <UnstyledButton
            onClick={() => setShow(!show)}
            sx={{
              width: 22,
              height: 22,
              borderRadius: radius.sm,
              backgroundColor: activeColor ?? getTextColorAsProp().color
            }}
          />
        </Popover.Target>
        <Popover.Dropdown>
          <Stack spacing="xs">
            <Text size="xs">Pick a color</Text>
            <Group spacing={6}>
              <ColorButton activeColor={activeColor} color={defaultColor as string} {...props} />
              <ColorButton activeColor={activeColor} color={colors.white[0]} {...props} />
              <ColorButton activeColor={activeColor} color={colors.red[7]} {...props} />
              <ColorButton activeColor={activeColor} color={colors.green[5]} {...props} />
              <ColorButton activeColor={activeColor} color={colors.blue[6]} {...props} />
              <ColorButton activeColor={activeColor} color={colors.yellow[5]} {...props} />
              <ColorButton activeColor={activeColor} color={colors.violet[5]} {...props} />
              <ColorButton activeColor={activeColor} color={colors.orange[5]} {...props} />
              <ColorButton activeColor={activeColor} color={colors.pink[5]} {...props} />
              <ColorButton activeColor={activeColor} color={colors.indigo[5]} {...props} />
            </Group>
          </Stack>
        </Popover.Dropdown>
      </Popover>
    </NodeToolbar>
  )
}

export default ColorToolbar
