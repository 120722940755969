import React, { type DragEvent, type MouseEventHandler } from 'react'
import { type Icon, type IconProps } from '@tabler/icons-react'
import { ActionIcon, type MantineSize, Tooltip, useMantineTheme } from '@mantine/core'
import { mq } from 'src/utils/style'
import { useMediaQuery } from '@mantine/hooks'
import { useDnD } from '../DnDContext'
import { type NodeType } from '../shared'
import { buttonStyles } from '../sharedStyles'
import { type ShapeType } from '../Nodes/ShapeNode/Shape/types'

interface ToolIconProps extends Omit<IconProps, 'onClick'> {
  disabled?: boolean
  isGroup?: boolean
  tooltip: string
  selected?: boolean
  type?: NodeType
  shapeType?: ShapeType
  Icon: Icon
  buttonSize?: MantineSize
  draggable?: boolean
  loading?: boolean
  onClick?: MouseEventHandler<HTMLDivElement>
}

function ToolIcon ({
  Icon,
  type,
  isGroup,
  shapeType,
  tooltip,
  disabled,
  loading,
  draggable = true,
  selected = false,
  buttonSize = 'sm',
  onClick,
  ...rest
}: ToolIconProps) {
  const { breakpoints } = useMantineTheme()
  const isDesktop = useMediaQuery(mq(breakpoints.sm, false))
  const { classes } = buttonStyles({ isSelected: selected, isDesktop })
  const { setType } = useDnD()

  const onDragStart = (event: DragEvent<HTMLDivElement>, nodeType?: NodeType) => {
    if (nodeType) {
      setType(nodeType)
      if (shapeType) {
        event.dataTransfer.setData('application/reactflow', shapeType)
      }
      if (isGroup) {
        event.dataTransfer.setData('application/reactflow', 'groupNode')
      }
      event.dataTransfer.effectAllowed = 'move'
    }
  }

  return (
    <Tooltip withArrow label={tooltip}>
      <ActionIcon
        size={buttonSize}
        loading={loading}
        component="div"
        draggable
        variant="subtle"
        disabled={disabled}
        className={classes.iconWithSelectedState}
        onDragStart={draggable
          ? (e) => onDragStart(e, type)
          : undefined}
        onClick={onClick}
        onDragEnd={() => setType(null)}
      >
        <Icon {...rest} />
      </ActionIcon>
    </Tooltip>
  )
}

export default ToolIcon
