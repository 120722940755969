import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useParams } from 'react-router'
import {
  createStyles,
  Affix,
  Box,
  Button,
  Group,
  MediaQuery,
  Stack
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  type AgentDetails,
  getAgentDetails,
  updateAgentSensorInstances
} from '@venturi-io/api/src/config/agent'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { useMeta } from 'src/MetaContext'
import { mq } from 'src/utils/style'
import NeedsRole from 'src/NeedsRole'
import NotFound from 'src/Router/NotFound'
import LoadingOverlay from 'src/Layout/LoadingOverlay'
import * as _ from 'lodash'
import Sensor from './Sensor'
import Create from './Create'

const useStyles = createStyles((theme) => ({
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    width: '100%',
    height: '90%',
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    overflow: 'auto',
    [mq(theme.breakpoints.sm)]: {
      height: '94%'
    },
    [mq(theme.breakpoints.md)]: {
      height: '90%'
    }
  },
  header: {
    height: 'auto',
    marginTop: 12
  },
  list: {
    flex: 1,
    margin: 12,
    padding: 4,
    overflow: 'auto',
    scrollbarWidth: 'thin'
  },
  footer: {
    display: 'flex',
    height: 'auto',
    padding: '4px 16px',
    justifyContent: 'center',
    [mq(theme.breakpoints.sm)]: {
      justifyContent: 'flex-end'
    }
  },
  createBtn: {
    boxShadow: theme.shadows.md,
    backgroundColor: '#FFFFFFCC',
    padding: theme.spacing.sm,
    borderRadius: theme.radius.md,
    '&:hover': {
      backgroundColor: '#fff'
    }
  }
}))

interface RouteParams extends Record<string, string | undefined> {
  agentId: string
}

export interface SensorInstance {
  sensorId: number
  sensorInstanceId: number
  primaryFunction: boolean
  systemOnly: boolean
  name: string
  iconStyle: string | undefined
  sensorDataTransformerId?: number
}

export default function Sensors () {
  const { classes } = useStyles()
  const { search } = useLocation()
  const agentDetails = useApi(getAgentDetails)
  const updateAgentSensors = useApi(updateAgentSensorInstances)
  const [showNew, setShowNew] = useState(false)
  const { token } = useUser()
  const { meta, setMeta } = useMeta()
  const { agentId: id } = useParams<RouteParams>()
  const [isTemplate, setIsTemplate] = useState(false)
  const [allSensors, setAllSensors] = useState<AgentDetails['sensors']>([])
  const [agentSensor, setAgentSensor] = useState<SensorInstance>()
  const [sensorInstances, setSensorInstances] = useState<SensorInstance[]>([])
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const isLoading = agentDetails.loading || updateAgentSensors.loading

  if (typeof id === 'undefined' || isNaN(parseInt(id))) {
    return <NotFound />
  }

  const agentId = parseInt(id)

  const agent = useMemo(() => (
    agentDetails
      .data
      .mapOrDefault(agent_ => {
        setMeta({
          ...meta,
          pageTitle: agent_.agentName
        })

        return agent_
      }, null)
  ), [agentDetails.data])

  const disabledUpdateButton = isTemplate ||
    isLoading ||
    agent === null ||
    sensorInstances.length === 0

  const loadAgent = () => {
    void agentDetails
      .fetch({ agentId }, token)
      .finally(() => {
        if (isInitialLoad) {
          setIsInitialLoad(false)
        }
      })
  }

  useEffect(() => {
    if (search) {
      const params = new URLSearchParams(search)
      const template = params.get('isTemplate')
      if (template) {
        setIsTemplate(template === 'true')
      }
    }
  }, [search])

  useEffect(() => {
    agentDetails.data.ifJust(({ sensors }) => {
      setAllSensors(sensors)
    })
  }, [agentDetails.data])

  useEffect(() => {
    void loadAgent()
  }, [])

  const hide = { display: 'none' }

  const newButton = (
    <Button
      title="Create Sensor"
      size="xs"
      color="primary"
      leftIcon={<FontAwesomeIcon icon={['fas', 'plus']} color="white" />}
      onClick={() => setShowNew(true)}
      disabled={isLoading}
    >
      Create Sensor
    </Button>
  )

  useEffect(() => {
    if (agentSensor) {
      const sensors = sensorInstances ?? []
      const allSensors = [
        ...sensors,
        agentSensor
      ]
      const removeDuplicates = _.uniqBy(_.reverse(allSensors), 'sensorInstanceId')

      setSensorInstances(removeDuplicates)
    }
  }, [agentSensor])

  const updateSensor = useCallback(() => {
    if (agent !== null && sensorInstances.length > 0) {
      const { agentId } = agent

      void updateAgentSensors
        .fetch({
          agentId,
          sensorInstances
        }, token, 'Successfully updated sensors')
        .finally(() => {
          setSensorInstances([])
          setAgentSensor(undefined)
          void loadAgent()
        })
    }
  }, [agent, sensorInstances])

  useEffect(() => {
    setSensorInstances([])

    return () => {
      setSensorInstances([])
    }
  }, [])

  return (
    <>
      <LoadingOverlay visible={isInitialLoad && agentDetails.loading} />
      <Box className={classes.container}>
        {!showNew && (
          <NeedsRole role="ROLE_SUPERADMIN">
            <MediaQuery smallerThan="sm" styles={hide}>
              <Box className={classes.header}>
                <Group align="center" position="center">
                  {newButton}
                </Group>
              </Box>
            </MediaQuery>
            <MediaQuery largerThan="sm" styles={hide}>
              <Affix
                position={{
                  right: 10,
                  top: 110
                }}
              >
                <Box className={classes.createBtn}>
                  {newButton}
                </Box>
              </Affix>
            </MediaQuery>
          </NeedsRole>
        )}
        <Stack className={classes.list} spacing={12}>
          {allSensors.map((sensor, index) => (
            <Sensor
              key={index}
              sensor={sensor}
              agentLoading={agentDetails.loading}
              loadAgent={loadAgent}
              isTemplate={isTemplate}
              setAgentSensor={setAgentSensor}
            />
          ))}
        </Stack>
        <Box className={classes.footer}>
          {allSensors.length > 0 && (
            <NeedsRole role="ROLE_SUPERADMIN">
              <Button
                color="primary"
                leftIcon={(
                  <FontAwesomeIcon
                    icon={['fas', 'arrows-rotate']}
                    color="primary"
                  />
                )}
                onClick={() => {
                  updateSensor()
                }}
                disabled={disabledUpdateButton}
                variant="outline"
              >
                Update Sensors
              </Button>
            </NeedsRole>
          )}
        </Box>
        <NeedsRole role="ROLE_SUPERADMIN">
          <Create
            agentId={agentId}
            show={showNew}
            close={() => setShowNew(false)}
            onCreate={loadAgent}
          />
        </NeedsRole>
      </Box>
    </>
  )
}
