import { useMantineTheme, Box, Divider } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { mq } from 'src/utils/style'
import EmptyPlaceholder from 'src/Reports/Viewer/EmptyPlaceholder'
import { type SiteTankLevels } from '../..'
import Table from './Table'
import Cards from './Cards'

export interface ItemProps {
  data: SiteTankLevels['list'][0]
}

export interface Props {
  items: SiteTankLevels['list']
}

export default function List ({ items }: Props) {
  const theme = useMantineTheme()
  const isDesktop = useMediaQuery(mq(theme.breakpoints.sm, false))

  return (
    <Box>
      {isDesktop
        ? <Table items={items} />
        : <Cards items={items} />}
      {items.length === 0 && <EmptyPlaceholder />}
      {isDesktop && <Divider mt={0} mb={4} variant="solid" />}
    </Box>
  )
}
