import React, { memo, useEffect, useState } from 'react'
import { createStyles, Image } from '@mantine/core'
import { useReactFlow, type Node, type NodeProps } from '@xyflow/react'
import ImageDropzone from '../Components/ImageDropzone'
import { type CustomizationProps } from '../shared'
import useGenericImage, { type GenericImage } from '../hooks/useGenericImage'
import BaseNode from './BaseNode'

const useStyles = createStyles(() => ({
  maxWidthAndHeight: {
    pointerEvents: 'none',
    width: '100%',
    height: '100% !important'
  }
}))

export interface ImgData extends Record<string, string | number | GenericImage | undefined>, CustomizationProps {
  image?: GenericImage
}

export type ImageNodeType = Node<ImgData, 'imageNode'>

function ImageNode (props: NodeProps<ImageNodeType>) {
  const { id, data } = props
  const { opacity, image } = data

  const { updateNodeData } = useReactFlow()
  const { upload, load, remove } = useGenericImage()
  const { classes } = useStyles()
  const [imageUrl, setImageUrl] = useState<string>()

  const onChangeImage = async (file?: File) => {
    if (file) {
      // check if there was an existing image, then we remove it
      if (image?.id) {
        void remove(image.id)
      }

      void upload([file], (response) => {
        updateNodeData(id, { image: response[0] })
      })
    }
  }

  const loadImage = async () => {
    if (image) {
      const url = await load(image.previewUrl)
      setImageUrl(url)
    }
  }

  const handleDelete = async () => {
    if (image?.id) {
      return await remove(image.id)
    }
  }

  useEffect(() => {
    if (image) {
      void loadImage()
    }
  }, [image])

  return (
    <BaseNode
      noBg
      noPadding
      allowResize
      onDelete={handleDelete}
      {...props}
    >
      {imageUrl
        ? (
          <Image
            src={imageUrl}
            fit="contain"
            classNames={{
              root: classes.maxWidthAndHeight,
              figure: classes.maxWidthAndHeight,
              imageWrapper: classes.maxWidthAndHeight,
              image: classes.maxWidthAndHeight
            }}
            sx={{
              opacity
            }}
          />
          )
        : (
          <ImageDropzone
            setImage={onChangeImage}
          />
          )}
    </BaseNode>
  )
}

export default memo(ImageNode)
