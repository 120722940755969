/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useEffect, useState } from 'react'
import { type Report, type ReportData } from '@venturi-io/api/src/config/report'
import { type Range } from '@venturi-io/api'
import dayjs from 'dayjs'
import { uiDateFormatUniversal } from 'src/utils/dates'
import { reverseGeocode } from 'src/Maps/GeoZoneMap/mapbox'
import { convertStrToFloat } from 'src/AssetTemplate/shared'
import { type CSVData } from 'src/utils/files'
import { getQueryIdByName } from '..'
import Content from './Content'

export enum SpeedEventQueries {
  summary = 'speed_event_report_summary',
  allEvents = 'speed_event_report_all_events'
}

export const transformSpeedEventDataToCSV = (items: ParsedSpeedEvent[]): CSVData => {
  const withSummary = items.length > 1

  const emptySpeedEventSummary = {
    Assets: '',
    Date: '',
    Violations: '',
    Driver: ''
  }

  const emptySpeedEventDetails = {
    'Event #': '',
    'Event Date/Time': '',
    Location: '',
    Geozone: '',
    'Speed Limit': '',
    'Speed Violation': ''
  }

  const summary = withSummary
    ? [
        ...items.map(({
          agent_name,
          trip_start_time,
          total_speed_alerts,
          driver
        }) => ({
          Assets: agent_name,
          Date: dayjs(trip_start_time).format(uiDateFormatUniversal),
          Violations: total_speed_alerts,
          Driver: driver,
          ...emptySpeedEventDetails
        })),
        // these empty objects serve as empty rows before all events
        {},
        {},
        {}
      ]
    : []

  const allEvents = items.reduce((data: CSVData, {
    agent_name,
    trip_start_time,
    total_speed_alerts,
    driver,
    speed_alerts
  }) => {
    const eventSummary = {
      Assets: agent_name,
      Date: dayjs(trip_start_time).format(uiDateFormatUniversal),
      Violations: total_speed_alerts,
      Driver: driver
    }

    const transformedData: CSVData = speed_alerts.map(({
      speed_date_time,
      location,
      geo_zone_name,
      speed_limit,
      speed
    }, index) => {
      const eventDetails = {
        'Event #': index + 1,
        'Event Date/Time': dayjs(speed_date_time).format(uiDateFormatUniversal),
        Location: location,
        Geozone: geo_zone_name,
        'Speed Limit': speed_limit,
        'Speed Violation': speed
      }

      return index === 0
        ? {
            ...eventSummary,
            ...eventDetails
          }
        : {
            ...emptySpeedEventSummary,
            ...eventDetails
          }
    })

    return [
      ...data,
      ...transformedData,
      {} // this serves as an empty row after each asset
    ]
  }, [])

  return [
    ...summary,
    ...allEvents
  ]
}

export interface SpeedAlert {
  id: string
  trip_id: string
  speed_limit: string
  speed: string // speed violation
  speed_date_time: string
  max_speed: string
  geo_zone_id: string
  geo_zone_name: string
  longitude: string
  latitude: string
  location: string
}

export interface SpeedEvent {
  trip_id: string
  trip_start_time: string
  trip_end_time: string
  agent_id: string
  agent_name: string
  agent_groups_ids: string
  user_groups_names: string
  org_user_id: string
  org_user_first_name: string
  org_user_last_name: string
  driver: string
  total_speed_alerts: string
  speed_alerts: SpeedAlert[]
}

export interface ParsedSpeedEvent extends Omit<SpeedEvent, 'agent_groups_ids'> {
  map_url?: string | null
}

export interface Props {
  report: Report
  data: ReportData
  queryId: number
  range: Range | null
  setSpeedEventReportData: (data: ParsedSpeedEvent[]) => void
  selectedAgentIds: string[]
  selectedAgentGroupIds: string[]
}

export default function SpeedingReportView ({
  report,
  data,
  range,
  setSpeedEventReportData,
  selectedAgentIds,
  selectedAgentGroupIds
}: Props) {
  const { reportQueries } = report.reportType
  const agentGroupIds = selectedAgentGroupIds.join(',') ?? ''
  const speedEventSummaryQueryId = getQueryIdByName(reportQueries, SpeedEventQueries.summary)
  const speedEventAllQueryId = getQueryIdByName(reportQueries, SpeedEventQueries.allEvents)
  const listItems = data[speedEventSummaryQueryId] as unknown as SpeedEvent[] ?? []
  const speedAlerts = data[speedEventAllQueryId] as unknown as SpeedAlert[] ?? []
  const [parsedSpeedEventsData, setParsedSpeedEventsData] = useState<ParsedSpeedEvent[]>([])
  const [isLoading, setIsLoading] = useState(false)

  // Transform and fetch necessary speed alerts data for specific trip
  const getSpeedAlerts = useCallback(async (trip_id: SpeedEvent['trip_id']) => {
    const tripSpeedAlerts: SpeedAlert[] = speedAlerts
      .filter((alert) => alert.trip_id === trip_id)
    // Sort by speed_date_time in ASC order (oldest to newest)
      .sort((a, b) => (
        a.speed_date_time > b.speed_date_time
          ? 1
          : -1
      ))

    return await Promise.all(tripSpeedAlerts.map(async ({
      longitude,
      latitude,
      geo_zone_name,
      speed_limit,
      speed,
      ...rest
    }) => {
      const location = await reverseGeocode([
        convertStrToFloat(longitude),
        convertStrToFloat(latitude)
      ])

      return {
        ...rest,
        longitude,
        latitude,
        location,
        geo_zone_name: geo_zone_name ?? 'N/A',
        speed_limit: `${speed_limit} km/h`,
        speed: `${speed} km/h`
      }
    }))
  }, [speedAlerts])

  const load = useCallback(async () => {
    setIsLoading(true)

    // Filter items by selected Agents first to reduce the number of
    // items to be transformed
    const filteredDataByAgents = selectedAgentIds.length > 0
      ? listItems.filter(({ agent_id }) => selectedAgentIds.includes(agent_id))
      : listItems

    // Parse the values of necessary fields including agent_groups_ids
    const parsedItems = await Promise.all(filteredDataByAgents.map(async ({
      trip_id,
      org_user_first_name,
      org_user_last_name,
      agent_groups_ids,
      ...rest
    }) => ({
      ...rest,
      trip_id,
      org_user_first_name,
      org_user_last_name,
      driver: org_user_first_name && org_user_last_name
        ? `${org_user_first_name} ${org_user_last_name}`
        : 'N/A',
      agent_groups_ids: agent_groups_ids
        ? agent_groups_ids.split(',')
        : [],
      speed_alerts: await getSpeedAlerts(trip_id)
    })))

    // Filter items by selected Agent Groups based on parsed
    // agent_groups_ids to reduce the number of items
    const filteredDataByGroups = agentGroupIds.length > 0
      ? parsedItems.filter(({ agent_groups_ids }) => (
        agent_groups_ids.some(id => agentGroupIds.includes(id))
      ))
      : parsedItems

    // Sort by trip_start_time in DESC order
    const sortedItems = filteredDataByGroups.sort((a, b) => (
      a.trip_start_time < b.trip_start_time
        ? 1
        : -1
    ))

    setParsedSpeedEventsData(sortedItems)
    setSpeedEventReportData(sortedItems)
    setIsLoading(false)
  }, [listItems, selectedAgentIds, agentGroupIds])

  useEffect(() => {
    try {
      void load()
    } catch (err: any) {
      throw Error('Failed to generate report')
    }
  }, [
    listItems,
    selectedAgentIds,
    agentGroupIds,
    range
  ])

  return <Content data={parsedSpeedEventsData} isLoading={isLoading} />
}
