import { useCallback, useEffect, useState } from 'react'
import {
  useMantineTheme,
  Button,
  Col,
  Divider,
  Grid,
  Group,
  Paper,
  Stack,
  Text,
  Radio
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useApi, usePaginatedApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { useNotifications } from 'src/utils/notifications'
import NeedsRole from 'src/NeedsRole'
import Pagination from 'src/Layout/Pagination'
import TextInput from 'src/Input/TextInput'
import Loader from 'src/Layout/Loader'
import MultiSelectAlarmAction from 'src/Input/MultiSelect/MultiSelectAlarmAction'
import ActionList from 'src/Layout/ActionList'
import ConfirmModal from 'src/Layout/ConfirmModal'
import { createAlarm, getAlarmsForSensorInstance } from '@venturi-io/api/src/analytics/alarm'
import { getAlarmActions } from '@venturi-io/api/src/analytics/alarmAction'
import {
  deleteSensorInstance,
  assignAlarmActionsToSensorInstance,
  removeAlarmActionsFromSensorInstance
} from '@venturi-io/api/src/config/sensorInstance'
import IconSelector from 'src/Input/IconSelector'
import { mq } from 'src/utils/style'
import { arraysEqual } from 'src/utils/arrays'
import SelectScaleFactor from 'src/Input/Select/SelectScaleFactor'
import SelectSensor from 'src/Input/Select/SelectSensor'
import { checkIfHasRole } from 'src/utils/role'
import { type SensorInstance } from '..'
import AlarmRow from './AlarmRow'
import AlarmRowHeader from './AlarmRowHeader'
import type { Alarm as AlarmInput } from './AlarmRow'
import type { Sensor as AgentSensor } from '@venturi-io/api/src/config/agent'

interface Props {
  sensor: AgentSensor
  agentLoading: boolean
  loadAgent: () => void
  isTemplate: boolean
  setAgentSensor: (value: SensorInstance | undefined) => void
}

export default function Sensor ({
  sensor,
  agentLoading,
  isTemplate,
  setAgentSensor,
  loadAgent
}: Props) {
  const theme = useMantineTheme()
  const isDesktop = useMediaQuery(mq(theme.breakpoints.sm, false))
  const { token, orgId } = useUser()
  const removeSensorInstance = useApi(deleteSensorInstance)
  const assignAlarmActions = useApi(assignAlarmActionsToSensorInstance)
  const removeAlarmActions = useApi(removeAlarmActionsFromSensorInstance)
  const getAlarms = usePaginatedApi(getAlarmsForSensorInstance)
  const getActions = usePaginatedApi(getAlarmActions)
  const addAlarm = useApi(createAlarm)
  const [sensorId, setSensorId] = useState(sensor.id)
  const [name, setName] = useState(sensor.name)
  const [sensorDataTransformerId, setSensorDataTransformerId] = useState(sensor.sensorDataTransformerId)
  const [primaryOrSystem, setPrimaryOrSystem] = useState('')
  const [lastPrimaryOrSystem, setLastPrimaryOrSystem] = useState(primaryOrSystem)
  const [currentAlarmActionIds, setCurrentAlarmActionIds] = useState<number[]>([])
  const [newAlarmActionIds, setNewAlarmActionIds] = useState<number[]>([])
  const [iconStyle, setIconStyle] = useState(sensor.iconStyle)
  const [dirty, setDirty] = useState(false)
  const [checkFields, setCheckFields] = useState(false)
  const [newAlarm, setNewAlarm] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const { showError } = useNotifications()
  const alarms = getAlarms.data.mapOrDefault(({ items }) => items, [])
  const isSuperAdmin = checkIfHasRole('ROLE_SUPERADMIN')

  const loadAlarms = async (page?: number): Promise<void> => {
    void getAlarms
      .fetch({
        sensorInstanceId: sensor.sensorInstanceId,
        page: page ?? getAlarms.page,
        size: 10
      }, token)
  }

  const loadAlarmActions = async (): Promise<void> => {
    void getActions
      .fetch({
        orgId,
        sensorInstanceId: sensor.sensorInstanceId,
        page: 1,
        size: 999999
      }, token)
  }

  const actions = {
    onSave: async () => {
      if (!isTemplate) {
        void loadAlarms(getAlarms.page)
        void loadAlarmActions()
      }
    }
  }

  const updateAlarmActions = useCallback((currentIds: string[], updatedIds: string[]) => {
    const newIds = updatedIds.filter(id => !currentIds.includes(id))
    const removedIds = currentIds.filter(id => !updatedIds.includes(id))

    if (newIds.length) {
      void assignAlarmActions
        .fetch({
          sensorInstanceId: sensor.sensorInstanceId,
          alarmActionIds: newIds.map(id => Number(id))
        }, token, 'Alarm Actions has been successfully updated')
        .finally(() => {
          void loadAgent()
        })
    }

    if (removedIds.length) {
      void removeAlarmActions
        .fetch({
          sensorInstanceId: sensor.sensorInstanceId,
          alarmActionIds: removedIds.map(id => Number(id))
        }, token)
        .finally(() => {
          void loadAgent()
        })
    }
  }, [sensor.sensorInstanceId])

  useEffect(() => {
    if (!isTemplate) {
      void loadAlarms()
    }
  }, [getAlarms.page])

  useEffect(() => {
    setDirty(
      !arraysEqual(currentAlarmActionIds, newAlarmActionIds)
    )
  }, [
    currentAlarmActionIds,
    newAlarmActionIds
  ])

  useEffect(() => {
    let type = ''

    if (!sensor.primaryFunction && !sensor.systemOnly) {
      type = 'standard'
    } else {
      type = sensor.primaryFunction
        ? 'primary'
        : 'system'
    }

    setPrimaryOrSystem(type)
    setLastPrimaryOrSystem(type)
  }, [sensor])

  const deleteSensor = useCallback(() => {
    void removeSensorInstance
      .fetch({ sensorInstanceId: sensor.sensorInstanceId }, token)
      .finally(() => {
        setShowDelete(false)
        void loadAgent()
      })
  }, [sensor.sensorInstanceId])

  const saveNewAlarm = useCallback(async (input: AlarmInput, sensorInstanceId: number) => {
    if (!input.name) {
      return showError(new Error('Invalid alarm details'))
    }

    await addAlarm
      .fetch({
        ...input,
        description: input.description ?? '',
        sensorInstanceId
      }, token, 'Alarm Action added successfully')
      .finally(() => setNewAlarm(false))
  }, [])

  useEffect(() => {
    getActions.data.ifJust(({ items }) => {
      const alarmActionIds = items.map(({ id }) => id)
      setCurrentAlarmActionIds(alarmActionIds)
      setNewAlarmActionIds(alarmActionIds)
    })
  }, [getActions.data])

  useEffect(() => {
    if (!isTemplate) {
      void loadAlarmActions()
    }
  }, [])

  useEffect(() => {
    if (checkFields) {
      setAgentSensor({
        sensorId,
        name,
        sensorInstanceId: sensor.sensorInstanceId,
        sensorDataTransformerId,
        iconStyle: iconStyle !== ''
          ? iconStyle
          : undefined,
        primaryFunction: primaryOrSystem === 'standard'
          ? false
          : primaryOrSystem === 'primary',
        systemOnly: primaryOrSystem === 'standard'
          ? false
          : primaryOrSystem === 'system'
      })
    }
  }, [
    sensorId,
    name,
    iconStyle,
    primaryOrSystem,
    checkFields,
    sensorDataTransformerId
  ])

  useEffect(() => {
    setCheckFields(
      sensorId !== sensor.id ||
      name !== sensor.name ||
      iconStyle !== sensor.iconStyle ||
      primaryOrSystem !== lastPrimaryOrSystem ||
      sensorDataTransformerId !== sensor.sensorDataTransformerId
    )
  }, [
    sensorId,
    name,
    iconStyle,
    primaryOrSystem,
    sensorDataTransformerId,
    sensor.id,
    sensor.name,
    sensor.iconStyle,
    sensor.sensorDataTransformerId,
    lastPrimaryOrSystem
  ])

  return (
    <Paper p="md" shadow="xs">
      <Grid grow>
        <Col span={12} sm={6}>
          <SelectSensor
            label="Sensor"
            defaultValue={sensorId.toString()}
            onChange={value => setSensorId(Number(value))}
            withAsterisk={isSuperAdmin}
            searchable
            disabled={!isSuperAdmin}
          />
        </Col>
        <Col span={12} sm={6}>
          <TextInput label="Sensor ID" value={sensorId} disabled />
        </Col>
        <Col span={12} sm={6}>
          <TextInput
            label="ID"
            value={sensor.sensorInstanceId}
            disabled
          />
        </Col>
        <Col
          span={12}
          sm={3}
          sx={{
            position: 'relative'
          }}
        >
          <IconSelector
            label="Icon"
            width={isDesktop ? 330 : '95%'}
            value={iconStyle}
            onChange={value => setIconStyle(value)}
          />
        </Col>
        <Col span={12} sm={3}>
          <Group position="center" align="center">
            <TextInput
              style={{
                flex: 1
              }}
              label="Name"
              value={name}
              onChange={item => setName(item.target.value)}
              withAsterisk
              disabled={agentLoading}
            />
          </Group>
        </Col>
        <Col span={12} sm={6}>
          <TextInput
            label="Value"
            value={sensor.currentValue}
            disabled
          />
        </Col>
        <Col span={12} sm={3}>
          <TextInput
            label="Alarm Status"
            value={sensor.alarmStatus}
            disabled
          />
        </Col>
        <Col span={12} sm={3}>
          <SelectScaleFactor
            label="Scale Factor"
            value={sensorDataTransformerId?.toString()}
            onChange={(id) => setSensorDataTransformerId(id
              ? Number(id)
              : undefined
            )}
            clearable
            searchable
            disabled={agentLoading}
          />
        </Col>
        <Col span={12} sm={6}>
          <MultiSelectAlarmAction
            label="Alarm Actions"
            value={newAlarmActionIds.map(id => id.toString())}
            onChange={ids => {
              setNewAlarmActionIds(ids.map(id => Number(id)))
            }}
            searchable
            clearable
          />
        </Col>
        <Col span={12} sm={6}>
          <Stack spacing={8}>
            <Text size="xs" mt={5}>Type</Text>
            <Radio.Group
              withAsterisk
              value={primaryOrSystem}
              onChange={setPrimaryOrSystem}
            >
              <Group>
                <Radio
                  color="primary"
                  value="standard"
                  label="Standard"
                />
                <Radio
                  color="primary"
                  value="primary"
                  label="Primary function"
                />
                <Radio
                  color="primary"
                  value="system"
                  label="System only"
                />
              </Group>
            </Radio.Group>
          </Stack>
        </Col>
        <Col span={12}>
          <Divider variant="dashed" my={'sm'} />
          <Group position="right">
            {!isTemplate && dirty && (
              <NeedsRole role="ROLE_SUPERADMIN">
                <Button
                  color="primary"
                  leftIcon={<FontAwesomeIcon icon={['fas', 'floppy-disk']} color="primary" />}
                  onClick={() => {
                    updateAlarmActions(
                      currentAlarmActionIds.map(id => id.toString()),
                      newAlarmActionIds.map(id => id.toString())
                    )
                  }}
                  disabled={isTemplate}
                  variant="outline"
                >
                  Update Alarm Actions
                </Button>
              </NeedsRole>
            )}
            <NeedsRole role="ROLE_SUPERADMIN">
              <Button
                color="red"
                leftIcon={<FontAwesomeIcon icon={['fas', 'trash']} color="red" />}
                onClick={() => setShowDelete(true)}
                disabled={isTemplate}
                variant="outline"
              >
                Delete Sensor
              </Button>
            </NeedsRole>
          </Group>
        </Col>
        <Col span={12}>
          <Divider variant="dashed" />
        </Col>
        <Col span={12} mt={-20}>
          <ActionList
            isLoading={false}
            data={alarms}
            actions={actions}
            extra={{ sensorInstanceId: sensor.sensorInstanceId }}
            getId={({ alarmId }) => alarmId}
            row={AlarmRow}
            card={AlarmRow}
            header={() => <AlarmRowHeader />}
            totalPages={0}
            noDataMessage={!getAlarms.loading && alarms.length < 1 && !newAlarm
              ? 'No alarms configured for this sensor'
              : null}
            hideShadow
          />
          {getAlarms.loading && <Loader size="sm" />}
          {newAlarm && (
            <AlarmRow
              actions={actions}
              extra={{ sensorInstanceId: sensor.sensorInstanceId }}
              alarmId={-1}
              name=""
              description=""
              operator="="
              enabled={false}
              setPoint={0}
              manualClear={false}
              quietMode={false}
              taskCreationEnabled={false}
              alarmType="WARNING"
              odd={alarms.length + 1 % 2 === 0}
              isNew
              isAdding={addAlarm.loading}
              overrideSave={saveNewAlarm}
              overrideCancel={() => setNewAlarm(false)}
            />
          )}
          <Pagination
            mt="sm"
            value={getAlarms.page}
            total={getAlarms.data.mapOrDefault(({ totalPages }) => totalPages, 0)}
            onChange={getAlarms.setPage}
          />
          {!newAlarm && (
            <Group position="right">
              <Button
                mt={10}
                color="primary"
                leftIcon={<FontAwesomeIcon icon={['fas', 'bell']} color="primary" />}
                onClick={() => setNewAlarm(true)}
                disabled={isTemplate}
                variant="outline"
              >
                Create Alarm
              </Button>
            </Group>
          )}
        </Col>
      </Grid>
      <NeedsRole role="ROLE_SUPERADMIN">
        <ConfirmModal
          type="delete"
          title="Are you sure?"
          question="Do you really want to delete this sensor? This action cannot be undone."
          opened={showDelete}
          onClose={() => setShowDelete(false)}
          onCancel={() => setShowDelete(false)}
          onConfirm={deleteSensor}
        />
      </NeedsRole>
    </Paper>
  )
}
