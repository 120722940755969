import React, {
  type MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { ActionIcon, Box, Paper, Tooltip } from '@mantine/core'
import {
  type Node,
  type NodeChange,
  type OnNodesChange,
  type ReactFlowInstance,
  type Edge,
  type XYPosition,
  useNodesState,
  useEdgesState,
  useReactFlow,
  useConnection,
  applyNodeChanges,
  Controls,
  ReactFlow,
  ReactFlowProvider,
  SelectionMode,
  ConnectionLineType,
  type OnNodeDrag,
  type SelectionDragHandler,
  type OnNodesDelete,
  type OnEdgesChange
} from '@xyflow/react'
import { adminRoles } from 'src/utils/role'
import Link from 'src/Layout/Link'
import { IconPencil } from '@tabler/icons-react'
import { useParams } from 'react-router'
import { useBeforeUnload } from 'react-router-dom'
import { useApi } from 'src/utils/useApi'
import { getSiteFlow, saveSiteFlow } from '@venturi-io/api/src/userManager/site'
import { useUser } from 'src/UserContext'
import { useNotifications } from 'src/utils/notifications'
import NeedsRole from 'src/NeedsRole'
import { DnDProvider, useDnD } from './DnDContext'
import { edgeTypes, nodeTypes } from './shared'
import SensorWizard from './Wizard/SensorWizard'
import ImageWizard from './Wizard/ImageWizard'
import AgentWizard from './Wizard/AgentWizard'
import { WizardProvider } from './Wizard/WizardContext'
import Toolbar from './Toolbar'
import BackgroundWizard from './Wizard/BackgroundWizard'
import HelperLines from './Components/HelperLines'
import NodeEdgeInspector from './Inspector/NodeEdgeInspector'
import {
  cleanData,
  getHelperLines,
  getId,
  getNodePositionInsideParent,
  getOrganizedNodes
} from './utils'
import { type ShapeType } from './Nodes/ShapeNode/Shape/types'
import TopBar from './Toolbar/TopBar'
import ContextMenu, { type MenuPosition } from './ContextMenu'
import useUndoRedo from './hooks/useUndoRedo'
import useViewOnly from './hooks/useViewOnly'
import { NodeProvider } from './Nodes/NodeContext'
import '@xyflow/react/dist/style.css'
import './index.css'
import { Prompt } from './hooks/usePrompt'
import { fileVersion } from './constants'
import CrossLines from './Components/CrossLines'
import { CopyPasteProvider } from './hooks/useCopyPaste'
import { useIncompleteEdge } from './hooks/useIncompleteEdge'
import SiteWizard from './Wizard/SiteWizard'

interface RouteParams extends Record<string, string | undefined> {
  siteId: string
}

interface Props {
  viewOnly?: boolean
  onFetchLayout?: (hasDetails: boolean) => void
}

function Flow ({ viewOnly, onFetchLayout }: Props) {
  const { siteId } = useParams<RouteParams>()
  const reactFlowWrapper = useRef(null)
  const ref = useRef<HTMLDivElement>(null)
  const { token } = useUser()
  const saveFlow = useApi(saveSiteFlow)
  const { inProgress } = useConnection()
  const { showError } = useNotifications()
  const viewOnlyProps = useViewOnly(viewOnly)

  const {
    screenToFlowPosition,
    getIntersectingNodes,
    fitView
  } = useReactFlow()
  const { type, cursorMode } = useDnD()
  const {
    undo,
    redo,
    canUndo,
    canRedo,
    takeSnapshot
  } = useUndoRedo()
  const [nodes, setNodes] = useNodesState<Node>([])
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([])
  const [rfInstance, setRfInstance] = useState<ReactFlowInstance<Node, Edge> | null>(null)
  const [helperLineHorizontal, setHelperLineHorizontal] = useState<number | undefined>(undefined)
  const [helperLineVertical, setHelperLineVertical] = useState<number | undefined>(undefined)
  const [position, setPosition] = useState<MenuPosition | null>(null)
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const lineHandlers = useIncompleteEdge({ takeSnapshot })

  const beforeUnload = useCallback((e: BeforeUnloadEvent) => {
    e.preventDefault()
    const message = 'There are unsaved changes, are you sure you want to leave?'
    e.returnValue = message

    return message
  }, [])

  // handles refresh only
  useBeforeUnload(beforeUnload, { capture: isDirty })

  const handleSaveSiteFlow = useCallback(async () => {
    if (rfInstance && siteId) {
      const flow = rfInstance.toObject()
      await saveFlow.fetch({
        siteId: Number(siteId),
        data: {
          ...flow,
          fileVersion
        }
      }, token, 'Successfully saved site flow')
      setIsDirty(false)
    }
  }, [rfInstance, siteId])

  const restore = (flow?: Record<string, any>) => {
    if (flow) {
      // Add's cross hair node
      setNodes(flow.nodes || [])
      setEdges(flow.edges || [])

      setTimeout(() => {
        void fitView({
          nodes: flow.nodes || [],
          duration: 1000
        })
      }, 500)
    }
  }

  // Gets the groupNode index from all nodes, and adds existing child nodes count to identify
  // resulting index for the new node
  const getResultingNodeIndex = (groupNodeId: string) => {
    const node = nodes.find(({ id }) => id === groupNodeId)
    // get existing child nodes count and add it in the index
    const childNodes = nodes.filter(({ parentId }) => parentId === groupNodeId)

    // add 1 to ensure that the new node get's on top of their sibling node
    return node
      ? nodes.indexOf(node) + childNodes.length + 1
      : -1
  }

  const getGroupNode = (position: XYPosition) => {
    const intersections = getIntersectingNodes({
      x: position.x,
      y: position.y,
      width: 40,
      height: 40
    }).filter((n) => n.type === 'groupNode')
    const groupNode = intersections[0]

    if (groupNode) {
      // if we drop a node on a group node, we want to position the node inside the group
      const extraParams: Pick<Node, 'position' | 'parentId' | 'expandParent' | 'zIndex'> = {
        position: getNodePositionInsideParent(
          {
            position,
            width: 40,
            height: 40
          },
          groupNode
        ) ?? { x: 0, y: 0 },
        zIndex: groupNode.zIndex,
        parentId: groupNode?.id,
        expandParent: true
      }

      return extraParams
    }

    return undefined
  }

  const finalizeNode = (node: Node, parentId?: string) => {
    if (parentId) {
      // Another known bug that occurs whenever a NEW node was added to a group, it doesn't respect the nodes order
      // and will always be placed on top.
      // we will use getResultingNodeIndex to get the correct index where we will place the new node
      const organizedNodes = [...nodes]
      const resultingIndex = getResultingNodeIndex(parentId)
      organizedNodes.splice(resultingIndex, 0, node)
      setNodes(organizedNodes)
    } else {
      // put node on top of all
      setNodes((nds) => nds.concat(node))
    }
  }

  const createNode = (type: string, { clientX, clientY }: React.DragEvent<HTMLDivElement>) => {
    // project was renamed to screenToFlowPosition
    // and you don't need to subtract the reactFlowBounds.left/top anymore
    // details: https://reactflow.dev/whats-new/2023-11-10
    const position = screenToFlowPosition({
      x: clientX,
      y: clientY
    })
    const extraParams = getGroupNode(position)
    const newNode: Node = {
      type,
      position,
      id: getId(),
      selected: true,
      zIndex: 4,
      data: { label: `${type} node`, opacity: 1 },
      ...extraParams
    }
    finalizeNode(newNode, extraParams?.parentId)
  }

  const createShapeNode = (shapeType: ShapeType, { clientX, clientY }: React.DragEvent<HTMLDivElement>) => {
    const position = screenToFlowPosition({
      x: clientX,
      y: clientY
    })
    const extraParams = getGroupNode(position)
    const newShapeNode: Node = {
      position,
      zIndex: 4,
      id: getId(),
      selected: true,
      type: 'shapeNode',
      style: {
        width: 100,
        height: 100
      },
      data: {
        color: '#fff',
        type: shapeType
      },
      ...extraParams
    }
    finalizeNode(newShapeNode, extraParams?.parentId)
  }

  const createGroupNode = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()

    const type = event.dataTransfer.getData('application/reactflow')
    const position = screenToFlowPosition({
      x: event.clientX - 20,
      y: event.clientY - 20
    })

    const newNode: Node = {
      id: getId(),
      type,
      position,
      selected: true,
      dragHandle: '.custom-drag-handle',
      data: { label: `${type}` },
      zIndex: 4,
      width: 400,
      height: 200
    }

    // we need to make sure that the parents are sorted before the children
    // to make sure that the children are rendered on top of the parents
    setNodes(nds => nds.concat(newNode))

    // the issue with 'sortNodes' above is that the group and it's child gets separated as multiple nodes get added in
    // We need to ensure that the order would be [grpA, childA1, childA2, grpB, childB1, childB2]
    // This will make it easier to manage when we control their z-index position (or the position in the nodes array)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    if (inProgress) return
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }, [inProgress])

  const onDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      if (inProgress) return
      event.preventDefault()

      // check if the dropped element is valid
      if (!type) {
        return
      }

      // make undoable
      takeSnapshot()

      // deselect all first
      setNodes(nodes => nodes.map(item => ({ ...item, dragging: false, selected: false })))

      // check if shapeType is available
      const upcomingType = event.dataTransfer.getData('application/reactflow') as string | undefined
      if (upcomingType && (upcomingType === 'circle' || upcomingType === 'rectangle')) {
        // shape nodes
        return createShapeNode(upcomingType, event)
      }

      if (upcomingType === 'groupNode') {
        return createGroupNode(event)
      }

      // normal nodes
      return createNode(type, event)
    },
    [
      screenToFlowPosition,
      type,
      takeSnapshot,
      nodes,
      setNodes
    ]
  )

  const handleDeleteAll = useCallback(() => {
    setNodes([])
    setEdges([])
  }, [])

  const customApplyNodeChanges = useCallback(
    (changes: NodeChange[], nodes: Node[]): Node[] => {
      // reset the helper lines (clear existing lines, if any)
      setHelperLineHorizontal(undefined)
      setHelperLineVertical(undefined)

      // this will be true if it's a single node being dragged
      // inside we calculate the helper lines and snap position for the position where the node is being moved to
      if (
        changes.length === 1 &&
        changes[0].type === 'position' &&
        changes[0].dragging &&
        changes[0].position
      ) {
        const helperLines = getHelperLines(changes[0], nodes)

        // if we have a helper line, we snap the node to the helper line position
        // this is being done by manipulating the node position inside the change object
        changes[0].position.x =
          helperLines.snapPosition.x ?? changes[0].position.x
        changes[0].position.y =
          helperLines.snapPosition.y ?? changes[0].position.y

        // if helper lines are returned, we set them so that they can be displayed
        setHelperLineHorizontal(helperLines.horizontal)
        setHelperLineVertical(helperLines.vertical)
      }

      return applyNodeChanges(changes, nodes)
    },
    []
  )

  const onNodesChange: OnNodesChange = useCallback(
    (changes) => {
      if (isLoaded) {
        setIsDirty(true)
      }
      setNodes((nodes) => customApplyNodeChanges(changes, nodes))
    },
    [
      isLoaded,
      setIsDirty,
      setNodes,
      customApplyNodeChanges
    ]
  )

  const handleEdgeChange: OnEdgesChange = useCallback(
    (changes) => {
      if (isLoaded) {
        setIsDirty(true)
      }
      onEdgesChange(changes)
    },
    [isLoaded, setIsDirty, onEdgesChange]
  )

  const onNodeDragStop = useCallback(
    (_: MouseEvent, node: Node) => {
      const { type, parentId } = node
      if (type === 'groupNode' && !parentId) {
        return
      }

      if (parentId) {
        // has already existing parent node
        return
      }

      const groupIntersections = getIntersectingNodes(node).filter(
        ({ type: nodeType }) => nodeType === 'groupNode'
      )

      const groupNode = groupIntersections[0]

      // check all other nodes that intersects and currently doesn't have the groupNode as parent Id
      if (groupNode) {
        const otherNodes = getIntersectingNodes(groupNode).filter((n) => n.type !== 'groupNode')
        const intersectingNodeIds: string[] = otherNodes.map(({ id }) => id)

        // modify child node placement in group node to respect group node index in nodes
        const nextNodes: Node[] = nodes.map(n => {
          if (n.id === groupNode.id) {
            return {
              ...n,
              dragging: false,
              className: ''
            }
          }
          if (intersectingNodeIds.includes(n.id) && n.parentId !== groupNode.id) {
            const position = getNodePositionInsideParent(n, groupNode) ?? {
              x: 0,
              y: 0
            }

            return {
              ...n,
              position,
              zIndex: groupNode.zIndex,
              dragging: false,
              parentId: groupNode.id,
              extent: 'parent'
            } as Node
          }

          return {
            ...n,
            dragging: false
          }
        })

        // reorganize group and child here
        const organizedNodes = getOrganizedNodes(nextNodes)
        setNodes(organizedNodes)
      }
    },
    [getIntersectingNodes, nodes, setNodes]
  )

  const onNodeDrag = useCallback(
    (_: MouseEvent, node: Node) => {
      const { type, parentId } = node
      if (position) {
        setPosition(null)
      }

      if (type === 'groupNode' && !parentId) {
        return
      }

      if (parentId) {
        // has already existing parent node
        return
      }

      const intersections = getIntersectingNodes(node).filter(
        ({ type: nodeType }) => nodeType === 'groupNode'
      )
      const groupClassName =
        intersections.length && node.parentId !== intersections[0]?.id
          ? 'active'
          : ''

      setNodes((nds) => {
        return nds.map((n) => {
          if (n.type === 'groupNode' && intersections[0]?.id === n.id) {
            return {
              ...n,
              className: groupClassName
            }
          } else if (n.id === node.id) {
            return {
              ...n,
              position: node.position
            }
          }

          return { ...n, className: '' }
        })
      })
    },
    [getIntersectingNodes, setNodes, position]
  )

  const onContextMenu = useCallback(
    (event: MouseEvent) => {
      if (!ref.current) return
      // Prevent native context menu from showing
      event.preventDefault()
      // Calculate position of the context menu. We want to make sure it
      // doesn't get positioned off-screen.
      const barHeight = 64
      const barWidth = 64
      const pane = ref.current.getBoundingClientRect()
      const paneHeightBounds = pane.height - 200
      const paneWidthBounds = pane.width - 200
      const position = {
        top: event.clientY < paneHeightBounds
          ? event.clientY - barHeight
          : undefined,
        left: event.clientX < paneWidthBounds
          ? event.clientX - barWidth
          : undefined,
        right: event.clientX >= paneWidthBounds
          ? pane.width - event.clientX - barWidth
          : undefined,
        bottom: event.clientY >= paneHeightBounds
          ? pane.height - event.clientY - barHeight
          : undefined
      }
      setPosition(position)
    },
    [setPosition]
  )

  // Remove the resizeObserver error
  // https://github.com/xyflow/xyflow/issues/3076#issuecomment-1763384329
  useEffect(() => {
    const errorHandler = (e: any) => {
      if (
        e.message.includes(
          'ResizeObserver loop completed with undelivered notifications'
        ) ??
        e.message.includes(
          'ResizeObserver loop limit exceeded'
        )
      ) {
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        )
        if (resizeObserverErr) {
          resizeObserverErr.style.display = 'none'
        }
      }
    }
    window.addEventListener('error', errorHandler)

    return () => {
      window.removeEventListener('error', errorHandler)
    }
  }, [])

  const onClick = useCallback(() => {
    if (position) {
      setPosition(null)
    }
  }, [position])

  const onNodeClick = useCallback(() => {
    if (position) {
      setPosition(null)
    }
  }, [position])

  const onNodeDragStart: OnNodeDrag = useCallback(() => {
    // 👇 make dragging a node undoable
    takeSnapshot()
    // 👉 you can place your event handlers here
  }, [takeSnapshot])

  const onSelectionDragStart: SelectionDragHandler = useCallback(() => {
    // 👇 make dragging a selection undoable
    takeSnapshot()
  }, [takeSnapshot])

  const onNodesDelete: OnNodesDelete = useCallback(() => {
    // 👇 make deleting nodes undoable
    takeSnapshot()
  }, [takeSnapshot])

  // load site flow Id using GET site flow Api
  const loadSiteFlow = useCallback(async () => {
    if (siteId) {
      await getSiteFlow({ siteId: Number(siteId) }, token).caseOf({
        Left: ({ data }) => {
          if (onFetchLayout) {
            onFetchLayout(false)
          }
          if (typeof data !== 'string') {
            // Avoid showing 404 error by default because all sites by default doesn't have a site flow
            // site flow will be created once saved
            if (data.code !== 404) {
              showError(new Error(data.messages[0]))
            }
          }
          setTimeout(() => {
            setIsLoaded(true)
          }, 1500)
        },
        Right: ({ data }) => {
          if (data) {
            if (onFetchLayout) {
              onFetchLayout(true)
            }
            // remove selected on data to avoid showing it on view only
            const flow = cleanData(data as never as Record<string, any>)
            restore(flow)
            setIsDirty(false)
            setTimeout(() => {
              setIsLoaded(true)
            }, 1500)
          }
        }
      })
    }
  }, [siteId])

  useEffect(() => {
    // load instance on load
    if (siteId) {
      void loadSiteFlow()
    }
  }, [siteId])

  return (
    <Box
      ref={reactFlowWrapper}
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'hidden'
      }}
    >
      <ReactFlow
        ref={ref}
        selectionMode={SelectionMode.Full}
        selectionOnDrag={cursorMode === 'selectOnDrag'}
        panOnDrag={cursorMode === 'panOnDrag'
          ? true
          : [1, 2, 3, 4]}
        elevateNodesOnSelect={false}
        elevateEdgesOnSelect={false}
        // Todo: Change to straight line
        connectionLineType={ConnectionLineType.Straight}
        proOptions={{
          account: 'paid-pro',
          hideAttribution: true
        }}
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onDrop={onDrop}
        onInit={setRfInstance}
        onClick={onClick}
        onDragOver={onDragOver}
        onContextMenu={onContextMenu}
        onNodeClick={onNodeClick}
        onNodeDrag={onNodeDrag}
        onNodesChange={onNodesChange}
        onNodesDelete={onNodesDelete}
        onNodeDragStop={onNodeDragStop}
        onNodeDragStart={onNodeDragStart}
        onEdgesChange={handleEdgeChange}
        onSelectionDragStart={onSelectionDragStart}
        fitView
        {...lineHandlers}
        {...viewOnlyProps}
      >
        <Controls position="bottom-right" />
        <CrossLines viewOnly={viewOnly} />
        <HelperLines
          horizontal={helperLineHorizontal}
          vertical={helperLineVertical}
        />
        {!viewOnly && (
          <ContextMenu
            nodes={nodes}
            setNodes={setNodes}
            position={position}
          />
        )}
      </ReactFlow>
      <TopBar
        viewOnly={viewOnly}
        siteId={siteId}
        nodes={nodes}
        undo={undo}
        redo={redo}
        canUndo={canUndo}
        canRedo={canRedo}
        canSave={isDirty}
        isSaving={saveFlow.loading}
        onSave={handleSaveSiteFlow}
      />
      <Toolbar viewOnly={viewOnly} onDeleteAll={handleDeleteAll} />
      <AgentWizard />
      <SensorWizard />
      <ImageWizard />
      <SiteWizard />
      <BackgroundWizard />
      <NodeEdgeInspector nodes={nodes} edges={edges} />
      {viewOnly && (
        <NeedsRole role={adminRoles}>
          <Paper
            shadow="xl"
            p="xs"
            pos="absolute"
            sx={{
              top: 12,
              right: 12
            }}
          >
            <Tooltip withinPortal position="top-start" label="Edit">
              <ActionIcon
                component={Link}
                to={`/settings/sites/flow/${siteId}`}
                radius="sm"
              >
                <IconPencil size={24} />
              </ActionIcon>
            </Tooltip>
          </Paper>
        </NeedsRole>
      )}
      <Prompt
        when={isDirty && !viewOnly}
        message="There are unsaved changes, are you sure you want to leave?"
      />
    </Box>
  )
}

export default function (props: Props) {
  return (
    <ReactFlowProvider>
      <DnDProvider>
        <CopyPasteProvider>
          <NodeProvider {...props}>
            <WizardProvider>
              <Flow {...props} />
            </WizardProvider>
          </NodeProvider>
        </CopyPasteProvider>
      </DnDProvider>
    </ReactFlowProvider>
  )
}
