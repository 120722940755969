import {
  useMantineTheme,
  Box,
  Group,
  Stack,
  Text,
  ThemeIcon
} from '@mantine/core'
import { IconCalendarMonth } from '@tabler/icons-react'
import { getLinkTextColor } from 'src/utils/theme'
import EmptyPlaceholder from 'src/Reports/Viewer/EmptyPlaceholder'
import { type ParsedSpeedEvent } from 'src/Reports/Viewer/SpeedingReportView'
import Card from './Card'

interface Props {
  items: ParsedSpeedEvent['speed_alerts']
}

export default function Events ({ items }: Props) {
  const theme = useMantineTheme()

  return (
    <>
      <Box>
        <Group
          mb={8}
          ml={-8}
          spacing={0}
          align="center"
          noWrap
        >
          <ThemeIcon
            sx={{
              background: 'transparent'
            }}
            variant="light"
            color="primary"
          >
            <IconCalendarMonth size={12} stroke={2} />
          </ThemeIcon>
          <Text
            size={12}
            weight={500}
            {...getLinkTextColor(theme)}
          >
            Events
          </Text>
        </Group>
        <Stack spacing={12}>
          {items.map((item, index) => (
            <Card
              key={item.id}
              index={index}
              data={item}
            />
          ))}
        </Stack>
      </Box>
      {items.length === 0 && <EmptyPlaceholder />}
    </>
  )
}
