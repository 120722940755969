import React, { type FormEvent, useCallback, useState } from 'react'
import { Button, Group, Modal, Stack } from '@mantine/core'
import { IconPlus } from '@tabler/icons-react'
import MultiSelectAgentSensor from 'src/Input/MultiSelect/MultiSelectAgentSensor'
import { type SensorInstance } from '@venturi-io/api/src/config/ui'
import { type Node, useReactFlow } from '@xyflow/react'
import { randomId } from '@mantine/hooks'
import { type Sensor } from '../../Nodes/SensorNode'

interface Props {
  show: boolean
  close: () => void
}

const OFFSET = 40

function SensorPickerDialog ({ show, close }: Props) {
  const { addNodes, setCenter } = useReactFlow()
  const [raws, setRaws] = useState<SensorInstance[]>()

  const handleSubmit = useCallback((e: FormEvent<Element>) => {
    e.preventDefault()
    if (raws) {
      const nodes: Array<Node<Sensor>> = raws.map((sensor, idx) => ({
        id: randomId(),
        type: 'sensorNode',
        position: {
          x: 0,
          y: idx * 52
        },
        zIndex: 4,
        data: {
          ...sensor,
          sensorId: sensor.sensorId,
          sensorName: sensor.name
        }
      }))

      addNodes(nodes)
      void setCenter(OFFSET, OFFSET, {
        duration: 800,
        zoom: 1
      })
      setRaws([])
      close()
    }
  }, [raws])

  const handleChange = useCallback((_: string[], raws?: SensorInstance[]) => {
    setRaws(raws)
  }, [])

  return (
    <Modal
      centered
      opened={show}
      onClose={close}
      title="Add sensors"
    >
      <form onSubmit={handleSubmit}>
        <Stack spacing="xs">
          <MultiSelectAgentSensor
            searchable
            withinPortal
            clearable
            label="Select sensor instances"
            onChange={handleChange}
          />
          <Group position="right">
            <Button
              color="primary"
              type="submit"
              leftIcon={<IconPlus size={16} />}
            >
              {`Add ${raws?.length
                ? raws.length
                : ''} sensors`}
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  )
}

export default SensorPickerDialog
