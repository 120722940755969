/* eslint-disable @typescript-eslint/naming-convention */
import { Text, View } from '@react-pdf/renderer'
import THCell from 'src/Layout/ReportTemplate/Components/THCell'
import TDCell from 'src/Layout/ReportTemplate/Components/TDCell'
import Space from 'src/Layout/ReportTemplate/Components/Space'
import { reportStyles } from 'src/Layout/ReportTemplate/styles'
import { type ParsedTripItem } from 'src/Reports/Viewer/TripReportView'

interface Props {
  data: ParsedTripItem[]
}

export default function SummaryTable ({ data }: Props) {
  return (
    <View>
      <Text style={reportStyles.subtitleValue}>
        SUMMARY
      </Text>
      <Space />
      <View style={reportStyles.group}>
        <THCell value="Assets" />
        <THCell value="Alarm Status" />
        <THCell value="# Trips" />
        <THCell value="Total Duration" />
        <THCell value="Total Distance KMs" />
      </View>
      {data.map(({
        agent_id,
        agent_name,
        alarm_status,
        total_trips,
        total_duration,
        total_distance_in_kilometres
      }) => (
        <View key={agent_id} style={reportStyles.group}>
          <TDCell value={agent_name} />
          <TDCell value={alarm_status} />
          <TDCell value={total_trips} />
          <TDCell value={total_duration} />
          <TDCell value={total_distance_in_kilometres} />
        </View>
      ))}
    </View>
  )
}
