/* eslint-disable @typescript-eslint/naming-convention */
import { Text, View } from '@react-pdf/renderer'
import { reportStyles } from 'src/Layout/ReportTemplate/styles'
import { toProperCase } from 'src/utils/strings'
import { type SiteTankLevels } from '../../../TankLevelReportView'
import Table from './Table'
import Chart from './Chart'

interface Props {
  index: number
  data: SiteTankLevels
}

export default function Item ({
  index,
  data: {
    site_id,
    site_name,
    list,
    chart_url
  }
}: Props) {
  return (
    <View
      key={site_id}
      style={reportStyles.stack}
      break={index > 0}
    >
      <Text>
        {toProperCase(site_name)}
      </Text>
      <View>
        <Table list={list} />
        {chart_url && <Chart src={chart_url} />}
      </View>
    </View>
  )
}
