/* eslint-disable @typescript-eslint/naming-convention */
import { View } from '@react-pdf/renderer'
import THCell from 'src/Layout/ReportTemplate/Components/THCell'
import TDCell from 'src/Layout/ReportTemplate/Components/TDCell'
import { reportStyles } from 'src/Layout/ReportTemplate/styles'
import { truncateWithEllipsis } from 'src/utils/strings'
import { type SiteTankLevels } from '../../../TankLevelReportView'

const Header = () => (
  <View style={[reportStyles.group]}>
    <THCell value="Tank" />
    <THCell value="Fluid" />
    <THCell value="Tank Capacity (L)" />
    <THCell value="Start Date/Time" />
    <THCell value="Start Volume (L)" />
    <THCell value="End Date/Time" />
    <THCell value="End Volume (L)" />
  </View>
)

const Row = ({
  agent_name,
  agent_liquid_type,
  tank_capacity,
  start_time,
  start_volume,
  end_time,
  end_volume
}: SiteTankLevels['list'][0]) => (
  <View style={reportStyles.group}>
    <TDCell value={truncateWithEllipsis(agent_name, 20)} />
    <TDCell value={agent_liquid_type} />
    <TDCell value={tank_capacity} />
    <TDCell value={start_time} />
    <TDCell value={start_volume} />
    <TDCell value={end_time} />
    <TDCell value={end_volume} />
  </View>
)

interface Props {
  list: SiteTankLevels['list']
}

const Body = ({ list }: Props) => (
  list.map((item) => (
    <Row
      key={`${item.site_id}-${item.agent_id}`}
      {...item}
    />
  ))
)

export default function Table ({ list }: Props) {
  return (
    <View>
      <Header />
      <Body list={list} />
    </View>
  )
}
