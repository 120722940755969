import { Text, View } from '@react-pdf/renderer'
import { tableStyles } from '../styles'

interface Props {
  value: string
}

export default function TDCell ({ value }: Props) {
  return (
    <View
      style={[
        tableStyles.cell,
        tableStyles.td
      ]}
    >
      <Text>{value}</Text>
    </View>
  )
}
