import React, {
  createContext,
  type ReactElement,
  type ReactNode,
  useContext,
  useState
} from 'react'

interface Props {
  children: JSX.Element | ReactElement | ReactNode
}

export type CursorMode = 'selectOnDrag' | 'panOnDrag'

interface ContextProps {
  type: string | null
  setType: (type: string | null) => void
  cursorMode: CursorMode
  setCursorMode: (mode: CursorMode) => void
}

const DnDContext = createContext<ContextProps>({
  type: null,
  cursorMode: 'panOnDrag',
  setType: () => {},
  setCursorMode: () => {}
})

export const DnDProvider = ({ children }: Props) => {
  const [type, setType] = useState<string | null>(null)
  const [cursorMode, setCursorMode] = useState<CursorMode>('panOnDrag')

  return (
    <DnDContext.Provider value={{
      type,
      cursorMode,
      setType,
      setCursorMode
    }}
    >
      {children}
    </DnDContext.Provider>
  )
}

export default DnDContext

export const useDnD = () => {
  return useContext(DnDContext)
}
