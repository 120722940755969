import React from 'react'
import {
  Paper,
  Flex,
  useMantineTheme,
  Text,
  Divider,
  Group
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { type Node, Panel } from '@xyflow/react'
import { mq } from 'src/utils/style'
import {
  IconArrowBack,
  IconArrowForward,
  IconClipboard,
  IconCopy,
  IconCut,
  IconDeviceFloppy
} from '@tabler/icons-react'
import { type UseUndoRedoProps } from 'src/Admin/SiteDetail/hooks/useUndoRedo'
import useCopyPaste from 'src/Admin/SiteDetail/hooks/useCopyPaste'
import ToolIcon from './ToolIcon'
import { ICON_XS_SIZE } from '.'

export interface TopBarProps extends UseUndoRedoProps {
  viewOnly?: boolean
  siteId?: string
  nodes: Node[]
  canSave: boolean
  isSaving?: boolean
  onSave: () => void
}

function TopBar ({
  viewOnly,
  siteId,
  nodes,
  undo,
  redo,
  canUndo,
  canRedo,
  canSave,
  isSaving,
  onSave
}: TopBarProps) {
  if (viewOnly) return undefined

  const { breakpoints } = useMantineTheme()
  const isDesktop = useMediaQuery(mq(breakpoints.sm, false))
  const {
    copy,
    cut,
    paste,
    bufferedNodes
  } = useCopyPaste({ noKeyboardShortcut: false })

  const selectedNodes = nodes.filter(node => node.selected)

  const canCopy = nodes.some(({ selected }) => selected)
  const canPaste = bufferedNodes.length > 0

  return (
    <Panel position={isDesktop
      ? 'top-center'
      : 'bottom-left'}
    >
      <Paper
        py="xs"
        px="xs"
        radius="lg"
        shadow="xl"
      >
        <Flex
          direction={isDesktop
            ? 'row'
            : 'column'}
          align="center"
          gap="xs"
        >
          {isDesktop
            ? (
              <Text
                fz="xs"
                sx={{
                  lineHeight: '32px'
                }}
              >
                {`${selectedNodes.length} selected`}
              </Text>
              )
            : (
              <Text fz="sm">
                {selectedNodes.length}
              </Text>
              )}
          <Divider orientation={isDesktop
            ? 'vertical'
            : 'horizontal'}
          />
          <ToolIcon
            buttonSize="xs"
            disabled={!canCopy}
            draggable={false}
            tooltip="Copy (ctrl+c)"
            Icon={IconCopy}
            size={ICON_XS_SIZE}
            onClick={copy}
          />
          <ToolIcon
            buttonSize="xs"
            disabled={!canCopy}
            draggable={false}
            tooltip="Cut (ctrl+x)"
            Icon={IconCut}
            size={ICON_XS_SIZE}
            onClick={cut}
          />
          <ToolIcon
            buttonSize="xs"
            disabled={!canPaste}
            draggable={false}
            tooltip="Paste (ctrl+v)"
            Icon={IconClipboard}
            size={ICON_XS_SIZE}
            onClick={() => paste({ x: 0, y: 0 })}
          />
          <Divider orientation={isDesktop
            ? 'vertical'
            : 'horizontal'}
          />
          <ToolIcon
            buttonSize="xs"
            disabled={canUndo}
            draggable={false}
            tooltip="Undo (ctrl+z)"
            Icon={IconArrowBack}
            size={ICON_XS_SIZE}
            onClick={undo}
          />
          <ToolIcon
            buttonSize="xs"
            disabled={canRedo}
            draggable={false}
            tooltip="Redo (ctrl+shift+z)"
            Icon={IconArrowForward}
            size={ICON_XS_SIZE}
            onClick={redo}
          />
          {siteId && (
            <>
              <Divider orientation={isDesktop
                ? 'vertical'
                : 'horizontal'}
              />
              <Group spacing="xs">
                {canSave && isDesktop && (
                  <Text
                    fz="xs"
                    color="dimmed"
                    sx={{
                      lineHeight: '32px'
                    }}
                  >
                    Unsaved changes
                  </Text>
                )}
                <ToolIcon
                  buttonSize="xs"
                  loading={isSaving}
                  disabled={!canSave}
                  draggable={false}
                  tooltip="Save changes"
                  Icon={IconDeviceFloppy}
                  size={ICON_XS_SIZE}
                  onClick={onSave}
                />
              </Group>
            </>
          )}
        </Flex>
      </Paper>
    </Panel>
  )
}

export default TopBar
