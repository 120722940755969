import { Stack, Text } from '@mantine/core'

interface Props {
  title: string
  message: string
}

export default function InformationBox ({ title, message }: Props) {
  return (
    <Stack spacing="xs" my="xl">
      <Text weight="bold" size="sm">{title}</Text>
      <Text weight={400} size="xs">{message}</Text>
    </Stack>
  )
}
