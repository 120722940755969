import { createStyles, Textarea } from '@mantine/core'
import { type Node, type NodeProps, useReactFlow } from '@xyflow/react'
import React, { memo } from 'react'
import { getTextColor } from 'src/utils/theme'
import ColorToolbar from '../FlowControls/ColorToolbar'
import { type CustomizationProps } from '../shared'
import BaseNode from './BaseNode'
import { useViewControls } from './NodeContext'

interface StyleProps {
  color?: string
}

const useStyles = createStyles((theme, { color }: StyleProps) => ({
  input: {
    padding: 0,
    margin: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    overflow: 'hidden',
    color: color ?? getTextColor(theme).color
  }
}))

interface Label extends Record<string, string | CustomizationProps | undefined> {
  text?: string
}

export type LabelNodeType = Node<Label, 'labelNode'>

function LabelNode (props: NodeProps<LabelNodeType>) {
  const { viewOnly } = useViewControls()
  const { updateNodeData } = useReactFlow()
  const { id, selected, data: { text, color: textColor } } = props
  const activeColor = textColor
    ? textColor as string
    : undefined
  const { classes } = useStyles({
    color: activeColor
  })

  return (
    <BaseNode
      minWidth={100}
      minHeight={20}
      allowResize
      noBg
      noBorder
      noPadding
      {...props}
    >
      <ColorToolbar
        selected={selected}
        activeColor={activeColor}
        onChange={(color) => updateNodeData(id, { color })}
      />
      <Textarea
        readOnly={viewOnly}
        placeholder="Enter text here"
        value={text}
        variant="unstyled"
        onChange={(e) => updateNodeData(id, { text: e.target.value })}
        classNames={{
          root: classes.input,
          wrapper: classes.input,
          input: classes.input
        }}
      />
    </BaseNode>
  )
}

export default memo(LabelNode)
