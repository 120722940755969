import { createContext, useContext } from 'react'

interface Props {
  viewOnly?: boolean
  children: JSX.Element
}

interface ContextProps {
  viewOnly?: boolean
}

const NodeContext = createContext<ContextProps>({})

export const NodeProvider = ({ children, viewOnly }: Props) => {
  return (
    <NodeContext.Provider value={{ viewOnly }}>
      {children}
    </NodeContext.Provider>
  )
}

export default NodeContext

export const useViewControls = () => useContext(NodeContext)
