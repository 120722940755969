import {
  useMantineTheme,
  Box,
  Group,
  Stack,
  Text,
  ThemeIcon
} from '@mantine/core'
import { IconRoute } from '@tabler/icons-react'
import { getLinkTextColor } from 'src/utils/theme'
import EmptyPlaceholder from 'src/Reports/Viewer/EmptyPlaceholder'
import { type ParsedTripItem } from 'src/Reports/Viewer/TripReportView'
import Card from './Card'

interface Props {
  items: ParsedTripItem['trips']
}

export default function Trips ({ items }: Props) {
  const theme = useMantineTheme()

  return (
    <>
      <Box>
        <Group
          mb={8}
          ml={-8}
          spacing={0}
          align="center"
          noWrap
        >
          <ThemeIcon
            sx={{
              background: 'transparent'
            }}
            variant="light"
            color="primary"
          >
            <IconRoute size={12} stroke={2} />
          </ThemeIcon>
          <Text
            size={12}
            weight={500}
            {...getLinkTextColor(theme)}
          >
            Trips
          </Text>
        </Group>
        <Stack spacing={12}>
          {items.map((item) => (
            <Card key={item.trip_id} data={item} />
          ))}
        </Stack>
      </Box>
      {items.length === 0 && <EmptyPlaceholder />}
    </>
  )
}
