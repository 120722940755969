import React, { memo, useEffect, useState } from 'react'
import { useReactFlow, type Node, type NodeProps } from '@xyflow/react'
import { Group, Text } from '@mantine/core'
import { usePaginatedApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { IconBinaryTree2 } from '@tabler/icons-react'
import { getSitesWithAlarms, type SiteWithAlarms } from '@venturi-io/api/src/config/site'
import StatusBadge from '../Components/StatusBadge'
import NodeLinkButton from '../Components/NodeLinkButton'
import BaseNode from './BaseNode'

interface SiteProps extends Record<string, string | SiteWithAlarms | undefined> {
  siteId?: string
  siteName?: string
  details?: SiteWithAlarms
}

export type SiteNodeType = Node<SiteProps, 'siteNode'>

function SiteNode (props: NodeProps<SiteNodeType>) {
  const {
    siteId,
    siteName
  } = props.data

  const { orgId, token } = useUser()
  const { updateNode } = useReactFlow()
  const sites = usePaginatedApi(getSitesWithAlarms)
  const [details, setDetails] = useState<SiteWithAlarms>()

  useEffect(() => {
    if (siteId) {
      void sites.fetch({ orgId, page: 1, size: 999 }, token)
    }
  }, [siteId])

  useEffect(() => {
    sites.data.ifJust(({ items }) => {
      const siteDetail = siteId
        ? items.find(({ siteId: id }) => id === Number(siteId))
        : undefined
      updateNode(props.id, node => ({
        data: {
          ...node.data,
          details: siteDetail ?? {}
        }
      }))
      setDetails(siteDetail)
    })
  }, [sites.data, siteId])

  return (
    <BaseNode
      isLoading={sites.loading}
      {...props}
    >
      <Group bg="inherit" spacing="xs" pos="relative">
        <IconBinaryTree2 size={16} />
        <Text
          color={siteName
            ? undefined
            : 'dimmed'}
          size="xs"
        >
          {siteName ?? 'No site assigned yet'}
        </Text>
        {details && (
          <>
            <StatusBadge
              isLoading={sites.loading}
              isAlarm={false}
              label={`${details.alarmsOkCount} OK`}
            />
            <StatusBadge
              isLoading={sites.loading}
              isAlarm={details.alarmsCount > 0}
              label={`${details.alarmsCount} Alarm`}
            />
          </>
        )}
        <NodeLinkButton to={`/site/${siteId}`} identifier="Site" />
      </Group>
    </BaseNode>
  )
}

export default memo(SiteNode)
