import React from 'react'
import { useNodesData, useReactFlow } from '@xyflow/react'
import { Group, Stack } from '@mantine/core'
import { IconLock, IconLockOpen } from '@tabler/icons-react'
import Action from './Action'

interface Props {
  nodeId: string
  hasLock?: boolean
}

function InteractionControls ({ nodeId, hasLock }: Props) {
  const { updateNode } = useReactFlow()
  const nodeData = useNodesData(nodeId)
  if (!nodeData) return null

  const { locked } = nodeData.data

  return (
    <Stack spacing="xs">
      {hasLock && (
        <Group spacing={2}>
          <Action
            label={locked
              ? 'Locked'
              : 'Unlocked'}
            Icon={locked
              ? IconLock
              : IconLockOpen}
            checked={locked as boolean}
            onClick={() => updateNode(
              nodeId,
              (node) => ({
                data: {
                  ...node.data,
                  locked: !locked
                },
                selectable: locked as boolean
              }))}
          />
        </Group>
      )}
    </Stack>
  )
}

export default InteractionControls
