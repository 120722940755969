import React, { memo, useEffect, useState } from 'react'
import { type NodeProps, type Node, NodeResizeControl, useKeyPress } from '@xyflow/react'
import { Image } from '@mantine/core'
import { IconArrowsDiagonal } from '@tabler/icons-react'
import { imageStyles } from '../sharedStyles'
import { type InteractionProps, SELECTED_COLOR, type CustomizationProps } from '../shared'
import NodeToolbar from '../FlowControls/NodeToolbar'
import useGenericImage, { type GenericImage } from '../hooks/useGenericImage'
import BaseNode from './BaseNode'

interface Background extends
  Record<string, string | number | boolean | GenericImage | undefined>,
  CustomizationProps,
  InteractionProps {
  image?: GenericImage
}

export type BGImageNodeType = Node<Background, 'backgroundNode'>

function BackgroundNode (props: NodeProps<BGImageNodeType>) {
  const { classes } = imageStyles()
  const shiftKeyPressed = useKeyPress('Shift')
  const { image, opacity, locked } = props.data
  const { load } = useGenericImage()
  const [imageUrl, setImageUrl] = useState<string>()

  const loadImage = async () => {
    if (image) {
      const url = await load(image.previewUrl)
      setImageUrl(url)
    }
  }

  useEffect(() => {
    if (image) {
      void loadImage()
    }
  }, [image])

  return (
    <BaseNode
      {...props}
      noPadding
      shouldOverrideToolbar
      shouldConnect={false}
      selectable={!locked}
      draggable={false}
    >
      <>
        <NodeToolbar
          isVisible={locked as boolean}
          showLock={locked as boolean}
        />
        <Image
          src={imageUrl}
          fit="contain"
          classNames={{
            root: classes.maxWidthAndHeight,
            figure: classes.maxWidthAndHeight,
            imageWrapper: classes.maxWidthAndHeight,
            image: classes.maxWidthAndHeight
          }}
          sx={{
            opacity
          }}
        />
        {!locked && (
          <NodeResizeControl
            keepAspectRatio={shiftKeyPressed}
            minWidth={100}
            minHeight={50}
          >
            <IconArrowsDiagonal
              size={16}
              color={SELECTED_COLOR}
              transform="scale(-1,1)"
            />
          </NodeResizeControl>
        )}
      </>
    </BaseNode>
  )
}

export default memo(BackgroundNode)
