import { Image, View } from '@react-pdf/renderer'
import { reportStyles } from 'src/Layout/ReportTemplate/styles'

interface Props {
  src: string
}

export default function Chart ({ src }: Props) {
  return (
    <View style={reportStyles.imageContainer}>
      <Image src={src} style={reportStyles.chartImage} />
    </View>
  )
}
