import { Divider, Flex, Paper, useMantineTheme } from '@mantine/core'
import {
  IconBinaryTree2,
  IconBox,
  IconCircle,
  IconCpu,
  IconLetterCase,
  IconLibraryPhoto,
  IconLine,
  IconPhotoScan,
  IconPointer,
  IconRectangle,
  IconShape,
  IconTrash
} from '@tabler/icons-react'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import ConfirmModal from 'src/Layout/ConfirmModal'
import { mq } from 'src/utils/style'
import { Panel } from '@xyflow/react'
import SensorPickerDialog from '../Components/Dialog/SensorPickerDialog'
import { useNode } from '../Wizard/WizardContext'
import { useDnD } from '../DnDContext'
import ToolIcon from './ToolIcon'

export const ICON_SIZE = 24
export const ICON_XS_SIZE = 16
export const ICON_PADDING = 8
interface Props {
  viewOnly?: boolean
  onDeleteAll: () => void
}

function Toolbar ({
  viewOnly,
  onDeleteAll
}: Props) {
  if (viewOnly) return undefined

  const { cursorMode, setCursorMode } = useDnD()
  const { bgWizardShow, bgWizardToggle } = useNode()
  const { breakpoints } = useMantineTheme()
  const isDesktop = useMediaQuery(mq(breakpoints.sm, false))
  const [
    showDelete,
    {
      toggle: toggleDelete,
      close: closeDelete
    }
  ] = useDisclosure(false)
  const [
    sensorShow,
    {
      toggle: sensorToggle,
      close: sensorClose
    }
  ] = useDisclosure(false)

  const iconSize = isDesktop
    ? ICON_SIZE
    : ICON_XS_SIZE

  return (
    <Panel position={isDesktop
      ? 'bottom-center'
      : 'bottom-right'}
    >
      <Paper
        py="sm"
        px={isDesktop
          ? 'lg'
          : 'xs'}
        radius="lg"
        shadow="xl"
      >
        <Flex
          direction={isDesktop
            ? 'row'
            : 'column'}
          gap={isDesktop
            ? 'lg'
            : 'xs'}
        >
          <ToolIcon
            selected={cursorMode === 'selectOnDrag'}
            draggable={false}
            tooltip="Select"
            Icon={IconPointer}
            size={iconSize}
            onClick={() => setCursorMode(cursorMode === 'selectOnDrag'
              ? 'panOnDrag'
              : 'selectOnDrag')}
          />
          <ToolIcon
            draggable={false}
            tooltip="Clear all"
            Icon={IconTrash}
            size={iconSize}
            onClick={toggleDelete}
          />
          <Divider orientation={isDesktop
            ? 'vertical'
            : 'horizontal'}
          />
          <ToolIcon
            draggable
            tooltip="Label"
            type="labelNode"
            Icon={IconLetterCase}
            size={iconSize}
          />
          <ToolIcon
            draggable
            tooltip="Circle"
            type="shapeNode"
            shapeType="circle"
            Icon={IconCircle}
            size={iconSize}
          />
          <ToolIcon
            draggable
            tooltip="Rectangle"
            type="shapeNode"
            shapeType="rectangle"
            Icon={IconRectangle}
            size={iconSize}
          />
          <ToolIcon
            draggable
            tooltip="Line"
            type="lineNode"
            Icon={IconLine}
            size={iconSize}
          />
          <ToolIcon
            draggable
            tooltip="Image"
            type="imageNode"
            Icon={IconLibraryPhoto}
            size={iconSize}
          />
          <Divider orientation={isDesktop
            ? 'vertical'
            : 'horizontal'}
          />
          <ToolIcon
            draggable
            tooltip="Agent"
            type="agentNode"
            Icon={IconBox}
            size={iconSize}
          />
          <ToolIcon
            draggable
            tooltip="Sensor"
            type="sensorNode"
            Icon={IconCpu}
            size={iconSize}
            onClick={sensorToggle}
          />
          <ToolIcon
            draggable
            tooltip="Site"
            type="siteNode"
            Icon={IconBinaryTree2}
            size={iconSize}
          />
          <ToolIcon
            isGroup
            draggable
            tooltip="Group"
            type="groupNode"
            Icon={IconShape}
            size={iconSize}
          />
          <Divider orientation={isDesktop
            ? 'vertical'
            : 'horizontal'}
          />
          <ToolIcon
            selected={bgWizardShow}
            draggable={false}
            tooltip="Background"
            Icon={IconPhotoScan}
            size={iconSize}
            onClick={bgWizardToggle}
          />
        </Flex>
      </Paper>
      <SensorPickerDialog
        show={sensorShow}
        close={sensorClose}
      />
      <ConfirmModal
        type="delete"
        opened={showDelete}
        title="Are you sure?"
        question="Deleting will clear all your workspace."
        onConfirm={() => {
          onDeleteAll()
          closeDelete()
        }}
        onCancel={closeDelete}
        onClose={closeDelete}
      />
    </Panel>
  )
}

export default Toolbar
