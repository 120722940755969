import React from 'react'
import { Group, useMantineTheme } from '@mantine/core'
import {
  IconLockFilled,
  IconEdit,
  IconTrash,
  IconShapeOff,
  IconExternalLink
} from '@tabler/icons-react'
import { NodeToolbar as Toolbar, type NodeToolbarProps } from '@xyflow/react'
import NodeAction from './NodeAction'

interface Props extends NodeToolbarProps {
  alwaysShow?: boolean
  showLock?: boolean
  showEdit?: boolean
  onDelete?: () => void
  onUngroup?: () => void
  onDetach?: () => void
}

function NodeToolbar ({
  alwaysShow,
  showLock,
  showEdit,
  onDelete,
  onUngroup,
  onDetach,
  children,
  ...rest
}: Props) {
  const { colors } = useMantineTheme()

  return (
    <Toolbar isVisible={alwaysShow} align="end" {...rest}>
      <Group spacing="xs">
        {onUngroup && (
          <NodeAction Icon={IconShapeOff} tooltip="Ungroup" onClick={onUngroup} />
        )}
        {onDetach && (
          <NodeAction Icon={IconExternalLink} tooltip="Detach" onClick={onDetach} />
        )}
        {onDelete && (
          <NodeAction
            variant="filled"
            buttonColor="red"
            Icon={IconTrash}
            tooltip="Delete"
            onClick={onDelete}
          />
        )}
        {showLock && (
          <IconLockFilled size={16} color={colors.primary[7]} />
        )}
        {showEdit && (
          <IconEdit size={16} color={colors.primary[7]} />
        )}
        {children}
      </Group>
    </Toolbar>
  )
}

export default NodeToolbar
