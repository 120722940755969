import { createStyles } from '@mantine/core'
import { getBackgroundColor } from 'src/utils/theme'
import { ICON_PADDING, ICON_SIZE, ICON_XS_SIZE } from './Toolbar'
import { SELECTED_COLOR } from './shared'

export const imageStyles = createStyles(() => ({
  maxWidthAndHeight: {
    pointerEvents: 'none',
    width: '100%',
    height: '100% !important'
  }
}))

interface StyleProps {
  isDesktop: boolean
  isSelected: boolean
}

export const buttonStyles = createStyles((theme, { isSelected, isDesktop }: StyleProps) => ({
  iconWithSelectedState: {
    width: isDesktop
      ? ICON_SIZE + ICON_PADDING
      : ICON_XS_SIZE + ICON_PADDING,
    height: isDesktop
      ? ICON_SIZE + ICON_PADDING
      : ICON_XS_SIZE + ICON_PADDING,
    backgroundColor: isSelected
      ? SELECTED_COLOR
      : 'transparent',
    color: isSelected
      ? '#fff'
      : SELECTED_COLOR,
    borderRadius: theme.radius.sm,
    cursor: 'pointer',
    ':hover': {
      ...getBackgroundColor(theme, -1)
    }
  }
}))
