import { type ReactNode, useEffect, useState, useCallback } from 'react'
import { type SelectProps, type SelectItem } from '@mantine/core'
import { getSensors, type Sensor } from '@venturi-io/api/src/config/ui'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import Select from '../Select'

type Value = string | null

interface Props extends Omit<SelectProps, 'data'> {
  label?: string
  placeholder?: string
  defaultValue?: Value
  value?: Value
  onChange?: (value: Value, text?: string) => void
  setExternalSensorId?: (value: Value) => void
  required?: boolean
  searchable?: boolean
  disabled?: boolean
  error?: ReactNode
}

const sensorToSelect = ({ id, name }: Sensor): SelectItem => ({
  value: id.toString(),
  label: name
})

export default function SelectSensor ({
  defaultValue,
  value,
  label,
  placeholder,
  setExternalSensorId,
  onChange,
  required = false,
  searchable = false,
  disabled = false,
  error,
  ...props
}: Props) {
  const { token } = useUser()
  const sensors = useApi(getSensors)
  const [sensorId, setSensorId] = useState<Value>(null)

  const selectSensors = sensors.data.mapOrDefault(data => data.map(sensorToSelect), [])

  const loadSensors = () => {
    void sensors.fetch({}, token)
  }

  const handleChange = useCallback((val: Value) => {
    setSensorId(val)

    if (setExternalSensorId) {
      setExternalSensorId(val)
    }

    const selected = selectSensors.find(sensor => sensor.value === val)
    if (onChange && selected) {
      onChange(val, selected.label)
    }
  }, [setExternalSensorId, onChange, selectSensors])

  useEffect(() => {
    if (defaultValue) {
      setSensorId(defaultValue)
    }
  }, [defaultValue])

  useEffect(() => {
    void loadSensors()
  }, [])

  return (
    <Select
      label={label}
      placeholder={placeholder ?? 'Choose a sensor'}
      data={selectSensors}
      defaultValue={defaultValue}
      value={value ?? sensorId}
      onChange={handleChange}
      required={required}
      searchable={searchable}
      disabled={disabled || sensors.loading}
      error={error}
      {...props}
    />
  )
}
