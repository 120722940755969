import { type CSSProperties, useEffect, useRef } from 'react'
import { type ReactFlowState, useStore } from '@xyflow/react'
import { SELECTED_COLOR } from '../shared'

const canvasStyle: CSSProperties = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  zIndex: 10,
  pointerEvents: 'none'
}

const storeSelector = ({
  width,
  height,
  transform
}: ReactFlowState) => ({
  width,
  height,
  transform
})

interface Props {
  viewOnly?: boolean
}

// a simple component to display the helper lines
// it puts a canvas on top of the React Flow pane and draws the lines using the canvas API
function CrossLines ({ viewOnly }: Props) {
  const vertical = 0
  const horizontal = 0
  const { width, height, transform } = useStore(storeSelector)

  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas?.getContext('2d')

    if (!ctx || !canvas) {
      return
    }

    const dpi = window.devicePixelRatio
    canvas.width = width * dpi
    canvas.height = height * dpi

    ctx.scale(dpi, dpi)
    ctx.clearRect(0, 0, width, height)
    ctx.setLineDash([5, 3])
    const fill = '#adb5bd'
    ctx.strokeStyle = fill

    const verticalValue = vertical * transform[2] + transform[0]
    ctx.moveTo(verticalValue, 0)
    ctx.lineTo(verticalValue, height)
    ctx.stroke()

    const horizontalValue = horizontal * transform[2] + transform[1]
    ctx.moveTo(0, horizontalValue)
    ctx.lineTo(width, horizontalValue)
    ctx.stroke()

    if (!viewOnly) {
      const padding = 5
      ctx.font = '12px Inter'
      ctx.fillStyle = fill
      ctx.fillText('(0,0)', verticalValue + padding, horizontalValue - padding)
    }
  }, [width, height, transform, horizontal, vertical])

  return (
    <canvas
      ref={canvasRef}
      className="react-flow__canvas"
      style={canvasStyle}
    />
  )
}

export default CrossLines
