import { type SVGAttributes } from 'react'
import Circle from './Circle'
import Rectangle from './Rectangle'
import type { Node } from '@xyflow/react'

// here we register all the shapes that are available
// you can add your own here
export const ShapeComponents = {
  circle: Circle,
  rectangle: Rectangle
}

export type ShapeType = keyof typeof ShapeComponents

export type ShapeProps = {
  width: number
  height: number
} & SVGAttributes<SVGElement>

export type ShapeComponentProps = Partial<ShapeProps> & { type: ShapeType }

export type ShapeNode = Node<{
  type: ShapeType
  color: string
  text: string
}>
