import React, { type CSSProperties } from 'react'
import { createStyles } from '@mantine/core'
import { Handle, type HandleProps, useConnection } from '@xyflow/react'
import { useViewControls } from '../NodeContext'

interface StyleProps {
  isHovered?: boolean
  isPotentialTarget?: boolean
  hasConnection?: boolean
}

const useStyles = createStyles((theme, { isHovered, hasConnection, isPotentialTarget }: StyleProps) => ({
  handle: {
    opacity: isHovered
      ? 1
      : isPotentialTarget
        ? 0.65
        : hasConnection
          ? 1
          : 0,
    '&:hover': {
      opacity: 1,
      backgroundColor: `${theme.fn.rgba(theme.colors.gray[4], 0.65)} !important`
    }
  }
}))

interface Props extends Omit<HandleProps, 'type'> {
  nodeId: string
  hovered: boolean
}

const handleStyle: CSSProperties = {
  width: '80%',
  height: '80%',
  position: 'absolute',
  top: '10%',
  left: '10%',
  borderRadius: 8,
  transform: 'none',
  background: 'transparent',
  border: '2px #1F395C solid'
}

function Handles ({ nodeId, hovered, ...rest }: Props) {
  const { viewOnly } = useViewControls()
  const { inProgress, fromNode } = useConnection()
  const isTarget = inProgress && fromNode?.id !== nodeId
  const { classes } = useStyles({ isHovered: hovered, isPotentialTarget: isTarget })

  return (
    <>
      {!inProgress && (
        <Handle
          isConnectable={!viewOnly}
          className={classes.handle}
          type="source"
          style={handleStyle}
          {...rest}
        />
      )}
      {(!inProgress || isTarget) && (
        <Handle
          isConnectable={!viewOnly}
          className={classes.handle}
          type="target"
          isConnectableStart={false}
          style={handleStyle}
          {...rest}
        />
      )}
    </>
  )
}

export default Handles
