import { useNavigate } from 'react-router-dom'
import { Group, Paper, Stack } from '@mantine/core'
import { type Site } from '@venturi-io/api/src/userManager/site'
import { type Row as ListRow } from 'src/Layout/ActionList'
import TextGroup from 'src/Layout/ActionList/TextGroup'
import ActionButton from 'src/ActionButton'

export default function Card ({ siteId, name }: Site & ListRow) {
  const navigate = useNavigate()

  return (
    <Paper p="lg" my={10} shadow="md">
      <Stack>
        <Group position="apart" align="start">
          <TextGroup label="ID" value={siteId.toString()} isKey />
          <ActionButton
            label="Edit"
            icon="pencil"
            iconType="fas"
            onClick={() => navigate(`/settings/sites/edit/${siteId}`)}
            actionColor="green"
            actionVariant="filled"
            iconColor="white"
            iconSize="sm"
          />
          <ActionButton
            label="Modify Flow"
            icon="circle-nodes"
            iconType="fas"
            onClick={() => navigate(`/settings/sites/flow/${siteId}`)}
            actionColor="blue"
            actionVariant="filled"
            iconColor="white"
            iconSize="sm"
          />
        </Group>
        <TextGroup label="Name" value={name} maxLength={25} />
      </Stack>
    </Paper>
  )
}
