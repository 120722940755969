function useViewOnly (value?: boolean) {
  const defaultValue = typeof value === 'undefined'
    ? true
    : !value // invert

  // defaults to true
  const zoomOnScroll = true

  // disable/enable depending on view
  const elementsSelectable = defaultValue
  const nodesDraggable = defaultValue
  const nodesConnectable = defaultValue
  const zoomOnDoubleClick = defaultValue

  return {
    elementsSelectable,
    nodesDraggable,
    nodesConnectable,
    zoomOnScroll,
    zoomOnDoubleClick
  }
}

export default useViewOnly
