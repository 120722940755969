import {
  type Box,
  type NodeOrigin,
  type Rect,
  type Node,
  type NodePositionChange,
  type XYPosition,
  type Edge,
  type InternalNode
} from '@xyflow/react'

import {
  boxToRect,
  getNodePositionWithOrigin,
  MarkerType,
  Position,
  rectToBox
} from '@xyflow/system'
interface GetHelperLinesResult {
  horizontal?: number
  vertical?: number
  snapPosition: Partial<XYPosition>
}

// this utility function can be called with a position change (inside onNodesChange)
// it checks all other nodes and calculated the helper line positions
// and the position where the current node should snap to
export function getHelperLines (
  change: NodePositionChange,
  nodes: Node[],
  distance = 5
): GetHelperLinesResult {
  const defaultResult = {
    horizontal: undefined,
    vertical: undefined,
    snapPosition: { x: undefined, y: undefined }
  }
  const nodeA = nodes.find((node) => node.id === change.id)

  if (!nodeA || !change.position) {
    return defaultResult
  }

  const nodeABounds = {
    left: change.position.x,
    right: change.position.x + (nodeA.measured?.width ?? 0),
    top: change.position.y,
    bottom: change.position.y + (nodeA.measured?.height ?? 0),
    width: nodeA.measured?.width ?? 0,
    height: nodeA.measured?.height ?? 0
  }

  let horizontalDistance = distance
  let verticalDistance = distance

  return nodes
    .filter((node) => node.id !== nodeA.id)
    .reduce<GetHelperLinesResult>((result, nodeB) => {
    const nodeBBounds = {
      left: nodeB.position.x,
      right: nodeB.position.x + (nodeB.measured?.width ?? 0),
      top: nodeB.position.y,
      bottom: nodeB.position.y + (nodeB.measured?.height ?? 0),
      width: nodeB.measured?.width ?? 0,
      height: nodeB.measured?.height ?? 0
    }

    //  |‾‾‾‾‾‾‾‾‾‾‾|
    //  |     A     |
    //  |___________|
    //  |
    //  |
    //  |‾‾‾‾‾‾‾‾‾‾‾|
    //  |     B     |
    //  |___________|
    const distanceLeftLeft = Math.abs(nodeABounds.left - nodeBBounds.left)

    if (distanceLeftLeft < verticalDistance) {
      result.snapPosition.x = nodeBBounds.left
      const { x } = findAbsolutePosition(nodes, { x: nodeBBounds.left }, nodeA.parentId)
      result.vertical = x
      verticalDistance = distanceLeftLeft
    }

    //  |‾‾‾‾‾‾‾‾‾‾‾|
    //  |     A     |
    //  |___________|
    //              |
    //              |
    //  |‾‾‾‾‾‾‾‾‾‾‾|
    //  |     B     |
    //  |___________|
    const distanceRightRight = Math.abs(
      nodeABounds.right - nodeBBounds.right
    )

    if (distanceRightRight < verticalDistance) {
      result.snapPosition.x = nodeBBounds.right - nodeABounds.width
      const { x } = findAbsolutePosition(nodes, { x: nodeBBounds.right }, nodeA.parentId)
      result.vertical = x
      verticalDistance = distanceRightRight
    }

    //              |‾‾‾‾‾‾‾‾‾‾‾|
    //              |     A     |
    //              |___________|
    //              |
    //              |
    //  |‾‾‾‾‾‾‾‾‾‾‾|
    //  |     B     |
    //  |___________|
    const distanceLeftRight = Math.abs(nodeABounds.left - nodeBBounds.right)

    if (distanceLeftRight < verticalDistance) {
      result.snapPosition.x = nodeBBounds.right
      const { x } = findAbsolutePosition(nodes, { x: nodeBBounds.right }, nodeA.parentId)
      result.vertical = x
      verticalDistance = distanceLeftRight
    }

    //  |‾‾‾‾‾‾‾‾‾‾‾|
    //  |     A     |
    //  |___________|
    //              |
    //              |
    //              |‾‾‾‾‾‾‾‾‾‾‾|
    //              |     B     |
    //              |___________|
    const distanceRightLeft = Math.abs(nodeABounds.right - nodeBBounds.left)

    if (distanceRightLeft < verticalDistance) {
      result.snapPosition.x = nodeBBounds.left - nodeABounds.width
      const { x } = findAbsolutePosition(nodes, { x: nodeBBounds.left }, nodeA.parentId)
      result.vertical = x
      verticalDistance = distanceRightLeft
    }

    //  |‾‾‾‾‾‾‾‾‾‾‾|‾‾‾‾‾|‾‾‾‾‾‾‾‾‾‾‾|
    //  |     A     |     |     B     |
    //  |___________|     |___________|
    const distanceTopTop = Math.abs(nodeABounds.top - nodeBBounds.top)

    if (distanceTopTop < horizontalDistance) {
      result.snapPosition.y = nodeBBounds.top
      const { y } = findAbsolutePosition(nodes, { y: nodeBBounds.top }, nodeA.parentId)
      result.horizontal = y
      horizontalDistance = distanceTopTop
    }

    //  |‾‾‾‾‾‾‾‾‾‾‾|
    //  |     A     |
    //  |___________|_________________
    //                    |           |
    //                    |     B     |
    //                    |___________|
    const distanceBottomTop = Math.abs(nodeABounds.bottom - nodeBBounds.top)

    if (distanceBottomTop < horizontalDistance) {
      result.snapPosition.y = nodeBBounds.top - nodeABounds.height
      const { y } = findAbsolutePosition(nodes, { y: nodeBBounds.top }, nodeA.parentId)
      result.horizontal = y
      horizontalDistance = distanceBottomTop
    }

    //  |‾‾‾‾‾‾‾‾‾‾‾|     |‾‾‾‾‾‾‾‾‾‾‾|
    //  |     A     |     |     B     |
    //  |___________|_____|___________|
    const distanceBottomBottom = Math.abs(
      nodeABounds.bottom - nodeBBounds.bottom
    )

    if (distanceBottomBottom < horizontalDistance) {
      result.snapPosition.y = nodeBBounds.bottom - nodeABounds.height
      const { y } = findAbsolutePosition(nodes, { y: nodeBBounds.bottom }, nodeA.parentId)
      result.horizontal = y
      horizontalDistance = distanceBottomBottom
    }

    //                    |‾‾‾‾‾‾‾‾‾‾‾|
    //                    |     B     |
    //                    |           |
    //  |‾‾‾‾‾‾‾‾‾‾‾|‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾
    //  |     A     |
    //  |___________|
    const distanceTopBottom = Math.abs(nodeABounds.top - nodeBBounds.bottom)
    if (distanceTopBottom < horizontalDistance) {
      result.snapPosition.y = nodeBBounds.bottom
      const { y } = findAbsolutePosition(nodes, { y: nodeBBounds.bottom }, nodeA.parentId)
      result.horizontal = y
      horizontalDistance = distanceTopBottom
    }

    return result
  }, defaultResult)
}

// this util functions generates an svg path from a list of points
export function generatePath (points: number[][]) {
  const path = points.map(([x, y]) => `${x},${y}`).join(' L')

  return `M${path} Z`
}

// we have to make sure that parent nodes are rendered before their children
export const sortNodes = (a: Node, b: Node): number => {
  if (a.type === b.type) {
    return 0
  }

  return a.type === 'groupNode' && b.type !== 'groupNode' ? -1 : 1
}

export type Direction = 'toFront' | 'toBack'

export const sortChildNodesFromGroup = (
  groupId: string,
  selectedChildIds: string[],
  nodes: Node[],
  direction: Direction
) => {
  const childNodes = nodes.filter(({ parentId, id }) => parentId === groupId && !selectedChildIds.includes(id))
  const parentNode = nodes.find(({ id }) => id === groupId) as Node
  const selectedNodes = nodes.filter(({ id }) => selectedChildIds.includes(id))

  const sortedNodes = direction === 'toBack'
    ? [
        ...selectedNodes,
        ...childNodes
      ]
    : [
        ...childNodes,
        ...selectedNodes
      ]

  return [
    parentNode,
    ...sortedNodes
  ]
}

export const getMultilevelNodes = (selectedNodes: Node[], allNodes: Node[], direction: Direction) => {
  // separate first which selected nodes are within a parent and not
  const selectedIds = selectedNodes.map(({ id }) => id)
  const withinParentNodes = selectedNodes.filter(({ parentId }) => typeof parentId !== 'undefined')
  const parentNodeIds = Array.from(new Set(withinParentNodes.map(({ parentId }) => parentId)))
  const withinParentNodeIds = withinParentNodes.map(({ id }) => id)
  const selectedWithoutParentNode = selectedNodes.filter(({ id }) => !withinParentNodeIds.includes(id))
  const selectedWithoutParentNodeIds = selectedWithoutParentNode.map(({ id }) => id)

  const groupNodes = parentNodeIds.map((groupId) => {
    const selectedChildIds = withinParentNodes
      .filter(
        // filter that checkis if the child is in the parent
        ({ parentId, id }) => groupId === parentId && selectedIds.includes(id)
      )
      .map(
        ({ id }) => id
      )
    return sortChildNodesFromGroup(
      groupId as string,
      selectedChildIds,
      allNodes,
      direction
    )
  })

  const groupedNodeIds = groupNodes
    .flat()
    .map(({ id }) => id)

  // remove selected groups and it's child, as well as unselected base nodes
  const otherNodes = allNodes.filter(
    ({ id }) =>
      !parentNodeIds.includes(id) &&
      !groupedNodeIds.includes(id) &&
      !selectedWithoutParentNodeIds.includes(id)
  )

  return {
    otherNodes,
    selectedWithoutParentNode,
    groupNodes
  }
}

// preserves the group node hierarchy when a group node is selected
export const getSelectedNodes = (nodes: Node[], allNodes: Node[]) => {
  const selectedIds: string[] = []
  const selectedGroupIds = Array.from(
    new Set(nodes
      .filter(node => node.type === 'groupNode')
      .reduce<string[]>(
      (ids, node) => node.id
        ? [...ids, node.id]
        : ids,
      []))
  )

  const groupDirectory = selectedGroupIds.reduce<Record<string, Node[] | undefined>>(
    (nodeGroup, id) => {
      const siblings = allNodes.filter(({ parentId }) => id === parentId)

      // save selected ids
      if (siblings.length > 0) {
        selectedIds.push(...siblings.map(({ id }) => id))
      }

      // sort the siblings
      return siblings.length > 0
        ? {
            ...nodeGroup,
            [id]: siblings
          }
        : {
            ...nodeGroup,
            [id]: undefined
          }
    },
    {}
  )

  const selected = Object.keys(groupDirectory).reduce<Node[][]>(
    (group, parentId) => {
      const groupNode = allNodes.find(({ id }) => parentId === id)
      // save selected ids
      if (groupNode?.id) {
        selectedIds.push(groupNode.id)
      }

      // grouped nodes are now sorted properly, so we can add them one by one using reduce
      return groupNode
        ? [
            ...group,
            [
              groupNode,
              ...groupDirectory[parentId] ?? []
            ]
          ]
        : group
    },
    []
  )

  const unSelected = allNodes.filter(({ id }) => !selectedIds.includes(id))

  return {
    selected,
    unSelected
  }
}

export const getOrganizedNodes = (nodes: Node[]) => {
  const groupNodes = nodes.filter(({ type }) => type === 'groupNode')
  // get sorted nodes
  const { selected: sortedNodes } = getSelectedNodes(groupNodes, nodes)

  // create clean slate of nodes without child nodes (low Level nodes only)
  const lowLevelNodes = nodes.filter(({ parentId }) => typeof parentId === 'undefined')
  // create a directory of groupNode index from lowlevel nodes
  const groupDirectory = groupNodes.reduce<Record<string, Node[]>>(
    (directory, groupNode) => {
      const sortedNode = sortedNodes.find((nodes) => {
        const parentNode = nodes[0]
        return parentNode.id === groupNode.id
      }) as Node[]

      return {
        ...directory,
        [groupNode.id]: sortedNode
      }
    },
    {}
  )

  const outputNodes = lowLevelNodes.reduce<Node[]>(
    (nodes, node) => {
      const groupedNodes = groupDirectory[node.id]

      return groupedNodes
        ? [
            ...nodes,
            ...groupedNodes
          ]
        : [
            ...nodes,
            node
          ]
    },
    []
  )

  return outputNodes
}

export const getId = (prefix = 'node') => `${prefix}_${Math.random() * 10000}`

export const getNodePositionInsideParent = (
  node: Partial<Node>,
  groupNode: Node
) => {
  const { position: nodePosition, measured: nodeMeasured } = node
  const { position: groupPosition, measured: groupMeasured } = groupNode

  const position = nodePosition ?? { x: 0, y: 0 }
  const nodeWidth = nodeMeasured?.width ?? 0
  const nodeHeight = nodeMeasured?.height ?? 0
  const groupWidth = groupMeasured?.width ?? 0
  const groupHeight = groupMeasured?.height ?? 0

  if (position.x < groupPosition.x) {
    position.x = 0
  } else if (position.x + nodeWidth > groupPosition.x + groupWidth) {
    position.x = groupWidth - nodeWidth
  } else {
    position.x = position.x - groupPosition.x
  }

  if (position.y < groupPosition.y) {
    position.y = 0
  } else if (position.y + nodeHeight > groupPosition.y + groupHeight) {
    position.y = groupHeight - nodeHeight
  } else {
    position.y = position.y - groupPosition.y
  }

  return position
}

export const findAbsolutePosition = (
  allNodes: Node[],
  positionVH: Partial<XYPosition>,
  parentId?: string): Partial<XYPosition> => {
  if (!parentId) {
    return { x: positionVH.x, y: positionVH.y }
  }

  const { x, y } = positionVH
  const parentNode = allNodes.find(n => n.id === parentId)

  return parentNode && (x ?? y)
    ? {
        x: x
          ? parentNode.position?.x + x
          : undefined,
        y: y
          ? parentNode.position?.y + y
          : undefined
      }
    : {
        x: undefined, y: undefined
      }
}

export const getConvertedPositionFromParent = (
  currentNode: Node,
  allNodes: Node[],
  parentNodeId: string
): { x: number, y: number } => {
  const parentNode = allNodes.find(n => n.id === parentNodeId)
  if (!parentNode) {
    return { x: currentNode.position.x, y: currentNode.position.y }
  }

  // check if parentNode of A is a neighboring group of node B
  if (currentNode.parentId !== parentNodeId) {
    const parentNodeB = allNodes.find(n => n.id === currentNode.parentId)
    if (parentNodeB) {
      const parentRelativePosition = {
        x: Math.abs(parentNodeB.position.x) - Math.abs(parentNode.position.x),
        y: Math.abs(parentNodeB.position.y) - Math.abs(parentNode.position.y)
      }

      return {
        x: currentNode.position.x - parentRelativePosition.x,
        y: currentNode.position.y - parentRelativePosition.y
      }
    }
  }

  return {
    x: Math.abs(parentNode.position.x - currentNode.position.x),
    y: Math.abs(parentNode.position.y - currentNode.position.y)
  }
}

export const getBoundsOfBoxes = (box1: Box, box2: Box): Box => ({
  x: Math.min(box1.x, box2.x),
  y: Math.min(box1.y, box2.y),
  x2: Math.max(box1.x2, box2.x2),
  y2: Math.max(box1.y2, box2.y2)
})

export const getRelativeNodesBounds = (
  nodes: Node[],
  nodeOrigin: NodeOrigin = [0, 0]
): Rect => {
  if (nodes.length === 0) {
    return {
      x: 0,
      y: 0,
      width: 0,
      height: 0
    }
  }

  const box = nodes.reduce(
    (currBox, node) => {
      const { x, y } = getNodePositionWithOrigin(node, nodeOrigin)
      return getBoundsOfBoxes(
        currBox,
        rectToBox({
          x,
          y,
          width: node.width ?? 0,
          height: node.height ?? 0
        })
      )
    },
    {
      x: Infinity,
      y: Infinity,
      x2: -Infinity,
      y2: -Infinity
    }
  )

  return boxToRect(box)
}

export const cleanData = (data: Record<string, any>) => {
  const flow = { ...data }
  if (data?.nodes) {
    flow.nodes = data.nodes.map((item: Node) => ({ ...item, selected: false }))
  }
  if (data?.edges) {
    flow.edges = data.edges.map((item: Edge) => ({ ...item, selected: false }))
  }

  return flow
}

interface IntersectionPoint {
  x: number
  y: number
}

// this helper function returns the intersection point
// of the line between the center of the intersectionNode and the target node
function getNodeIntersection (intersectionNode: InternalNode, targetNode: InternalNode) {
  // https://math.stackexchange.com/questions/1724792
  // /an-algorithm-for-finding-the-intersection-point-between-a-center-of-vision-and-a
  const { width: intersectionNodeWidth = 0, height: intersectionNodeHeight = 0 } =
    intersectionNode.measured
  const intersectionNodePosition = intersectionNode.internals.positionAbsolute
  const targetPosition = targetNode.internals.positionAbsolute

  const w = intersectionNodeWidth / 2
  const h = intersectionNodeHeight / 2

  const x2 = intersectionNodePosition.x + w
  const y2 = intersectionNodePosition.y + h
  const x1 = targetPosition.x + (targetNode.measured?.width ?? 0) / 2
  const y1 = targetPosition.y + (targetNode.measured?.height ?? 0) / 2

  const xx1 = (x1 - x2) / (2 * w) - (y1 - y2) / (2 * h)
  const yy1 = (x1 - x2) / (2 * w) + (y1 - y2) / (2 * h)
  const a = 1 / (Math.abs(xx1) + Math.abs(yy1) || 1)
  const xx3 = a * xx1
  const yy3 = a * yy1
  const x = w * (xx3 + yy3) + x2
  const y = h * (-xx3 + yy3) + y2

  return { x, y }
}

// returns the position (top,right,bottom or right) passed node compared to the intersection point
function getEdgePosition (node: InternalNode, intersectionPoint: IntersectionPoint) {
  const n = { ...node.internals.positionAbsolute, ...node }
  const nx = Math.round(n.x)
  const ny = Math.round(n.y)
  const px = Math.round(intersectionPoint.x)
  const py = Math.round(intersectionPoint.y)

  if (px <= nx + 1) {
    return Position.Left
  }
  if (px >= nx + (n.measured.width ?? 0) - 1) {
    return Position.Right
  }
  if (py <= ny + 1) {
    return Position.Top
  }
  if (py >= n.y + (n.measured.height ?? 0) - 1) {
    return Position.Bottom
  }

  return Position.Top
}

// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge
export function getEdgeParams (source: InternalNode, target: InternalNode) {
  const sourceIntersectionPoint = getNodeIntersection(source, target)
  const targetIntersectionPoint = getNodeIntersection(target, source)

  const sourcePos = getEdgePosition(source, sourceIntersectionPoint)
  const targetPos = getEdgePosition(target, targetIntersectionPoint)

  return {
    sx: sourceIntersectionPoint.x,
    sy: sourceIntersectionPoint.y,
    tx: targetIntersectionPoint.x,
    ty: targetIntersectionPoint.y,
    sourcePos,
    targetPos
  }
}

export function createNodesAndEdges () {
  const nodes = []
  const edges = []
  const center = { x: window.innerWidth / 2, y: window.innerHeight / 2 }

  nodes.push({ id: 'target', data: { label: 'Target' }, position: center })

  for (let i = 0; i < 8; i++) {
    const degrees = i * (360 / 8)
    const radians = degrees * (Math.PI / 180)
    const x = 250 * Math.cos(radians) + center.x
    const y = 250 * Math.sin(radians) + center.y

    nodes.push({ id: `${i}`, data: { label: 'Source' }, position: { x, y } })

    edges.push({
      id: `edge-${i}`,
      target: 'target',
      source: `${i}`,
      type: 'floating',
      markerEnd: {
        type: MarkerType.Arrow
      }
    })
  }

  return { nodes, edges }
}
