import { type ParsedTripItem } from '..'
import List from './List'

interface Props {
  data: ParsedTripItem[]
  isLoading?: boolean
}

export default function Content ({ data, isLoading = false }: Props) {
  return <List items={data} isLoading={isLoading} />
}
