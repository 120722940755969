/* eslint-disable @typescript-eslint/naming-convention */
import {
  useMantineTheme,
  Collapse,
  Group,
  Text,
  ThemeIcon
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconCar, IconPrinter } from '@tabler/icons-react'
import dayjs from 'dayjs'
import { getLinkTextColor, getToggleableColorWBG } from 'src/utils/theme'
import { uiDateFormatUniversal } from 'src/utils/dates'
import { downloadCSV } from 'src/utils/files'
import ViewTripButton from 'src/Reports/Viewer/ViewTripButton'
import ExportButton from 'src/Reports/Viewer/ExportButton'
import { type ItemProps } from '..'
import { transformSpeedEventDataToCSV } from '../../..'
import Events from './Events'

interface Props extends ItemProps {
  containerWidth: number
}

export default function Row ({ item, containerWidth }: Props) {
  const theme = useMantineTheme()
  const [open, { toggle }] = useDisclosure(false)
  const {
    agent_id,
    agent_name,
    trip_id,
    trip_start_time,
    total_speed_alerts,
    driver,
    speed_alerts
  } = item

  return (
    <>
      <tr
        onClick={toggle}
        style={{
          cursor: 'pointer',
          backgroundColor: getToggleableColorWBG(open),
          borderBottom: 1,
          position: open
            ? 'sticky'
            : 'initial',
          top: 34,
          zIndex: open
            ? 100
            : 'unset'
        }}
      >
        <td>
          <Group
            ml={-4}
            spacing={4}
            align="center"
            noWrap
          >
            <ThemeIcon
              sx={{
                background: 'transparent'
              }}
              variant="light"
              color="primary"
            >
              <IconCar
                size={18}
                stroke={open
                  ? 2
                  : 1.5}
              />
            </ThemeIcon>
            <Text
              weight={open
                ? 'bold'
                : 'normal'}
              {...getLinkTextColor(theme)}
            >
              {agent_name}
            </Text>
          </Group>
        </td>
        <td>{dayjs(trip_start_time).format(uiDateFormatUniversal)}</td>
        <td>{total_speed_alerts}</td>
        <td>{driver}</td>
        <td>
          <Group spacing={12} align="center">
            <ViewTripButton
              agentId={agent_id}
              tripId={trip_id}
              startTime={trip_start_time}
            />
            <ExportButton
              Icon={IconPrinter}
              data={[item]}
              onClickDownloadAsCSV={() => {
                const transformedData = transformSpeedEventDataToCSV([item])
                downloadCSV(transformedData, `${agent_name} - Speed Event Report`)
              }}
            />
          </Group>
        </td>
      </tr>
      <tr>
        <td
          colSpan={6}
          style={{
            padding: 0,
            borderTop: 0,
            borderBottom: open
              ? 1
              : 0
          }}
        >
          <Collapse in={open}>
            <Events items={speed_alerts} containerWidth={containerWidth} />
          </Collapse>
        </td>
      </tr>
    </>
  )
}
