import { useCallback } from 'react'
import { type ReactourStep } from 'reactour'
import Tour, { type Props } from '../Tour'
import InformationBox from '../InformationBox'

const steps: ReactourStep[] = [
  {
    selector: '#tour-asset__menu',
    content: () => (
      <InformationBox
        title="Get into the nitty gritty"
        message="Users can find detailed information regarding all of their assets within the assets menu,
          select your desired asset and you will have access to a full suite of information relative to that asset."
      />
    )
  }
]

export default function AssetTour ({
  isOpen,
  setIsOpen,
  setIsDone,
  onClose
}: Omit<Props, 'steps' | 'onClickLastStepNextButton'>) {
  const handleClickNextTour = useCallback(() => {
    if (setIsDone) {
      setIsDone(true)
    }
  }, [])

  return (
    <Tour
      steps={steps}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClickLastStepNextButton={handleClickNextTour}
      onClose={onClose}
    />
  )
}
