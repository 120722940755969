import { type KeyCode, useKeyPress } from '@xyflow/react'
import { useEffect, useState } from 'react'

// eslint-disable-next-line @typescript-eslint/ban-types
export default function (keyCode: KeyCode, callback: Function): void {
  const [didRun, setDidRun] = useState(false)
  const shouldRun = useKeyPress(keyCode)

  useEffect(() => {
    if (shouldRun && !didRun) {
      callback()
      setDidRun(true)
    } else {
      setDidRun(shouldRun)
    }
  }, [shouldRun, didRun, callback])
}
