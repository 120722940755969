/* eslint-disable @typescript-eslint/naming-convention */
import { View, Image } from '@react-pdf/renderer'
import dayjs from 'dayjs'
import { uiDateFormatUniversal } from 'src/utils/dates'
import THCell from 'src/Layout/ReportTemplate/Components/THCell'
import TDCell from 'src/Layout/ReportTemplate/Components/TDCell'
import Space from 'src/Layout/ReportTemplate/Components/Space'
import { reportStyles } from 'src/Layout/ReportTemplate/styles'
import { type ParsedSpeedEvent } from '../../../SpeedingReportView'
import ChildTable from './ChildTable'

interface Props {
  index: number
  data: ParsedSpeedEvent
  withSummary?: boolean
}

export default function Table ({
  index,
  data: {
    agent_name,
    trip_start_time,
    total_speed_alerts,
    driver,
    speed_alerts,
    map_url
  },
  withSummary = false
}: Props) {
  const breakStartNumber = withSummary
    ? 0
    : 1
  const withBreak = index >= breakStartNumber

  return (
    <View break={withBreak}>
      <View>
        <View style={reportStyles.group}>
          <THCell value="Assets" />
          <THCell value="Date" />
          <THCell value="Violations" />
          <THCell value="Driver" />
        </View>
        <View style={reportStyles.group}>
          <TDCell value={agent_name} />
          <TDCell value={dayjs(trip_start_time).format(uiDateFormatUniversal)} />
          <TDCell value={total_speed_alerts} />
          <TDCell value={driver} />
        </View>
      </View>
      <ChildTable items={speed_alerts} />
      {map_url && (
        <>
          <Space />
          <Space />
          <View style={reportStyles.imageContainer}>
            <Image src={map_url} style={reportStyles.image} />
          </View>
        </>
      )}
    </View>
  )
}
