import { type Roles } from '@venturi-io/api/src/userManager/auth'
import { type Role } from 'src/NeedsRole'
import { useUser } from 'src/UserContext'

export const adminRoles = ['ROLE_ADMIN', 'ROLE_SUPERADMIN'] as Roles

export const primaryRoles = [...adminRoles, 'ROLE_USER']

export const checkIfHasRole = (role: Role | Role[]) => {
  const { roles } = useUser()

  return typeof role === 'object'
    ? roles.some(userRole => role.includes(userRole as Role))
    : roles.includes(role)
}
