/* eslint-disable @typescript-eslint/naming-convention */
import {
  useMantineTheme,
  Collapse,
  Group,
  Text,
  ThemeIcon
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconCar, IconPrinter } from '@tabler/icons-react'
import { getLinkTextColor, getToggleableColorWBG } from 'src/utils/theme'
import ExportButton from 'src/Reports/Viewer/ExportButton'
import { downloadCSV } from 'src/utils/files'
import { type ItemProps } from '..'
import { transformTripReportDataToCSV } from '../../..'
import Trips from './Trips'

interface Props extends ItemProps {
  containerWidth: number
}

export default function Row ({ item, containerWidth }: Props) {
  const theme = useMantineTheme()
  const [open, { toggle }] = useDisclosure(false)
  const {
    agent_name,
    alarm_status,
    total_trips,
    total_duration,
    total_distance_in_kilometres,
    trips
  } = item

  return (
    <>
      <tr
        onClick={toggle}
        style={{
          cursor: 'pointer',
          backgroundColor: getToggleableColorWBG(open),
          borderBottom: 1,
          position: open
            ? 'sticky'
            : 'initial',
          top: 34,
          zIndex: open
            ? 100
            : 'unset'
        }}
      >
        <td>
          <Group
            ml={-4}
            spacing={4}
            align="center"
            noWrap
          >
            <ThemeIcon
              sx={{
                background: 'transparent'
              }}
              variant="light"
              color="primary"
            >
              <IconCar
                size={18}
                stroke={open
                  ? 2
                  : 1.5}
              />
            </ThemeIcon>
            <Text
              weight={open
                ? 'bold'
                : 'normal'}
              {...getLinkTextColor(theme)}
            >
              {agent_name}
            </Text>
          </Group>
        </td>
        <td>{alarm_status}</td>
        <td>{total_trips}</td>
        <td>{total_duration}</td>
        <td>{total_distance_in_kilometres}</td>
        <td>
          <ExportButton
            Icon={IconPrinter}
            data={[item]}
            onClickDownloadAsCSV={() => {
              const transformedData = transformTripReportDataToCSV([item])
              downloadCSV(transformedData, `${agent_name} - Trip History Report`)
            }}
          />
        </td>
      </tr>
      <tr>
        <td
          colSpan={6}
          style={{
            padding: 0,
            borderTop: 0,
            borderBottom: open
              ? 1
              : 0
          }}
        >
          <Collapse in={open}>
            <Trips items={trips} containerWidth={containerWidth} />
          </Collapse>
        </td>
      </tr>
    </>
  )
}
