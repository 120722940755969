/* eslint-disable @typescript-eslint/naming-convention */
import {
  useMantineTheme,
  Center,
  Collapse,
  Divider,
  Group,
  Paper,
  SimpleGrid,
  Stack,
  ThemeIcon,
  Text
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { getLinkTextColor, getWhiteBackgroundColor } from 'src/utils/theme'
import { IconCalendarTime, IconCar, IconPrinter } from '@tabler/icons-react'
import dayjs from 'dayjs'
import ViewTripButton from 'src/Reports/Viewer/ViewTripButton'
import ExportButton from 'src/Reports/Viewer/ExportButton'
import { downloadCSV } from 'src/utils/files'
import { uiDateFormatUniversal } from 'src/utils/dates'
import { type ItemProps } from '..'
import { transformSpeedEventDataToCSV } from '../../..'
import Detail from './Detail'
import Events from './Events'

export default function Card ({ item }: ItemProps) {
  const theme = useMantineTheme()
  const [open, { toggle }] = useDisclosure(false)
  const isLight = theme.colorScheme === 'light'
  const {
    agent_id,
    agent_name,
    trip_id,
    trip_start_time,
    total_speed_alerts,
    driver,
    speed_alerts
  } = item

  return (
    <Paper
      sx={{
        ...getWhiteBackgroundColor(theme),
        border: isLight
          ? 'initial'
          : `1px solid ${theme.colors.gray[7]}`
      }}
      p={16}
      shadow="xs"
      radius={4}
      onClick={toggle}
    >
      <Stack spacing={12}>
        <Group position="apart" align="center">
          <Group
            ml={-4}
            spacing={4}
            align="center"
            noWrap
          >
            <ThemeIcon
              sx={{
                background: 'transparent'
              }}
              variant="light"
              color="primary"
            >
              <IconCar
                size={18}
                stroke={open
                  ? 2
                  : 1.5}
              />
            </ThemeIcon>
            <Text
              size={12}
              weight={open
                ? 'bold'
                : 500}
              {...getLinkTextColor(theme)}
            >
              {agent_name}
            </Text>
          </Group>
          <ExportButton
            Icon={IconPrinter}
            data={[item]}
            onClickDownloadAsCSV={() => {
              const transformedData = transformSpeedEventDataToCSV([item])
              downloadCSV(transformedData, `${agent_name} - Speed Event Report`)
            }}
          />
        </Group>
        <Divider mt={0} mb={4} variant="dotted" />
        <Group
          ml={-8}
          spacing={0}
          align="center"
          noWrap
        >
          <ThemeIcon
            sx={{
              background: 'transparent'
            }}
            variant="light"
            color="primary"
          >
            <IconCalendarTime size={14} />
          </ThemeIcon>
          <Text
            size={12}
            weight={500}
            {...getLinkTextColor(theme)}
          >
            {dayjs(trip_start_time).format(uiDateFormatUniversal)}
          </Text>
        </Group>
        <SimpleGrid cols={2} spacing={12}>
          <Detail label="Driver" value={driver} />
          <Detail label="Violations" value={total_speed_alerts} />
        </SimpleGrid>
        <Center mt={12} mb={4}>
          <ViewTripButton
            agentId={agent_id}
            tripId={trip_id}
            startTime={trip_start_time}
          />
        </Center>
        {open && <Divider mt={8} mb={0} variant="dotted" />}
        <Collapse in={open}>
          <Events items={speed_alerts} />
        </Collapse>
      </Stack>
    </Paper>
  )
}
