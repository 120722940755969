import { Text, Alert } from '@mantine/core'
import { IconInfoCircle } from '@tabler/icons-react'

export default function ReportParametersAlert () {
  return (
    <Alert
      title="Required Report Parameters"
      icon={<IconInfoCircle size={26} />}
      color="primary"
      mt="xs"
      pb="md"
    >
      <Text>
        Please select a report type and your preferred time range to proceed with report generation
      </Text>
    </Alert>
  )
}
