import { type CSSProperties, useEffect, useRef } from 'react'
import { type ReactFlowState, useStore } from '@xyflow/react'
import { SELECTED_COLOR } from '../shared'

const canvasStyle: CSSProperties = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  zIndex: 10,
  pointerEvents: 'none'
}

const storeSelector = ({
  width,
  height,
  transform
}: ReactFlowState) => ({
  width,
  height,
  transform
})

export interface HelperLinesProps {
  horizontal?: number
  vertical?: number
}

// a simple component to display the helper lines
// it puts a canvas on top of the React Flow pane and draws the lines using the canvas API
function HelperLines ({ horizontal, vertical }: HelperLinesProps) {
  const { width, height, transform } = useStore(storeSelector)

  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas?.getContext('2d')

    if (!ctx || !canvas) {
      return
    }

    const dpi = window.devicePixelRatio
    canvas.width = width * dpi
    canvas.height = height * dpi

    ctx.scale(dpi, dpi)
    ctx.clearRect(0, 0, width, height)
    ctx.strokeStyle = SELECTED_COLOR

    if (typeof vertical === 'number') {
      const verticalValue = vertical * transform[2] + transform[0]
      ctx.moveTo(verticalValue, 0)
      ctx.lineTo(verticalValue, height)
      ctx.stroke()
    }

    if (typeof horizontal === 'number') {
      const horizontalValue = horizontal * transform[2] + transform[1]
      ctx.moveTo(0, horizontalValue)
      ctx.lineTo(width, horizontalValue)
      ctx.stroke()
    }
  }, [width, height, transform, horizontal, vertical])

  return (
    <canvas
      ref={canvasRef}
      className="react-flow__canvas"
      style={canvasStyle}
    />
  )
}

export default HelperLines
