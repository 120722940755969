import { createStyles, Box, Table as MantineTable } from '@mantine/core'
import { getBackgroundColor } from 'src/utils/theme'
import EmptyPlaceholder from 'src/Reports/Viewer/EmptyPlaceholder'
import { type ParsedSpeedEvent } from 'src/Reports/Viewer/SpeedingReportView'
import Row from './Row'

const useStyles = createStyles((theme) => ({
  head: {
    position: 'sticky',
    ...getBackgroundColor(theme),
    zIndex: 3
  }
}))

interface Props {
  items: ParsedSpeedEvent['speed_alerts']
  containerWidth: number
}

export default function Events ({ items, containerWidth }: Props) {
  const { classes } = useStyles()

  return (
    <Box
      sx={{
        width: containerWidth,
        overflowY: 'auto',
        scrollbarWidth: 'thin'
      }}
    >
      <MantineTable fontSize="xs">
        <thead className={classes.head}>
          <tr>
            <th>Event #</th>
            <th>Event Date/Time</th>
            <th>Location</th>
            <th>Geozone</th>
            <th>Speed Limit</th>
            <th>Speed Violation</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <Row
              key={item.id}
              index={index}
              data={item}
            />
          ))}
        </tbody>
      </MantineTable>
      {items.length === 0 && <EmptyPlaceholder />}
    </Box>
  )
}
