interface Props {
  label: string
}

export default function AverageMaxHead ({ label }: Props) {
  return (
    <th
      colSpan={2}
      style={{
        width: 160
      }}
    >
      <tr>
        <td colSpan={2}>{label}</td>
      </tr>
      <tr>
        <td
          style={{
            minWidth: 70
          }}
        >
          Average
        </td>
        <td
          style={{
            minWidth: 70
          }}
        >
          Max
        </td>
      </tr>
    </th>
  )
}
