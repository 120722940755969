import {
  createContext,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  useState
} from 'react'
import { type Report } from '@venturi-io/api/src/config/report'
import { type TimePeriod } from 'src/Buttons/TimePeriodButton'
import { type Range } from '@venturi-io/api'

type ReportType = Report | null
export type TimePeriodType = TimePeriod['value'] | null
export type RangeType = Range | null
export type ReportRawDataType = Record<string, Array<Record<string, string | null>>>

export interface ContextProps {
  report: ReportType
  timePeriod: TimePeriodType
  range: RangeType
  reportRawData: ReportRawDataType
  isGenerating: boolean
  isDownloading: boolean
  setReport: Dispatch<SetStateAction<ReportType>>
  setTimePeriod: Dispatch<SetStateAction<TimePeriodType>>
  setRange: Dispatch<SetStateAction<RangeType>>
  setReportRawData: Dispatch<SetStateAction<ReportRawDataType>>
  setIsGenerating: Dispatch<SetStateAction<boolean>>
  setIsDownloading: Dispatch<SetStateAction<boolean>>
}

export const Context = createContext<ContextProps>({
  report: null,
  timePeriod: null,
  range: null,
  reportRawData: {},
  isGenerating: false,
  isDownloading: false,
  setReport: () => null,
  setTimePeriod: () => null,
  setRange: () => null,
  setReportRawData: () => null,
  setIsGenerating: () => null,
  setIsDownloading: () => null
})

interface Props {
  children: ReactNode | JSX.Element
}

export default function ReportContext ({ children }: Props) {
  const [report, setReport] = useState<ReportType>(null)
  const [timePeriod, setTimePeriod] = useState<TimePeriodType>(null)
  const [range, setRange] = useState<RangeType>(null)
  const [reportRawData, setReportRawData] = useState<ReportRawDataType>({})
  const [isGenerating, setIsGenerating] = useState<boolean>(false)
  const [isDownloading, setIsDownloading] = useState<boolean>(false)

  return (
    <Context.Provider
      value={{
        report,
        timePeriod,
        range,
        reportRawData,
        isGenerating,
        isDownloading,
        setReport,
        setTimePeriod,
        setRange,
        setReportRawData,
        setIsGenerating,
        setIsDownloading
      }}
    >
      {children}
    </Context.Provider>
  )
}
